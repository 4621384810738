<template>
	<v-container fluid class="px-0 py-0">
		<v-row class="justify-center grey darken-2">
			<v-col class="pb-0">
				<UserHeader :beatmaker="beatmaker" />
			</v-col>
			<v-col cols="12" md="10" class="py-0">
				<SongCarousel beatPlaylist="Titres populaires" :songCards="popularCarousel" />
			</v-col>
		</v-row>

		<v-row class="grey darken-4 justify-center">
			<v-col cols="10">
				<span class="white--text h1 songItemHead">Tous les titres</span>
				<SongItem :Songs="beatmakerSongs" />
			</v-col>
		</v-row>
		<v-row class="grey darken-4 justify-center">
			<v-col cols="10">
				<span class="white--text h1 songItemHead">Toutes les collabs</span>
				<SongItem :Songs="beatmakerCollab" />
			</v-col>
		</v-row>
		<UserSocial :beatmaker="beatmaker" />
		<UserAdmin :beatmakerRole="beatmaker" />
	</v-container>
</template>
<script>
import UserHeader from "../components/UserPage/UserHeader.vue";
import UserAdmin from "../components/UserPage/UserAdmin.vue";
import UserSocial from "../components/UserPage/UserSocial.vue";
import SongCarousel from "../components/Global_Components/SongCarousel.vue";
import SongItem from "../components/Global_Components/SongItem.vue";
export default {
	components: {
		UserHeader,
		UserAdmin,
		UserSocial,
		SongCarousel,
		SongItem,
	},
	data() {
		return {
			beatmaker: {
				name: "Green",
				bio: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt impedit harum a ad porro, recusandae inventore suscipit ea in reiciendis exercitationem cum, ratione culpa, facere tempore veniam. Soluta, sunt eaque? Incidunt impedit harum a ad porro, recusandae inventore suscipit ea in reiciendis exercitationem cum, ratione culpa, facere",
				nbSubscriptions: 0,
				nbSubcribers: 0,
				nbListens: 10,
				nbDownload: 1,
				avatar:
					"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
				cover:
					"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2Fcaught-in-joy-ptVBlniJi50-unsplash-min%20(2).jpg?alt=media&token=1de2601f-4e2f-4135-919e-ef11b4dd9111",
				instagram: "",
				twitter: "",
				youtube: "",
				snapchat: "",
				website: "",
				facebook: "",
				role: "Utilisateur",
			},
			popularCarousel: [
				{
					songID: "657417",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 1,
					Play: false,
				},
				{
					songID: "657418",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					songID: "657435",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					songID: "658418",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					songID: "677418",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					songID: "657419",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					songID: "657420",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					songID: "657421",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
			],
			beatmakerCollab: [
				{
					songID: "657417",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 300,
					genre: "Rap",
					licence: "Licence non-commerciale",
					mood: "Triste",
					instrument: "Piano",
				},
				{
					songID: "657418",
					title: "Search",
					beatmaker: "Filtered",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 100,
					genre: "Drill",
					licence: "Licence non-commerciale",
					mood: "Kek",
					instrument: "Flute",
				},
				{
					songID: "657419",
					title: "Data",
					beatmaker: "Green Wojack",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 10,
					BPM: 105,
					genre: "Rap",
					licence: "Ecoute",
					mood: "Dansant",
					instrument: "Tambour",
				},
				{
					songID: "657420",
					title: "Last One",
					beatmaker: "Maker",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 180,
					genre: "Trap",
					licence: "Licence non-commerciale",
					mood: "Joyeux",
					instrument: "Accordeon",
				},
				{
					songID: "657421",
					title: "On the Run",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 170,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Triste",
					instrument: "Guitare",
				},
				{
					songID: "657422",
					title: "It was only a Dream",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 10,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Dansant",
					instrument: "piano",
				},
				{
					songID: "657498",
					title: "On the Run",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 170,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Triste",
					instrument: "Guitare",
				},
				{
					songID: "657499",
					title: "It was only a Dream",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 10,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Dansant",
					instrument: "piano",
				},
				{
					songID: "657487",
					title: "On the Run",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 170,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Triste",
					instrument: "Guitare",
				},
				{
					songID: "657488",
					title: "It was only a Dream",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 10,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Dansant",
					instrument: "piano",
				},
			],
			beatmakerSongs: [
				{
					songID: "657417",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 300,
					genre: "Rap",
					licence: "Licence non-commerciale",
					mood: "Triste",
					instrument: "Piano",
				},
				{
					songID: "657418",
					title: "Search",
					beatmaker: "Filtered",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 100,
					genre: "Drill",
					licence: "Licence non-commerciale",
					mood: "Kek",
					instrument: "Flute",
				},
				{
					songID: "657419",
					title: "Data",
					beatmaker: "Green Wojack",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 10,
					BPM: 105,
					genre: "Rap",
					licence: "Ecoute",
					mood: "Dansant",
					instrument: "Tambour",
				},
				{
					songID: "657420",
					title: "Last One",
					beatmaker: "Maker",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 180,
					genre: "Trap",
					licence: "Licence non-commerciale",
					mood: "Joyeux",
					instrument: "Accordeon",
				},
				{
					songID: "657421",
					title: "On the Run",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 170,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Triste",
					instrument: "Guitare",
				},
				{
					songID: "657422",
					title: "It was only a Dream",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 10,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Dansant",
					instrument: "piano",
				},
				{
					songID: "657498",
					title: "On the Run",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 170,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Triste",
					instrument: "Guitare",
				},
				{
					songID: "657499",
					title: "It was only a Dream",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 10,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Dansant",
					instrument: "piano",
				},
				{
					songID: "657487",
					title: "On the Run",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 170,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Triste",
					instrument: "Guitare",
				},
				{
					songID: "657488",
					title: "It was only a Dream",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 10,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Dansant",
					instrument: "piano",
				},
			],
		};
	},
};
</script>
<style>
.songItemHead {
	font-size: 160%;
}
</style>