<template lang="">
    <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col cols=12 class="text-center pb-0">
                        <span>Suggestions</span><br>
                        <span>D'abonnement</span>
                    </v-col>
                    <v-col cols="12" v-for="item in SuggestedBeatmaker.slice(0,6)" :key="item.id">
                        <v-row>
                            <v-col cols="2" class="pb-0">
                                <v-avatar size="36">
                                    <v-img class="pointer" @click="linkTo(item.altName)" :src="item.avatar"></v-img>
                                </v-avatar>
                            </v-col >
                            <v-col cols="6" class="pb-0 mt-1">
                                <span @click="linkTo(item.altName)" class="ml-1 beatmakerName pointer">{{item.name}}</span>
                            </v-col>
                            <v-col cols="4" class="pb-0 text-end">
                                <v-icon @click="follow(item.id)">mdi-account-plus-outline</v-icon>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols=12>
                        <v-row>
                            <v-btn plain x-small v-if="suggestPage>0" @click="prevSuggestion()">< Précédents</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn plain x-small @click="nextSuggestion()">Suivants ></v-btn>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-row class="justify-center">
                    <v-col cols=8>
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row class="mt-0">
                    <v-col cols=12 class="text-center pb-0">
                        <span>Nouveaux</span><br>
                        <span>Utilisateurs</span>
                    </v-col>
                    <v-col cols="12" v-for="item in newBeatmaker.slice(0,6)" :key="item.id">
                        <v-row>
                            <v-col cols="2" class="pb-0">
                                <v-avatar size="36">
                                    <v-img @click="linkTo(item.altName)" class="pointer" :src="item.avatar"></v-img>
                                </v-avatar>
                            </v-col >
                            <v-col cols="6" class="pb-0 mt-1">
                                <span @click="linkTo(item.altName)" class="ml-1 beatmakerName pointer">{{item.name}}</span>
                            </v-col>
                            <v-col cols="4" class="pb-0 text-end">
                                <v-icon @click="follow(item.id)">mdi-account-plus-outline</v-icon>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols=12 class="pb-6">
                        <v-row>
                            <v-btn plain x-small v-if="newPage>0" @click="prevNew()">< Précédents</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn plain x-small @click="nextNew()">Suivants ></v-btn>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
    </v-row>
</template>
<script>
export default {
	computed: {
		// filteredBeatmaker(){
		//     var follow = this.user.following
		//     if (follow.indexOf(this.beatmaker.id) === -1) {
		//     }
		//     var filtered = this.beatmaker.filter(function(el){return el.id != follow})
		//     console.log(follow)
		//     return filtered
		// }
	},
	methods: {
		linkTo(link) {
			this.$router.push({ name: 'user', params: { id: link } });
		},
		nextSuggestion() {
			this.suggestPage = this.suggestPage + 1;
			// TODO : REFRESH SUGGESTIONS
		},
		prevSuggestion() {
			this.suggestPage = this.suggestPage - 1;
			// TODO : REFRESH SUGGESTIONS BACK TO PREV PAGE
		},
		nextNew() {
			this.newPage = this.newPage + 1;
			// TODO : REFRESH SUGGESTIONS
		},
		prevNew() {
			this.newPage = this.newPage - 1;
			// TODO : REFRESH SUGGESTIONS BACK TO PREV PAGE
		},
		follow(id) {
			this.$emit('follow', id);
		},
	},
	props: {
		newBeatmaker: Array,
		SuggestedBeatmaker: Array,
		user: Object,
	},
	data() {
		return {
			suggestPage: 0,
			newPage: 0,
		};
	},
};
</script>
<style>
.beatmakerName {
	font-size: 70%;
}
</style>