<template>
	<v-container fluid class="grey darken-3">
		<v-row dense class="justify-center">
			<v-col cols="12" md="11" lg="10" class="py-0">
				<p class="mb-1" :class="textMargin">
					<span class="grey--text text--lighten-2 h1 font-weight-bold head">Populaires</span>
				</p>
			</v-col>
		</v-row>
		<v-row class="justify-center mt-2" @mouseover="btns = true" @mouseleave="btns = false">
			<vue-horizontal ref="horizontal" class="carousel ml-5">
				<template v-slot:btn-prev>
					<v-btn icon large color="white" class="justify-end mb-16" :class="prevBtn">
						<v-icon>mdi-less-than</v-icon>
					</v-btn>
				</template>
				<template v-slot:btn-next>
					<v-btn icon large color="white" class="justify-end mb-16" :class="nextBtn">
						<v-icon>mdi-greater-than</v-icon>
					</v-btn>
				</template>
				<section v-for="song in songCards" :key="song.id " :class="carouselMargin">
					<v-col cols="12">
						<v-row class="rounded-lg grey darken-4 mb-4">
							<v-col class="py-2 px-2" cols="7">
								<v-img v-if="song.imagePath != null" @dblclick="song.playButton=true" @mouseover="song.playButton = true" @mouseleave="song.playButton = false" height="100%" :src="song.imagePath" class="align-end rounded">
									<v-img v-if="song.imagePath != null" @dblclick="song.playButton=true" @mouseover="song.playButton = true" @mouseleave="song.playButton = false" height="100%" :src="song.imagePath" class="align-end rounded" />
									<v-row>
										<v-col cols="12">
											<v-row class="justify-center">
												<v-col color="transparent" v-if="song.isPlaying === true || song.playButton === true">
													<v-row :class="btnposition" class="justify-center">
														<v-btn @click="song.isPlaying=false" v-if="song.isPlaying===true && $vuetify.breakpoint.smAndUp" icon>
															<div class="icon audio">
																<i></i>
																<i></i>
															</div>
														</v-btn>
														<v-btn @click="song.isPlaying=false" icon v-if="song.isPlaying===true && $vuetify.breakpoint.smAndDown">
															<v-icon :x-large="iconSize" color="white">mdi-pause</v-icon>
														</v-btn>
														<v-btn v-if="song.isPlaying===false && song.playButton === true" @click="song.isPlaying=true" icon>
															<v-icon :x-large="iconSize" color="white">mdi-play</v-icon>
														</v-btn>
													</v-row>
												</v-col>
											</v-row>
										</v-col>
										<v-col cols="12">
											<v-row>
												<v-col cols="12" class="ml-4 mb-2">
													<v-row>
														<v-btn class="ml-2" icon @click.stop.prevent="like(song)" v-if="!user.liked.includes(song.songID)">
															<v-icon class="white--text">mdi-thumb-up</v-icon>
															<span class="mr-3 mt-1 white--text">{{ song.likes }}</span>
														</v-btn>
														<v-btn class="ml-2" icon @click.stop.prevent=" removeLike(song)" v-else>
															<v-icon class="white--text" color="green">mdi-thumb-up</v-icon>
															<span class="mr-3 mt-1 white--text caption">{{song.likes}}</span>
														</v-btn>
													</v-row>
												</v-col>
											</v-row>
										</v-col>
									</v-row>
								</v-img>
								<v-img
									v-else
									@dblclick="song.playButton=true"
									@mouseover="song.playButton = true"
									@mouseleave="song.playButton = false"
									height="100%"
									:src="'https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ffavicon.png?alt=media&token=e2e96a37-5474-4fc0-abc0-04ffd333d18c'"
									class="align-end rounded"
								>
									<v-row>
										<v-col cols="12">
											<v-row class="justify-center">
												<v-col color="transparent" v-if="song.isPlaying === true || song.playButton === true">
													<v-row :class="btnposition" class="justify-center">
														<v-btn @click="song.isPlaying=false" v-if="song.isPlaying===true && $vuetify.breakpoint.smAndUp" icon>
															<div class="icon audio">
																<i></i>
																<i></i>
															</div>
														</v-btn>
														<v-btn @click="song.isPlaying=false" icon v-if="song.isPlaying===true && $vuetify.breakpoint.smAndDown">
															<v-icon :x-large="iconSize" color="white">mdi-pause</v-icon>
														</v-btn>
														<v-btn v-if="song.isPlaying===false && song.playButton === true" @click="song.isPlaying=true" icon>
															<v-icon :x-large="iconSize" color="white">mdi-play</v-icon>
														</v-btn>
													</v-row>
												</v-col>
											</v-row>
										</v-col>
										<v-col cols="12">
											<v-row>
												<v-col cols="12" class="ml-4 mb-2">
													<v-row>
														<v-btn class="ml-2" icon @click.stop.prevent="like(song)" v-if="!user.liked.includes(song.songID)">
															<v-icon class="white--text">mdi-thumb-up</v-icon>
															<span class="mr-3 mt-1 white--text">{{ song.likes }}</span>
														</v-btn>
														<v-btn class="ml-2" icon @click.stop.prevent=" removeLike(song)" v-else>
															<v-icon class="white--text" color="green">mdi-thumb-up</v-icon>
															<span class="mr-3 mt-1 white--text caption">{{song.likes}}</span>
														</v-btn>
													</v-row>
												</v-col>
											</v-row>
										</v-col>
									</v-row>
								</v-img>
							</v-col>
							<v-col cols="5">
								<v-layout column justify-space-between fill-height>
									<v-row class="justify-end">
										<v-btn @click="routeTo(song.id,'SamplePopup')" icon class="align-start justify-end mt-1 mr-1">
											<v-icon color="white">mdi-information-outline</v-icon>
										</v-btn>
									</v-row>
									<v-row class="align-end">
										<v-col>
											<v-row class="justify-space-between">
												<v-col cols="12" md="7" xl="6">
													<v-row class="d-none d-md-block justify-center">
														<p class="mb-1 px-3 py-1 text-center rounded green">
															<span class="free h1 white--text">{{song.price}}</span>
														</p>
													</v-row>
												</v-col>
												<v-col cols="12" md="5">
													<v-row class="justify-end">
														<v-btn @click="downloadSong(song.wav,song.title)" icon>
															<v-icon color="white">mdi-download</v-icon>
														</v-btn>
													</v-row>
												</v-col>
											</v-row>
										</v-col>
									</v-row>
								</v-layout>
							</v-col>
						</v-row>
						<v-row v-if="$vuetify.breakpoint.smAndUp">
							<p class="h1 text ml-2">
								<span class="text-uppercase white--text sampleInfo">{{song.title}}</span>
								<span class="grey--text ml-2 free sampleInfo">{{song.type}}</span>
								<br />
								<span @click="routeTo(song.beatmaker,'user')" class="text-uppercase white--text sampleInfo pointer">{{song.beatmaker}}</span>
							</p>
						</v-row>
					</v-col>
				</section>
			</vue-horizontal>
		</v-row>
	</v-container>
</template>
<script>
import VueHorizontal from "vue-horizontal";
import { db } from "../../firebase";
import "firebase/firestore";
import firebase from "firebase/app";

export default {
	components: {
		VueHorizontal,
	},
	computed: {
		textMargin() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "ml-4";
				case "sm":
					return "ml-9";
				case "md":
					return "ml-5";
				case "lg":
					return "ml-5";
				case "xl":
					return "ml-10";
				default:
					return "ml-8";
			}
		},
		carouselMargin() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "mx-2";
				case "sm":
					return "ml-2 mr-4";
				case "md":
					return "ml-2 mr-4";
				case "lg":
					return "mx-4";
				case "xl":
					return "mx-4";
				default:
					return "mx-3";
			}
		},
		iconSize() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return false;
				case "sm":
					return true;
				case "xl":
					return true;
				default:
					return false;
			}
		},
		btnposition() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "mt-3";
				case "sm":
					return "mb-6";
				case "md":
					return "mb-10";
				case "lg":
					return "mb-4";
				case "xl":
					return "mb-12";
				default:
					return "mt-3";
			}
		},
		nextBtn() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "";
				case "sm":
					return "ml-2";
				case "md":
					return "ml-2";
				default:
					return "";
			}
		},
		prevBtn() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "mr-8";
				case "sm":
					return "mr-8";
				case "md":
					return "mr-16";
				default:
					return "mr-16";
			}
		},
	},
	methods: {
		routeTo(link, type) {
			this.$router.push({ name: type, params: { id: link } });
		},
		async downloadSong(uri, name) {
			var link = document.createElement("a");
			link.download = name;
			link.href = uri;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
		like(song) {
			let ref = db.collection("loops").doc(song.songID.toString());
			let likes = ref.update({
				likes: firebase.firestore.FieldValue.increment(1),
			});
			//TODO :  Update user's liked song
		},
		removeLike(song) {
			let ref = db.collection("loops").doc(song.songID.toString());
			let likes = ref.update({
				likes: firebase.firestore.FieldValue.decrement(1),
			});
		},
	},
	props: {
		songCards: Array,
	},
	data() {
		return {
			btns: false,
			user: {
				id: ["id"],
				liked: ["657417"],
			},
		};
	},
};
</script>
<style scoped>
@media (max-width: 660px) {
	.carousel >>> .v-hl-btn-prev {
		height: 2em;
		width: 3em;
	}
}
@media (min-width: 660px) {
	.carousel >>> .v-hl-btn-prev {
		height: 7em;
		width: 5em;
	}
}
@media (min-width: 960px) {
	.carousel >>> .v-hl-btn-prev {
		height: 11em;
		width: 5em;
	}
}
@media (min-width: 1864px) {
	.carousel >>> .v-hl-btn-prev {
		height: 12em;
		width: 5em;
	}
}
</style>
<style>
.free {
	user-select: none;
}
.text {
	font-size: 105%;
}
.head {
	font-size: 200%;
}
@media (max-width: 960px) {
	.text {
		font-size: 90%;
	}
}
@media (max-width: 600px) {
	section {
		width: calc((95% - (1px)) / 2);
	}
	.text {
		font-size: 75%;
	}
	.carousel {
		width: 90%;
	}
	.sampleInfo {
		font-size: 75%;
	}
}
@media (min-width: 600px) {
	section {
		width: calc((85% - (2 * -29px)) / 2);
	}
	.carousel {
		width: 90%;
	}
}

@media (min-width: 960px) {
	section {
		width: calc((95% - (1 * -5px)) / 2);
	}
	.carousel {
		width: 88%;
	}
}

@media (min-width: 1264px) {
	section {
		width: calc((93% - (1 * 1px)) / 3);
	}
	.carousel {
		width: 82%;
	}
}
@media (min-width: 1904px) {
	section {
		width: calc((94% - (2 * -5px)) / 3);
	}
	.carousel {
		width: 79%;
	}
}
.icon {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 16em;
	height: 16em;
	transform: translate(-50%, -50%);
}

/* Audio */

.audio {
	color: #fffffff3;
}

.audio::after,
.audio i,
.audio i::before,
.audio i::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	display: block;
	width: 0.25em;
	height: 0.5em;
	transform: translate(-50%, -50%);
	background: currentColor;
	border-radius: 0.125em;
}

.audio::after {
	animation: audio4 1.35s infinite;
}

.audio i::before {
	left: -0.8125em;
	animation: audio1 1.35s infinite;
}
.audio i::after {
	left: 1.0625em;
	animation: audio3 1.35s infinite;
}

.audio i:first-child {
	margin-left: -1.875em;
	animation: audio2 1.35s infinite;
}

.audio i:last-child {
	margin-left: 1.875em;
	animation: audio6 1.35s infinite;
}

.audio i:last-child::before {
	animation: audio5 1.35s infinite;
}

.audio i:last-child::after {
	animation: audio7 1.35s infinite;
}

@keyframes audio1 {
	9% {
		height: 1.75em;
	}
	36% {
		height: 0.5em;
	}
	42% {
		height: 2.375em;
	}
	69% {
		height: 0.5em;
	}
	76% {
		height: 3.625em;
	}
	100% {
		height: 1em;
	}
}

@keyframes audio2 {
	9% {
		height: 5.5em;
	}
	36% {
		height: 0.5em;
	}
	42% {
		height: 5.5em;
	}
	69% {
		height: 0.5em;
	}
	76% {
		height: 6.75em;
	}
	100% {
		height: 1em;
	}
}

@keyframes audio3 {
	9% {
		height: 6.5em;
	}
	36% {
		height: 0.5em;
	}
	42% {
		height: 3.5em;
	}
	69% {
		height: 0.5em;
	}
	76% {
		height: 3.5em;
	}
	100% {
		height: 1em;
	}
}

@keyframes audio4 {
	9% {
		height: 1.875em;
	}
	36% {
		height: 0.5em;
	}
	42% {
		height: 3.75em;
	}
	69% {
		height: 0.5em;
	}
	76% {
		height: 5.625em;
	}
	100% {
		height: 1em;
	}
}

@keyframes audio5 {
	9% {
		height: 5em;
	}
	36% {
		height: 0.5em;
	}
	42% {
		height: 1.25em;
	}
	69% {
		height: 0.5em;
	}
	76% {
		height: 3.75em;
	}
	100% {
		height: 1em;
	}
}

@keyframes audio6 {
	9% {
		height: 6.125em;
	}
	36% {
		height: 0.5em;
	}
	42% {
		height: 6.875em;
	}
	69% {
		height: 0.5em;
	}
	76% {
		height: 2.5em;
	}
	100% {
		height: 1em;
	}
}

@keyframes audio7 {
	9% {
		height: 3.125em;
	}
	36% {
		height: 0.5em;
	}
	42% {
		height: 1.875em;
	}
	69% {
		height: 0.5em;
	}
	76% {
		height: 3.75em;
	}
	100% {
		height: 1em;
	}
}
</style>