import { firestorePlugin } from "vuefire";
import Vue from "vue";
import VueHorizontal from "vue-horizontal";
import App from "./App.vue";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import VueSimpleAlert from "vue-simple-alert";
import { ValidationProvider, extend } from "vee-validate";
import router from './router'
import PortalVue from "portal-vue";
import store from './store/index';
import firebase from 'firebase';
import { db } from './firebase'

require('@/assets/Style/main.css')
Vue.config.productionTip = false;
Vue.use(firestorePlugin, VueHorizontal, VueSimpleAlert, ValidationProvider, extend, PortalVue);



const v = new Vue({
  vuetify,
  router,
  render: (h) => h(App),
  store: store

}).$mount('#app');

//TODO : Update store with user's information ?
firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    const userData = await db.collection('testAbdel').where('authID', '==', user.uid).get().then((userSnapshot) => {
      return userSnapshot.docs[0].data()
    })
    console.log(userData)
    store.commit('setUser', userData)
  } else {
    store.commit('setUser', null)
  }
});

