<template lang="">
<v-col cols=10>
    <v-row class="justify-center">
            <v-col cols="12" class='flow grey darken-4 imgEffect rounded-lg pl-6 pr-1' @click="activated">
                <v-row class="justify-center align-center">
                    <span id="inner" class="grey--text text--lighten-3 h1 mr-3 size">FLOW</span>
                    <v-icon v-if="activate===true" size="55" right color="grey lighten-3">mdi-play</v-icon>
                    <v-icon v-if="activate===false" size="55" right color="grey lighten-3">mdi-pause</v-icon>
                </v-row> 
            </v-col>
    </v-row>
</v-col>
</template>
<script>
export default {
    methods: {
        activated(){
            this.activate = !this.activate;
        }
    },
    data() {
        return {
            activate:true,
        }
    },    
}
</script>
<style>
#inner{
    font-size: 250%;
}
.flow:hover {
	transition: all 500ms;
	transform: scale(1.05);
    cursor: pointer;
    user-select: none;
    border: white;
}
</style>