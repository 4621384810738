<template lang="">
<v-container fluid>
    <v-row dense>
        <v-col cols=12>
            <v-row class="justify-center">
                <v-col cols="12" class="text-center">
                    <span class="white--text h1 logHead">Mot de passe oublié</span> <br>
                    <span v-if="failed === false && success===false" class="grey--text text--lighten-1 logCaption">On va vous aider à le retrouver !</span>
                    <span v-if="failed === true && success===false" class="error--text">Aucun compte associé à cette adresse mail</span>
                    <span v-if="success === true" class="loginText">Le mot de passe a été envoyé par mail à l'adresse indiquée</span>
                </v-col>
                <v-col cols=10 md=8>
                    <validation-observer ref="form" v-slot="{ invalid }">
                        <v-form @submit.prevent="onSubmit">
                            <validation-provider rules="required|email" v-slot="{errors}">
                                <span class="grey--text text--lighten-1">Adresse mail</span>
                                <v-text-field v-on:keyup.enter="onSubmit" type=mail :error-messages="errors" v-model="mailInput" background-color="grey" outlined dense></v-text-field>
                            </validation-provider>
                        </v-form>
                            <v-btn v-on:click="onSubmit" v-if="invalid === false" color="#00ba94" width="100%">Continuer</v-btn>
                            <v-btn v-on:click="onSubmit" v-else disabled dark width="100%">Continuer</v-btn>
                            <p class="text-center grey--text text--lighten-1 mt-1">                                
                                <span>
                                    La mémoire vous revient ?
                                </span>
                                <span v-on:click="loginForm" class="loginText pointer"> Accéder à la page de connexion</span>
                            </p>
                    </validation-observer>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
import VueSimpleAlert from "vue-simple-alert";
import firebase from "firebase";
import "firebase/auth";
import { required, email } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
extend("required", {
	...required,
	message: "Cette case ne doit pas rester vide",
});
extend("email", {
	...email,
	message: "Entrez un email valide",
});
export default {
	components: {
		ValidationObserver,
		ValidationProvider,
	},
	methods: {
		loginForm() {
			this.$emit("login");
		},
		onSubmit() {
			firebase
				.auth()
				.sendPasswordResetEmail(this.mailInput)
				.then(function () {
					VueSimpleAlert.confirm("", "Formulaire envoyé par mail", "success");
				})
				.catch(function (error) {
					VueSimpleAlert.confirm(
						"",
						"Aucun compte avec cette adresse",
						"error"
					);
					// Error occurred. Inspect error.code.
				});
		},
	},
	props: {
		beatmaker: Array,
	},
	created() {
		this.accounts = this.beatmaker.map(({ email }) => ({ email: email }));
	},
	data() {
		return {
			mailInput: "",
			passwordInput: "",
			failed: false,
			success: false,
			accounts: [
				{
					email: String,
				},
			],
		};
	},
};
</script>
<style>
.logHead {
	font-size: 200%;
}
.loginText {
	color: #00ba94;
}
@media (min-width: 768px) {
	.logHead {
		font-size: 230%;
	}
	.logCaption {
		font-size: 120%;
	}
}
</style>