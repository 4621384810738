<template >
  <v-menu dark offset-y left open-on-click>
    <template v-slot:activator="{on, attrs}">
      <v-badge bordered bottom color="green" dot offset-x="10" offset-y="10">
        <v-responsive>
          <v-avatar v-bind="attrs" v-on="on" size="40">
            <img src="https://cdn.iconscout.com/icon/free/png-256/avatar-366-456318.png" />
          </v-avatar>
        </v-responsive>
      </v-badge>
    </template>
    <v-list>
      <v-list-item>
        <v-btn plain grow @click="linkTo(user.username)">
          <v-icon class="mr-1">mdi-account</v-icon>Mon profil
        </v-btn>
      </v-list-item>
      <v-list-item v-for="items in account" v-bind:key="items.text">
        <router-link style="text-decoration: none; color: inherit;" :to="{path:items.route}">
          <v-btn plain grow>
            <v-icon class="mr-1">{{items.icon}}</v-icon>
            {{items.text}}
          </v-btn>
        </router-link>
      </v-list-item>
      <v-list-item>
        <v-btn plain grow v-on:click="disconnect">
          <v-icon class="mr-1">mdi-exit-to-app</v-icon>Deconnexion
        </v-btn>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <router-link style="text-decoration: none; color: inherit;" :to="{path:'/Administrateur'}">
          <v-btn plain grow>
            <v-icon class="mr-1">{{user.adminIcon}}</v-icon>
            {{user.text}}
          </v-btn>
        </router-link>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import firebase from "firebase";
export default {
  name: "userDropdown",
  methods: {
    linkTo(link) {
      this.$router.push({ name: "user", params: { id: link } });
    },

    goToLogin() {
      auth.router.push("/Login");
    },
    disconnect() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push("/Login");
        });
    },
  },
  data() {
    return {
      user: {
        username: "KaowBeats",
        avatar: "./avatar-2.png",
        email: "blahblah@hotmail.fr",

        text: "Espace Administrateur",
        adminIcon: "mdi-gavel",
        route: "/Administrateur",
      },
      account: [
        // { text: "Mon profil", icon: "mdi-account", route: "/" },
        {
          text: "Tableau de bord",
          icon: "mdi-clipboard-play-outline",
          route: "/Dashboard",
        },
        {
          text: "Paramètres",
          icon: "mdi-account-cog-outline",
          route: "/settings",
        },
      ],
    };
  },
};
</script>
<style lang="">
</style>