<template lang="">
<div class='grey darken-4 pb-5'>
<div>
  <v-row class="justify-center">
	  <v-col cols="12" sm=11 md=10 class="pt-7" :class="textMargin">
		<span class="white--text h1 style"> Styles musicaux</span> <br>
		<p class="white--text heading-3 subtext">
			Naviguez parmis nos styles musicaux les plus populaires.
		</p>
	  </v-col>
  </v-row>
  <v-row :class="card">
  <v-col cols="12" class="my-1 d-flex flex-row">
    <v-row class='justify-center'>
  <v-col cols="12" sm="5" md="3" lg="3" xl=2 v-for="item in rubric" :key="item.genre"
    class="d-flex flex-row mx-1 mt-2 card grey darken-3" :href="item.route">
    <v-list width="3rem" class="ml-3 grey darken-3">
                <v-img width="3rem"
                :src="item.avatar" large></v-img>
    </v-list>
    <v-list class="text-start ml-1 grey darken-3"  height="3rem" width="11em" >
        <div>
        <v-list-item-title width="100%" class="white--text">
          {{item.genre}}
          </v-list-item-title>
        </div>
        <div>
        <v-list-item-subtitle class="white--text" width="100%">
          {{item.nbOfSong}} instrumentales
        </v-list-item-subtitle>
        </div>
    </v-list>
    </v-col>
    </v-row>
  </v-col>
  </v-row>
</div>
</div>
</template>
<script>
export default {
	props:{
		rubricCard:Array,
	},
	created() {
        this.rubric = this.rubricCard.map(({ genre, avatar, nbOfSong, route }) => ({ genre: genre, avatar: avatar, nbOfSong: nbOfSong, route: route,}));
    },
	computed: {
		textMargin(){
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "";
				case "sm":
					return "ml-6";
				case "md":
					return "ml-7";
				case "lg":
					return "mr-7";
				case "xl":
					return "mr-12";
				default:
					return "";
			}	
		},
		card(){
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "justify-space-around";
				case "sm":
					return "";
				case "md":
					return "justify-center";
				case "lg":
					return "20%";
				case "xl":
					return "15%";
				default:
					return "justify-space-around";
			}	
		},
		// Change la longueur du rubricCard entier
		itemWidth() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "100%";
				case "sm":
					return "30%";
				case "md":
					return "25%";
				case "lg":
					return "20%";
				case "xl":
					return "15%";
				default:
					return "15%";
			}
		},
	},
	data() {
		return {
			rubric: [
				{
					genre: String,
					avatar: String,
					nbOfSong: Number,
					route: String,
				},
			],
		};
	},
};
</script>
<style>
@media (max-width: 640px) {
	.subtext {
		font-size: 105%;
	}
	.header {
		margin-left: 10px;
	}
	.style{
		font-size: 140%;
	}
}
@media (min-width: 640px) {
	.subtext {
		font-size: 140%;
	}
	.style {
		font-size: 170%;
	}
	.header {
		margin-left: 25px;
	}
}
@media (min-width: 960px) {
	.subtext {
		font-size: 160%;
	}
	.style {
		font-size: 170%;
	}
	.header {
		margin-left: 6em;
	}
}
@media (min-width: 1264px) {
	.header {
		margin-left: 7em;
	}
}
@media (min-width: 1904px) {
	.header {
		margin-left: 13em;
	}
	.style{
		font-size: 150%;
	}
}
.card:hover {
	transition: all 300ms;
	transform: translate(0, -10px);
}
</style>