<template>
  <v-menu :close-on-content-click="false" dark offset-y max-width="335">
    <template v-slot:activator="{ on, attrs }">
      <div>
        <!-- Possibilite de mettre la props 'content' pour afficher
        DANS le badge le nombre de notif-->
        <v-badge bordered overlap color="red" offset-x="27" offset-y="22" v-show="newNotification">
          <v-btn v-bind="attrs" v-on="on" icon @click="newNotification = false">
            <v-icon>mdi-bell</v-icon>
          </v-btn>
        </v-badge>
        <v-btn v-show="!newNotification" v-bind="attrs" v-on="on" icon @click="newNotification = false">
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </div>
    </template>
    <v-list two-line>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-list two-line v-if="notification.notifications != undefined">
              <v-card height="25" class="d-flex text-center">
                <!-- A garder pour le moment, peut servir s'ils veulent
                un autre système pour les notifications-->

                <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon small>
                <v-icon class="mb-3 ml-4">mdi-email-mark-as-unread</v-icon>
                </v-btn>
              </template>
                <span>Tout marquer comme lu</span>
                </v-tooltip>-->
                <v-spacer>Notifications</v-spacer>
              </v-card>
              <template v-for="(item,index) in notification.notifications.slice(0,6).reverse()">
                <router-link style="text-decoration: none; color: inherit;" :to="{path:item.route}">
                  <v-list-item :key="index">
                    <v-list-item-avatar>
                      <img :src="item.avatar" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <div>
                        <span>{{ item.notifier }}</span>
                        <span v-text="message(item.content)"></span>
                      </div>
                      <v-row height="10" class="caption ml-1 mt-1">
                        <span v-if="currentDate - (item.createdAt.seconds) < 3599" class="grey--text">
                          Il y a {{ formatDate(item.createdAt.seconds) }} minute(s).
                          {{ item.notifier }}
                        </span>
                        <span v-if="currentDate - (item.createdAt.seconds) >3600 && currentDate - (item.createdAt.seconds) <86399 " class="grey--text">
                          Il y a {{ formatDate(item.createdAt.seconds) }} heure(s).
                          {{ item.notifier }}
                        </span>
                        <span v-if="currentDate - (item.createdAt.seconds) > 86400" class="grey--text">
                          Il y a {{ Math.trunc(formatDate(item.createdAt.seconds)/24) }} jour(s).
                          {{ item.notifier }}
                        </span>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </router-link>
              </template>
              <v-row class="justify-center mt-1 text-center">
                <router-link style="text-decoration: none; color: inherit;" :to="{path:'/notifications'}">
                  <v-btn height="2em" block>Voir toutes les notifications</v-btn>
                </router-link>
              </v-row>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-list>
  </v-menu>
</template>
<script>
import { db } from "../../firebase";
import "firebase/firestore";
export default {
  methods: {
    // Converti le timestamp et l'heure locale en secondes puis calcule la différence entre les deux
    formatDate(timestamp) {
      var difference = this.currentDate - timestamp;
      var nbOfHours = difference / 60 / 60;
      return Math.trunc(nbOfHours);
    },
    // Rempli le contenu de la notification en fonction du type de contenu
    message(content) {
      switch (content) {
        case "Communauté":
          return "a commenté votre publication dans l'espace communauté.";
        case "Abonnement":
          return " s'est abonné à votre chaine.";
        case "validation":
          return "a validé la publication de votre beat !";
        case "refus":
          return "n'a pas validé la publication de votre beat. Plus d'information sur le tableau de bord ";
        case "upload":
          return "vient de mettre en ligne un nouveau beat !";
        default:
          return "...";
      }
    },
  },
  data: () => ({
    // Récupère la date locale en nanoseconde et converti en seconde
    currentDate: Date.now() / 1000,
    user: {
      authId: "beatmakerAuthId",
    },
    notification: [],
    newNotification: true,
  }),
  // Récupère la notification dans firestore en utilisant authId pour filtrer et afficher uniquement notif de l'utilisateur
  firestore() {
    return {
      notification: db.collection("Notifications").doc(this.user.authId),
    };
  },
};
</script>
<style scoped>
.test {
  font-size: 90%;
}
</style>