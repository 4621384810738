<template>
  <v-container fluid class="grey" style="min-height:87vh">
    <Navbar />
    <v-row class="ml-1 mt-1">
      <v-icon color="white">mdi-bell</v-icon>
      <span class="h1 white--text ml-1">Notifications</span>
    </v-row>
    <v-row class="justify-center mt-8">
      <v-col cols="11" sm="10" md="8" lg="7" class="white rounded-lg">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="h1">id</th>
                <th class="h1">Content</th>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <th class="h1" v-bind="attrs" v-on="on">Type</th>
                  </template>
                  <v-list>
                    <v-list-item v-for="(sortType, index) in ['Toutes les notifications','Instrurap','Communauté','Abonnement']" :key="index">
                      <v-list-item-title @click="sortBy(sortType)" class="item">{{ sortType }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </tr>
            </thead>
            <tbody v-if="sortedNotifications != undefined">
              <!-- Slice and reverse the array to have items ordered by creation date -->
              <tr v-for="(item,index) in sortedNotifications.slice().reverse()" :key="item.id" @click="route(item.route)" class="notif">
                <td>{{ index+1 }}</td>
                <td>
                  <span class="font-weight-bold">{{item.notifier}}</span>
                  <span>{{ message(item.content) }}</span>
                </td>
                <td>{{item.type}}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Navbar from "../Global_Components/Navbar.vue";
import { db } from "../../firebase";
export default {
  components: {
    Navbar,
  },
  methods: {
    message(content) {
      switch (content) {
        case "Communauté":
          return "a commenté votre publication dans l'espace communauté.";
        case "Abonnement":
          return " s'est abonné à votre chaine.";
        case "validation":
          return "a validé la publication de votre beat !";
        case "refus":
          return "n'a pas validé la publication de votre beat. Plus d'information sur le tableau de bord ";
        case "upload":
          return "vient de mettre en ligne un nouveau beat !";
        default:
          return "...";
      }
    },
    /**
     * Updates the type of sorting
     *
     * @param {String} item - The sort type
     */
    sortBy(sortType) {
      this.sortType = sortType;
    },
    route(route) {
      this.$router.push(route);
    },
  },
  data() {
    return {
      user: {
        authId: "beatmakerAuthId",
      },
      userNotifications: [],
      sortType: "Toutes les notifications",
    };
  },

  firestore() {
    return {
      userNotifications: db.collection("Notifications").doc(this.user.authId),
    };
  },
  computed: {
    /**
     * Sorts notifications by type
     */
    sortedNotifications() {
      switch (this.sortType) {
        case "Toutes les notifications":
          return this.userNotifications.notifications;
          break;

        case "Instrurap":
          return this.userNotifications.notifications.filter((notif) => {
            return notif.type == "instrurap";
          });
          break;

        case "Communauté":
          return this.userNotifications.notifications.filter((notif) => {
            return notif.type == "communauté";
          });
          break;

        case "Abonnement":
          return this.userNotifications.notifications.filter((notif) => {
            return notif.type == "abonnement";
          });
          break;
      }
    },
  },
};
</script>
<style>
.item {
  cursor: pointer;
}
.notif {
  cursor: pointer;
}
</style>