<template lang="">
<v-container fluid class="grey darken-1">
    <v-row class="py-2 px-2 mb-3"><v-icon color=white>mdi-cog</v-icon><span class="h1 white--text">Paramètres du compte</span></v-row>
    <v-row class='justify-center'>
        <v-col cols=12 md=9>
            <validation-observer ref="observer" v-slot="{ invalid }">
            <v-row class="justify-center">
                <v-col cols=12 class="mx-7">
                    <v-row class="justify-center">
                         <v-col cols="11" sm=5 lg=3 class="white rounded-lg mb-7 " :class="imageCol">
                            <v-row class="grey lighten-2 rounded-lg blue--text">
                                <v-col cols=12>Photo de profil</v-col>
                            </v-row>
                            <v-row class="justify-center text-center">
                                <v-col cols="12">
                                    <v-avatar size="210">
                                        <v-img class="mb-4" contain :src="user.imageProfile"></v-img>
                                    </v-avatar>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col class="mt-5 pb-0">
                                    <validation-provider rules="ext:png,jpg|size:2000" v-slot="{errors}">
                                    <v-file-input :error-messages="errors" class="file" required accept="image/*" @change="imageProfileUpload"v-model="setImageProfile" outlined dense></v-file-input>
                                    </validation-provider>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="11" sm=5 lg=7 offset-lg='1' class="white rounded-lg mb-7 " :class="imageCol">
                            <v-row class="grey lighten-2 rounded-lg blue--text">
                                <v-col cols=12>Photo de couverture</v-col>
                            </v-row>
                            <v-row class="justify-center text-center">
                                <v-col cols="12" class="px-10">
                                    <v-img class="mb-4 fit" height="12em" position="center top" :src="user.imageProfile"></v-img>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col class="mt-5 pb-0">
                                    <validation-provider rules="ext:png,jpg|size:2000" v-slot="{errors}">
                                    <v-file-input :error-messages="errors" required accept="image/*" @change="imageCoverUpload" class="form-control"v-model="setImageCover" outlined dense></v-file-input>
                                    
                                    </validation-provider>
                                </v-col>
                                
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="11" class="white rounded-lg mx-7 mb-10">
                    <v-row class="justify-center mb-1">
                        <v-col cols="12" class="grey lighten-2 rounded-lg blue--text">Détails du compte</v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols=12 md=6 class="spacing2">
                            <span class="caption">Nom d'affichage</span>
                            <v-text-field  solo dense outlined v-model="setDisplayName" label="Nom d'affichage"></v-text-field>
                        </v-col>
                        <v-col cols=12 md=6 class='spacing2'>
                            <validation-provider rules="alpha" v-slot="{errors}">
                                <span class="caption">Nom d'utilisateur</span>
                                <v-text-field :error-messages="errors" required solo v-model="setUsername" dense outlined label="Nom d'utilisateur"></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row dense>
                        <v-col class="py-0 mx-0 spacingRoles" cols=11>
                            <span class="caption">Type de compte</span>
                            <v-row class="justify-center">
                                <!-- TODO : AFFICHE TYPE PAR DEFAUT, 1 SEUL TYPE PEUT ETRE COCHE A LA FOIS -->
                                <v-col  cols=11 sm=4>
                                    <v-row class="align-center" :class="justifyRole">
                                        <v-checkbox color=black></v-checkbox>
                                        <span class="roleSize">Compositeur</span>
                                        <v-avatar class="ml-2" size="15px" tile>
                                            <v-img src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Fbadge_compositeur.svg?alt=media&token=2dd6dbc0-58dd-41df-bc24-a46864d6534c"></v-img>
                                        </v-avatar>
                                    </v-row>
                                </v-col>
                                <v-col  cols=11 sm=4>
                                    <v-row class="align-center" :class="justifyRole">
                                        <v-checkbox color=black></v-checkbox>
                                        <span class="roleSize">Interprète</span>
                                        <v-avatar class="ml-2" size="15px" tile>
                                            <v-img src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Fbadge_interpr%C3%A8te.svg?alt=media&token=57370c78-0fea-43c6-a3ce-487e08d00b33"></v-img>
                                        </v-avatar>
                                    </v-row>
                                </v-col>
                                <v-col cols=11 sm=4>
                                    <v-row class="align-center" :class="justifyRole">
                                        <v-checkbox color=black></v-checkbox>
                                        <span class="roleSize">Structure</span>
                                        <v-avatar class="ml-2" size="15px" tile>
                                            <v-img src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Fbadge_entreprise.svg?alt=media&token=933d60b6-b7b2-4e04-a3df-ec3cc3fadae8"></v-img>
                                        </v-avatar>
                                    </v-row>
                                </v-col>
                                
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row dense>
                        <v-col class="py-0 mx-0 spacing" cols=12 md=6>
                            <span class="caption">Nom d'utilisateur Instagram</span>
                            <v-text-field  solo dense outlined v-model="setInstagram" label="Nom d'utilisateur Instagram"></v-text-field>
                        </v-col>
                        <v-col class="py-0 mx-0 spacing" cols=12 md=6>
                            <span class="caption">Nom d'utilisateur Snapchat</span>
                            <v-text-field  solo dense outlined v-model="setSnapchat" label="Nom d'utilisateur Snapchat"></v-text-field>
                        </v-col>                        
                        <v-col class="py-0 mx-0 spacing2" cols=12 md=6>
                            <span class="caption">Nom d'utilisateur Twitter</span>
                            <v-text-field  solo dense outlined v-model="setTwitter" label="Nom d'utilisateur Twitter"></v-text-field>
                        </v-col>
                        <v-col class="py-0 mx-0 spacing2" cols=12 md=6>
                            <span class="caption">Nom d'utilisateur Facebook</span>
                            <v-text-field  solo dense outlined v-model="setFacebook" label="Nom d'utilisateur Facebook"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row dense>
                        <v-col class="py-0 mx-0 spacing2" cols=12 md=6>
                            <validation-provider rules="confirmed:confirmation" v-slot="{ errors }">
                                <span class="caption">Modification du mot de passe</span>
                                <v-text-field :error-messages="errors" type="password"  solo dense outlined v-model="setPassword" label="Mot de passe"></v-text-field>                            
                            </validation-provider>
                        </v-col>
                        <v-col class="py-0 mx-0 spacing2" cols=12 md=6>
                            <validation-provider v-slot="{ errors }" vid="confirmation">
                                <span class="caption">Confirmer nouveau mot de passe</span>
                                <v-text-field :error-messages="errors" type="password" v-model="confirmation" solo dense outlined label="Confirmer mot de passe"></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row dense>
                        <v-col cols=12 md=6 class="py-0 mx-0 spacing">
                            <span class="caption">URL de la chaine Youtube</span>
                            <v-text-field  solo dense outlined v-model="setYoutube" label="youtube.com/c/..."></v-text-field>
                        </v-col>
                        <v-col cols=12 md=6 class="py-0 mx-0 spacing2">
                            <span class="caption">URL du site internet</span>
                            <v-text-field  solo dense outlined v-model="setWebsite" label="https/example.com"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row dense>
                        <v-col cols="12" class="mb-8 spacing2">
                            <validation-provider rules="max:350" v-slot="{ errors }">
                                <span class="caption">Bio du profil</span>
                                <v-textarea :error-messages="errors" :counter="350" rows="2" v-model="setBio" no-resize solo label="Bio à définir" outlined ></v-textarea>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row dense class="justify-center">
                        <v-col cols="12" md=6 class="mt-5 spacing3" :class="btnUpdate">
                            <v-btn v-if="invalid===false" @click="updateProfile" small color="info">Mettre à jour</v-btn>
                            <v-btn v-if="invalid===true" disabled small color="info">Mettre à jour</v-btn>
                        </v-col>
                        <v-col cols="12" md=6 class="mt-5 spacing3" :class="btnDelete">
                            <v-btn small @click="deleteAccount" dark><v-icon color="white">mdi-delete-forever-outline</v-icon> Supprimer le compte</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            </validation-observer>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
import { db } from "../../firebase";
import firebase from "firebase";
import "firebase/auth";
import { size, ext, alpha_dash, max, confirmed } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import VueSimpleAlert from "vue-simple-alert";
import "vue";
import "vuex";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import "vue-router";
let user = window.localStorage.getItem("user");
extend("ext", {
  ...ext,
  message: "Ce format de fichier n'est pas accepté",
});
extend("max", {
  ...max,
  message: "La biographie ne doit pas dépasser 350 caractères",
});
extend("alpha", {
  ...alpha_dash,
  message: "Les espaces et les caractères spéciaux ne sont pas autorisés",
});
extend("confirmed", {
  ...confirmed,
  message: "Les mots de passe ne sont pas identiques",
});
extend("size", {
  ...size,
  message: "L'image ne doit pas dépasser 2 MB",
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  created() {
    this.setDisplayName = this.name;
    this.setUsername = this.altName;
    this.setInstagram = this.instagram;
    this.setSnapchat = this.snapchat;
    this.setFacebook = this.facebook;
    this.setYoutube = this.youtube;
    this.setWebsite = this.internet;
    this.setTwitter = this.twitter;
    this.confirmation = this.password;
    this.setBio = this.bio;
  },
  methods: {
    async imageProfileUpload(e) {
      const obj = JSON.parse(user);
      let file = e.target.files[0];
      // store dans le répértoir UserImageP/C selon Profile ou Couverture, tourjours avec le Uid de l'user comme valeur de base.
      var storageRef = firebase.storage().ref("usersImageP/" + obj.uid);
      storageRef.put(file);
      console.log(e.target.files[0]);
      // 	const imageFileRef = storageRef.child(
      // 		"user/" +
      // 			this.user.id +
      // 			" " +
      // 			"imageProfile"
      // 	);
      // 	await imageFileRef.put(this.setImageProfile);
      // 	this.user.imageProfile = await imageFileRef.getDownloadURL();
    },
    async imageCoverUpload(e) {
      const obj = JSON.parse(user);
      let file = e.target.files[0];
      // store dans le répértoir UserImageP/C selon Profile ou Couverture, tourjours avec le Uid de l'user comme valeur de base.
      var storageRef = firebase.storage().ref("usersImage/C" + obj.uid);
      storageRef.put(file);
      console.log(e.target.files[0]);
      console.log(this.setImageCover);
      // 	const imageFileRef = storageRef.child(
      // 		"user/" +
      // 			this.user.id +
      // 			"/" +
      // 			"imageCover"
      // 	);
      // 	await imageFileRef.put(this.setImageCover);
      // 	this.user.imageCover = await imageFileRef.getDownloadURL();
    },
    updateProfile() {
      ///mise à jour a effectuer après l'issue 130 :
      /* if (this.setPassword != null) {
        const obj = JSON.parse(user);
        obj.updatePassword(this.setPassword);
      }*/
      const obj = JSON.parse(user);

      db.collection("testAbdel")
        .where(`authID`, "==", obj.uid)
        .get()
        .then((userCredential) => {
          console.log(JSON.stringify(userCredential).docs);
          if (userCredential.empty) {
            db.collection("testAbdel").doc("CYfkM1rL8ZE5GKBSaDZn").update({
              name: this.setUsername,
              altName: this.setDisplayName,
              bio: this.setBio,
              about_instagram: this.setInstagram,
              about_twitter: this.setTwitter,
              about_youtube: this.setYoutube,
              about_snapchat: this.setSnapchat,
              about_website: this.setWebsite,
              about_facebook: this.setFacebook,
            });
            alert("modification effectué avec succès");
          }
        });
      // Set the 'capital' field of the city
    },
    deleteAccount() {
      const email = "aa@aa.aa";
      const mdp = "aaaaaa";
      firebase
        .auth()
        .signInWithEmailAndPassword(email, mdp)

        .then((response) => {
          firebase.auth().currentUser.delete();
          alert(" suppréssion réussi !");
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
  computed: {
    ...mapGetters(["getUser", "isUserAuth"]),
    imageCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "";
        case "sm":
          return "mx-7 mb-14";
        case "md":
          return "mx-7 mb-15";
        case "lg":
          return "mx-10 mb-16";
        case "xl":
          return "mb-14";
        default:
          return "";
      }
    },
    justifyRole() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "";
        case "sm":
          return "justify-center";
        case "md":
          return "justify-center";
        case "lg":
          return "justify-center";
        case "xl":
          return "justify-center";
        default:
          return "";
      }
    },
    btnUpdate() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-center";
        case "sm":
          return "text-center";
        case "md":
          return "text-start";
        case "lg":
          return "text-start";
        case "xl":
          return "text-start";
        default:
          return "text-center";
      }
    },
    btnDelete() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-center";
        case "sm":
          return "text-center";
        case "md":
          return "text-end";
        case "lg":
          return "text-end";
        case "xl":
          return "text-end";
        default:
          return "text-center";
      }
    },
  },
  props: {
    name: String,
    altName: String,
    instagram: String,
    snapchat: String,
    facebook: String,
    youtube: String,
    internet: String,
    twitter: String,
    bio: String,
  },
  data() {
    const obj = JSON.parse(user);
    return {
      setDisplayName: "",
      setUsername: "",
      setPassword: "",
      confirmation: "",
      setImageProfile: null,
      setImageCover: null,
      setBio: "",
      setInstagram: "",
      setSnapchat: "",
      setTwitter: "",
      setFacebook: "",
      setYoutube: "",
      setWebsite: "",

      user: {
        username: "Green",
        password: "pass",
        imageProfile: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
        imageCover: "",
        bio: "",
        instagram: "",
        snapchat: "",
        twitter: "",
        facebook: "",
        youtube: "",
        website: "",
      },
    };
  },
};
</script>
<style>
.roleSize {
  font-size: 90%;
}
.spacing {
  height: 5em;
}
.spacing2 {
  height: 6em;
}
.spacingRoles {
  height: 13em;
}
@media (min-width: 768px) {
  .spacingRoles {
    height: 5em;
  }
}
.spacing3 {
  height: 3em;
}
</style>