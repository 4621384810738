<template lang="">
<v-container fluid >
    <v-row class='justify-center'>
        <v-col cols=12 class="px-0 blue darken-4">
            <span class="headTitle white--text font-weight-bold">Liste des utilisateurs</span> <br>
            <v-list v-for="item in user" :key="item.id" class="blue darken-4">
                <v-list-item class="grey darken-4">
                    <v-btn plain text width="100%">
                        <v-row class="align-center">
                            <v-col cols="1" v-if="$vuetify.breakpoint.smAndUp">
                                <span class="grey--text userDetail">{{item.id}}</span>
                            </v-col>
                            <v-col cols=2 sm=1>
                                <v-avatar size="42" class="d-none d-sm-block">
                                    <v-img :src="item.cover"></v-img>
                                </v-avatar>
                                <v-avatar size="28" class="d-flex d-sm-none">
                                    <v-img :src="item.cover"></v-img>
                                </v-avatar>
                            </v-col>
                            <v-col cols="4" md=3 class="pb-0">
                                <p class="userDetail white--text">
                                    <span class="font-weight-bold">{{item.display}}</span> <br>
                                    <span>{{item.username}}</span><br>
                                </p>
                            </v-col>
                            <v-col cols=4 md=6>
                                <v-btn v-if="item.isChatBanned === true" dark icon class="mx-3">
                                    <v-icon >mdi-message-bulleted-off</v-icon>
                                </v-btn>
                                <v-btn v-if="item.isChatBanned === false" dark icon class="mx-3">
                                    <v-icon >mdi-message-bulleted</v-icon>
                                </v-btn>
                                <v-btn v-if="item.isBanned === true" icon class="mx-3">
                                    <v-icon color="red">mdi-minus-circle</v-icon>
                                </v-btn>
                                <v-dialog :retain-focus="false" width="30%" v-model="removeTagDialogs[item.id]" transition="dialog-bottom-transition">
                                    <template v-slot:activator="{ on, attrs }">                                            
                                        <v-btn v-if="item.isBanned === false" icon class="mx-3" v-bind="attrs" v-on="on">
                                            <v-icon color='green'>mdi-minus-circle</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title>
                                            <span>Gestion utilisateur</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-select :items="bans" v-model="banType" label="Type de ban"></v-select>
                                            <v-text-field v-model="banDuration" type="number" label="Nombre de jour" v-if="banType == 'Temporaire'" ></v-text-field>
                                            <v-row class="justify-end py-2">
                                                <v-btn @click="confirmed(item)" text>Confirmer</v-btn>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                            <v-col cols="1" class="text-end">
                                <v-btn icon>
                                    <v-icon color="white">mdi-dots-vertical</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-btn>
                </v-list-item>
            </v-list>
            <v-row class='text-end'>
                <v-col class="pt-6">
                    <v-btn width="10em" small color='black' class='white--text'>Précédent</v-btn>
                    <v-btn width="10em" small>Suivant</v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row class='justify-center'>
        <v-col cols=12 class="px-0 blue darken-4">
            <span class="headTitle white--text font-weight-bold">Liste des utilisateurs bannis</span> <br>
            <v-list v-for="item in bannedUser" :key="item.id" class="blue darken-4">
                <v-list-item class="grey darken-4">
                    <v-btn plain text width="100%">
                        <v-row class="align-center">
                            <v-col cols="1" v-if="$vuetify.breakpoint.smAndUp">
                                <span class="grey--text userDetail">{{item.id}}</span>
                            </v-col>
                            <v-col cols="1">
                                <v-avatar size="42" class="d-none d-sm-block">
                                    <v-img :src="item.cover"></v-img>
                                </v-avatar>
                                <v-avatar size="28" class="d-flex d-sm-none">
                                    <v-img :src="item.cover"></v-img>
                                </v-avatar>
                            </v-col>
                            <v-col cols="5" md=3 class="pb-0">
                                <p class="userDetail white--text">
                                    <span class="font-weight-bold">{{item.display}}</span> <br>
                                    <span>{{item.username}}</span><br>
                                </p>
                            </v-col>
                            <v-col cols=4 md=6>
                                <v-btn v-if="item.isChatBanned === true" dark icon class="mx-2">
                                    <v-icon >mdi-message-bulleted-off</v-icon>
                                </v-btn>
                                <v-btn v-if="item.isTempBanned == 0 && item.isBanned === true" icon class="mx-2">
                                    <v-icon color="red">mdi-minus-circle</v-icon>
                                </v-btn>
                                <v-btn v-if="item.isTempBanned > 0" icon class="mx-2">                                
                                    <v-icon color="warning">mdi-minus-circle</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="1" class="text-end">
                                <v-btn icon>
                                    <v-icon color="white">mdi-dots-vertical</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-btn>
                </v-list-item>
            </v-list>
            <v-row class='text-end'>
                <v-col class="pt-6">
                    <v-btn width="10em" small color='black' class='white--text'>Précédent</v-btn>
                    <v-btn width="10em" small>Suivant</v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
export default {
    props:{
        // user:Array,
        banned:Array,
    },
    methods: {
        confirmed(item){
            if (this.banType == "") {
                 this.dialog= false
            }
            else if (this.banType == "Permanent") {
                this.user.isBanned = true
                this.banType=""
                this.dialog= false
                console.log(item.username,"is banned :", this.user.isBanned)
            }
            else if (this.banType == "Temporaire") {
                this.user.isBanned = true
                this.user.isTempBanned = this.banDuration
                this.banType=""
                this.banDuration=""
                this.dialog= false
                console.log(item.username,"is banned for :", this.user.isTempBanned, this.user.isBanned)
            }
        }
    },
    data() {
        return {
            removeTagDialogs: {},
            dialog:false,
            banType:"",
            banDuration:"",
            bans:["Temporaire","Permanent"],
            bannedUser:[
                {
                    display:'Green',
                    username:"Green",
                    id:0,
                    cover:"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
                    isBanned:false,
                    isChatBanned:true,
                    isTempBanned:0,
                },
                {
                    display:'Red',
                    username:"Red",
                    id:1,
                    cover:"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
                    isBanned:true,
                    isChatBanned:false,
                    isTempBanned:0,
                },
                {
                    display:'Black',
                    username:"Black",
                    id:2,
                    cover:"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
                    isBanned:false,
                    isChatBanned:false,
                    isTempBanned:20,
                },
                {
                    display:'User',
                    username:"User",
                    id:3,
                    cover:"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
                    isBanned:false,
                    isChatBanned:true,
                    isTempBanned:20,
                },
            ],
            user:[
                {
                    display:'Green',
                    username:"Green",
                    id:0,
                    cover:"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
                    isBanned:false,
                    isChatBanned:false,
                    isTempBanned:0,
                },
                {
                    display:'Red',
                    username:"Red",
                    id:1,
                    cover:"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
                    isBanned:false,
                    isChatBanned:false,
                    isTempBanned:0,
                },
                {
                    display:'Black',
                    username:"Black",
                    id:2,
                    cover:"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
                    isBanned:false,
                    isChatBanned:false,
                    isTempBanned:0,
                },
                {
                    display:'User',
                    username:"User",
                    id:3,
                    cover:"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
                    isBanned:false,
                    isChatBanned:false,
                    isTempBanned:0,
                },
            ],
        }
    },    
}
</script>
<style>
.userDetail{
    font-size: 80%;
}
.headTitle{
    font-size: 150%;
}        
</style>