<template>
	<v-container fluid class="grey lighten-1">
		<v-row class="justify-center">
			<v-col cols="12" md="10">
				<v-row class="justify-center">
					<v-col cols="12" md="10" offset-xl="2">
						<v-row class="text-start">
							<v-col cols="6" sm="2" order-sm="1" class="my-3 pb-1 text-end">
								<v-btn @click="newPost = !newPost" color="#1f2d41" class="publishBtn white--text text-capitalize">
									<v-icon small color="white" class="mr-2">mdi-pencil</v-icon>Publier
								</v-btn>
							</v-col>
							<v-col cols="4" sm="2" md="1" order-sm="3" class="my-0 pb-1 text-end">
								<v-btn aria-label="Messenger" icon class="mt-3">
									<v-badge bordered bottom color="red" offset-x="5" offset-y="7">
										<v-icon size="4em">mdi-email</v-icon>
									</v-badge>
								</v-btn>
							</v-col>
							<v-col cols="12" sm="8" order-sm="2" class="mt-2 pb-1 pr-10">
								<UserSearch :beatmaker="beatmaker" />
							</v-col>
						</v-row>
					</v-col>
				</v-row>
				<v-expand-transition>
					<v-row v-if="newPost===true" class="px-5 mb-2 mt-6">
						<v-col offset-md="2" class="white rounded-lg" cols="12" md="8">
							<NewPost :user="user" />
						</v-col>
					</v-row>
				</v-expand-transition>
				<v-row>
					<v-col v-if="$vuetify.breakpoint.lgAndUp" cols="4" lg="2">
						<!-- <v-col cols="12" class="white rounded-lg">Pub</v-col> -->
						<v-col cols="12" class="white rounded-lg">
							<OnlineUser />
						</v-col>
					</v-col>
					<v-col cols="12" sm="8" lg="7" xl="8">
						<v-col cols="12" class="white rounded-lg">
							<NewsfeedItem @sub="subscribers" @like="like" @removeLike="removeLike" :beatmaker="beatmaker" :publications="publications" :user="user" />
						</v-col>
					</v-col>
					<v-col v-if="$vuetify.breakpoint.smAndUp" cols="4" lg="3" xl="2">
						<v-col cols="12" class="white rounded-lg">
							<Abonnements @follow="follow" :user="user" :SuggestedBeatmaker="SuggestedBeatmaker" :newBeatmaker="newBeatmaker" />
						</v-col>
						<!-- <v-col v-if="$vuetify.breakpoint.sm" cols="12" class="white mt-5">Pub</v-col> -->
						<v-col v-if="$vuetify.breakpoint.sm" cols="12" class="white my-5">User</v-col>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import Abonnements from "../components/CommunautePage/Abonnements.vue";
import NewsfeedItem from "../components/CommunautePage/NewsfeedItem.vue";
import NewPost from "../components/CommunautePage/NewPost.vue";
import UserSearch from "../components/CommunautePage/UserSearch.vue";
import OnlineUser from "../components/CommunautePage/OnlineUser.vue";
export default {
	components: {
		Abonnements,
		NewsfeedItem,
		NewPost,
		UserSearch,
		OnlineUser,
	},
	methods: {
		subscribers(id) {},
		follow(id) {
			this.user.following.push(id);
		},
		like(id) {
			this.user.likedPost.push(id);
		},
		removeLike(id) {
			this.user.likedPost.splice(this.user.likedPost.indexOf(id), 1);
		},
	},
	data() {
		return {
			newPost: false,
			user: {
				id: 0,
				name: "Green Wojack",
				altName: "GreenWojack",
				badge: "Structure",
				avatar:
					"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
				isAdmin: true,
				likedPost: [],
				following: [],
			},
			beatmaker: [
				{
					id: 0,
					name: "GreenWojack",
					altName: "GreenWojack",
				},
				{
					id: 1,
					name: "RedWojack",
					altName: "RedWojack",
				},
				{
					id: 2,
					name: "BlackWojack",
					altName: "BlackWojack",
				},
				{
					id: 3,
					name: "Other Dude",
					altName: "OtherDude",
				},
			],
			newBeatmaker: [
				{
					id: 0,
					name: "NewBeatmaker0",
					altName: "NewBeatmaker0",
					avatar:
						"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
				},
				{
					id: 1,
					name: "NewBeatmaker1",
					altName: "NewBeatmaker1",
					avatar:
						"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
				},
				{
					id: 2,
					name: "NewBeatmaker2",
					altName: "NewBeatmaker2",
					avatar:
						"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
				},
				{
					id: 3,
					name: "NewBeatmaker3",
					altName: "NewBeatmaker3",
					avatar:
						"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
				},
				{
					id: 4,
					name: "NewBeatmaker4",
					altName: "NewBeatmaker4",
					avatar:
						"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
				},
				{
					id: 5,
					name: "NewBeatmaker5",
					altName: "NewBeatmaker5",
					avatar:
						"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
				},
				{
					id: 6,
					name: "NewBeatmaker6",
					altName: "NewBeatmaker6",
					avatar:
						"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
				},
			],
			SuggestedBeatmaker: [
				{
					id: 0,
					name: "SuggestedBeatmaker0",
					altName: "SuggestedBeatmaker0",
					avatar:
						"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
				},
				{
					id: 1,
					name: "SuggestedBeatmaker1",
					altName: "SuggestedBeatmaker1",
					avatar:
						"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
				},
				{
					id: 2,
					name: "SuggestedBeatmaker2",
					altName: "SuggestedBeatmaker2",
					avatar:
						"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
				},
				{
					id: 3,
					name: "SuggestedBeatmaker3",
					altName: "SuggestedBeatmaker3",
					avatar:
						"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
				},
				{
					id: 4,
					name: "SuggestedBeatmaker4",
					altName: "SuggestedBeatmaker4",
					avatar:
						"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
				},
				{
					id: 5,
					name: "SuggestedBeatmaker5",
					altName: "SuggestedBeatmaker5",
					avatar:
						"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
				},
				{
					id: 6,
					name: "SuggestedBeatmaker6",
					altName: "SuggestedBeatmaker6",
					avatar:
						"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
				},
			],
			publications: [
				{
					id: 1,
					avatar:
						"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
					beatmaker: "Green Wojack",
					altName: "GreenWojack",
					badge: "Compositeur",
					createdAt: "2021-09-22 22:17:22",
					text: "Yo l'équipe Nouveau Tuto Disponible dite moi si vous aimez ce genre de format et quel type de prod vous voulez voir sur ma chaine Le liens du tuto : https://www.youtube.com/watch?v=V8OCOMLmxmI",
					nbLikes: 0,
					userComment: "",
					subscribersID: [],
					comments: [
						{
							id: 0,
							text: "Super !",
							icon: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
							name: "Red Wojack",
							badge: "Structure",
							altName: "Red Wojack",
						},
					],
				},
				{
					id: 2,
					avatar:
						"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
					beatmaker: "Green Wojack",
					altName: "GreenWojack",
					badge: "Interprète",
					createdAt: "2021-09-22 22:17:22",
					text: "Yo l'équipe Nouveau Tuto Disponible dite moi si vous aimez ce genre de format et quel type de prod vous voulez voir sur ma chaine Le liens du tuto : https://www.youtube.com/watch?v=V8OCOMLmxmI",
					nbLikes: 0,
					userComment: "",
					subscribersID: [],
					comments: [
						{
							id: 0,
							text: "Super !",
							icon: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
							name: "Red Wojack",
							badge: "Compositeur",
							altName: "Red Wojack",
						},
					],
				},
				{
					id: 3,
					avatar:
						"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
					beatmaker: "Green Wojack",
					altName: "GreenWojack",
					badge: "Compositeur",
					createdAt: "2021-09-22 22:17:22",
					text: "Yo l'équipe Nouveau Tuto Disponible dite moi si vous aimez ce genre de format et quel type de prod vous voulez voir sur ma chaine Le liens du tuto : https://www.youtube.com/watch?v=V8OCOMLmxmI",
					nbLikes: 0,
					userComment: "",
					subscribersID: [],
					comments: [
						{
							id: 0,
							text: "Super !",
							icon: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
							name: "Red Wojack",
							badge: "Compositeur",
							altName: "Red Wojack",
						},
					],
				},
			],
		};
	},
};
</script>
<style>
</style>