<template lang="">
<v-container fluid>
    <v-row dense>
        <v-col cols=12>
            <v-row class="justify-center">
                <v-col cols="12" class="text-center">
                    <span class="white--text h1 logHead">Authentification</span> <br>
                    <span v-if="failed === false" class="grey--text text--lighten-1 logCaption">Connectez-vous pour accéder à l'espace membres</span>
                    <span v-if="failed === true" class="error--text">Adresse mail ou mot de passe incorrect(s).</span>
                </v-col>
                <v-col cols=10 md=8>
                    <validation-observer ref="form">
                        <v-form @submit.prevent="onSubmit">
                            <validation-provider rules="required|email" v-slot="{errors}">
                                <span class="grey--text text--lighten-1">Adresse mail</span>
                                <v-text-field v-on:keyup.enter="onSubmit" :error-messages="errors" v-model="mailInput" background-color="grey" outlined dense></v-text-field>
                            </validation-provider>
                            <validation-provider rules="required" v-slot="{errors}">
                                <span class="grey--text text--lighten-1">Mot de passe</span>
                                <v-text-field v-on:keyup.enter="onSubmit" type=password :error-messages="errors" v-model="passwordInput" background-color="grey" outlined dense></v-text-field>
                            </validation-provider>
                        </v-form>
                            <v-btn v-on:click="onSubmit" width="100%">Continuer</v-btn>
                            <v-btn @click="googleAuth()" text color="white" class="mt-4 mb-1" width="100%">
                                <v-avatar>
                                    <v-img contain height="2.3em" src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficons8-google.svg?alt=media&token=5e152b47-6d94-4c05-b170-fd62c1767f33"></v-img>
                                </v-avatar>
                                Se connecter avec Google</v-btn>
                            <p class="text-center grey--text text--lighten-1 mt-1">                                
                                <span>
                                    Pas encore membre ?
                                </span>
                                <span v-on:click="registerForm" class="info--text register pointer">Inscrivez-vous gratuitement</span> <br>
                                <span @click="passwordForm" class="pointer pass font-italic">Mot de passe oublié ?</span>
                            </p>
                    </validation-observer>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
import VueSimpleAlert from "vue-simple-alert";
import firebase from "firebase";
import "firebase/auth";
import { db } from "../../firebase";
let user = window.localStorage.getItem("user");
import { required, email } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
extend("required", {
  ...required,
  message: "Cette case ne doit pas rester vide",
});
extend("email", {
  ...email,
  message: "Entrez un email valide",
});
import "vue";
import "vuex";
import { mapActions } from "vuex";
import "vue-router";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  methods: {
    googleAuth() {
      const obj = JSON.parse(user);

      this.signUpGoogle({});
      db.collection("testAbdel")
        .where(`authID`, "==", obj.uid)
        .get()
        .then((userCredential) => {
          if (userCredential.empty) {
            window.mark = true;
            var today = new Date();
            var date =
              today.getFullYear() +
              "-" +
              (today.getMonth() + 1) +
              "-" +
              today.getDate();
            db.collection("testAbdel").add({
              authID: userC.user.uid,
              name: "",
              createdAt: date,
              altName: "",
              bio: "",
              mail: "",
              about_instagram: "",
              about_twitter: "",
              about_youtube: "",
              about_snapchat: "",
              about_website: "",
              about_facebook: "",
              beatmakerType: "",
              role: "",
              avatar: "",
              isBanned: false,
              isChatBanned: false,
              isTempBanned: false,
              cover: "",
              isOnline: true,
              likedSongs: [],
              createdSongs: [],
              collabSongs: [],
              nbDownload: 0,
              nbListens: 0,
              subscriptions: [],
              nbSubscriptions: 0,
              nbSubscribers: 0,
              subscribersID: [],
              isVB: false,
            });
          } else {
            console.log("no collection doc to add ");
            //rien à faire user se connecte juste
          }
          this.$router.push("/");
        });
    },
    ...mapActions(["signUpAction"]),
    ...mapActions(["signUpGoogle"]),
    registerForm() {
      this.$emit("register");
    },
    passwordForm() {
      this.$emit("password");
    },

    onSubmit() {
      console.log(this.$store.getters.getUser);
      this.signUpAction({
        email: this.mailInput,
        password: this.passwordInput,
      }).then(() => {
        console.log(this.$store.getters.getUser);
      });
      console.log(this.$store.getters.getUser);
    },
  },
  props: {
    beatmaker: Array,
  },
  created() {
    this.accounts = this.beatmaker.map(({ email }) => ({ email: email }));
  },
  data() {
    return {
      mailInput: "",
      passwordInput: "",
      failed: false,
      accounts: [
        {
          email: String,
        },
      ],
    };
  },
};
</script>
<style>
.logHead {
  font-size: 200%;
}
.pass {
  font-size: 90%;
}
@media (min-width: 768px) {
  .logHead {
    font-size: 230%;
  }
  .logCaption {
    font-size: 120%;
  }
}
</style>