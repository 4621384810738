<template lang="">
<v-container fluid>
<v-row>
    <Setting :name="user.name" :instagram="user.about_instagram" :snapchat="user.about_snapchat" :facebook="user.about_facebook" :youtube="user.about_youtube" :internet="user.about_website" :altName="user.altName" :twitter="user.about_twitter" :bio="user.bio" />
</v-row>

</v-container>
</template>
<script>
import { db } from "../firebase";
import Setting from "../components/SettingsPage/Setting.vue";
export default {
	components: {
		Setting,
	},
	created() {},

	data() {
		return {
			user: this.$store.state.user,
		};
	},
};
</script>
<style lang="">
</style>