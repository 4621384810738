
<template>
	<v-app>
		<v-main>
			<navbar />
			<router-view />
			<music-player />
			<Footer />
		</v-main>
	</v-app>
</template>

<script>
import "firebase/firestore";
import Footer from "./components/Global_Components/Footer.vue";
import { db } from "./firebase";
import "firebase/firestore";
import MusicPlayer from "./components/Global_Components/MusicPlayer.vue";
import { mapGetters } from "vuex";
import Navbar from "./components/Global_Components/Navbar.vue";

export default {
	components: {
		Footer,
		MusicPlayer,
		Navbar,
	},
	computed: {
		...mapGetters(["getUser", "isUserAuth"]),
	},
	name: "App",

	data() {
		return {
			couille: "",
			users: [],
		};
	},
	firestore() {
		return {
			// users: db.collection("users"),
		};
	},
};
</script>
<style>
@import url("./assets/Style/main.css");
</style>