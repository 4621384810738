<template >
	<v-app-bar app dark height="65" class="py-0 my-0">
		<v-row class="justify-space-between align-center">
			<v-col cols="3" md="2" lg="2" class="py-0 my-0 px-0">
				<router-link style="text-decoration: none; color: inherit;" :to="{path:'/'}">
					<v-img
						lazy
						alt="InstruRap Logo"
						class="px-0 my-0 ml-3 my-0 pointer"
						src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Flogo_long_vector_final.svg?alt=media&token=dd634e8a-ca45-4dc4-a6d6-5c25cc912680"
						transition="scale-transition"
						contain
						width="175"
						height="65"
					/>
				</router-link>
			</v-col>
			<v-col cols="9" md="10">
				<v-row class="mt-1">
					<v-row class="justify-space-between mt-1">
						<v-col cols="8" class="d-none d-lg-block">
							<v-row>
								<v-col cols="3">
									<router-link style="text-decoration: none; color: inherit;" :to="{path:'/'}">
										<v-btn :plain="homePage" text>Instrumentales</v-btn>
									</router-link>
								</v-col>
								<v-col cols="3">
									<router-link style="text-decoration: none; color: inherit;" :to="{path:'/Loops'}">
										<v-btn text :plain="loopsPage">Sample & Drumkit</v-btn>
									</router-link>
								</v-col>
								<v-col cols="3">
									<v-menu offset-y open-on-click>
										<template v-slot:activator="{on, attrs}">
											<v-btn plain v-bind="attrs" v-on="on">
												UNIVERS INSTRURAP
												<v-icon>mdi-menu-down</v-icon>
											</v-btn>
										</template>
										<v-list>
											<v-list-item v-for="(item, social) in social" v-bind:key="social.title">
												<v-btn plain grow :href="item.route" target="_blank">
													<v-icon>{{item.icon}}</v-icon>
													<span class="ml-1">{{item.title}}</span>
												</v-btn>
											</v-list-item>
										</v-list>
									</v-menu>
								</v-col>
								<v-col class="text-end" cols="3">
									<router-link style="text-decoration: none; color: inherit;" :to="{path:'/communaute'}">
										<v-btn text :plain="communautéPage">Communauté</v-btn>
									</router-link>
								</v-col>
							</v-row>
						</v-col>
						<v-spacer class="d-lg-none"></v-spacer>
						<v-col cols="11" sm="8" md="5" lg="4" class="px-0 mb-5">
							<v-row class="justify-end">
								<v-col cols="12" lg="9" xl="7" class="mr-3" v-if="userIsAuth">
									<v-row class="justify-end align-center">
										<v-col cols="11" sm="8" md="9" lg="11" class="mb-3">
											<v-row class="justify-center mt-1">
												<router-link style="text-decoration: none; color: inherit;" :to="{path:'/Publier'}">
													<v-btn light class="d-none d-md-block mx-2">
														<v-row>
															<v-col class="mt-2">
																<span class="mr-2">Publier</span>
																<v-avatar tile size="22">
																	<v-img src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_publication_pc_noir.svg?alt=media&token=8aa81160-f11a-4de2-86ac-ee2f6604d57f"></v-img>
																</v-avatar>
															</v-col>
														</v-row>
													</v-btn>
												</router-link>
												<v-btn plain class="d-md-none">
													<v-avatar tile size="32">
														<v-img src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_publication_blanc_mobile.svg?alt=media&token=c2071a13-39a1-462c-9366-1cb0fa7d7867"></v-img>
													</v-avatar>
												</v-btn>
												<notificationDropdown />
												<userDropdown />
											</v-row>
										</v-col>
									</v-row>
								</v-col>
								<v-col cols="12" xl="9" v-else>
									<v-btn class="mx-2" v-if="$vuetify.breakpoint.smAndUp" plain router :to="'Login'">Se connecter</v-btn>
									<v-btn class="mx-2" v-if="$vuetify.breakpoint.smAndUp" light router :to="{path:'Login', query:{signup:true}}">S'inscrire</v-btn>
									<v-btn class="mx-1 d-sm-none" x-small plain router :to="'Login'">Se connecter</v-btn>
									<v-btn class="mx-1 d-sm-none" x-small light router :to="{path:'Login', query:{signup:true}}">S'inscrire</v-btn>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-row>
			</v-col>
		</v-row>
		<v-app-bar-nav-icon class="d-md-none grey--text" @click="drawer = !drawer"></v-app-bar-nav-icon>
		<v-navigation-drawer absolute temporary right v-model="drawer" app>
			<v-list v-if="userIsAuth">
				<v-list-item class="ml-3 px-2">
					<v-row>
						<v-list-item-action>
							<userDropdown />
						</v-list-item-action>
						<v-col class="mr-3">
							<span class="text-center ml-5 overline">{{user.username}}</span>
							<v-btn small v-on:click="disconnect()">Deconnexion</v-btn>
						</v-col>
					</v-row>
				</v-list-item>
			</v-list>
			<v-list v-else>
				<v-list-item>
					<v-list-item-action class="mx-8">
						<v-btn large v-on:click="isAuth()">Se connecter</v-btn>
					</v-list-item-action>
				</v-list-item>
			</v-list>
			<v-list>
				<v-list-item v-for="link in links" :key="link.text" router :to="link.route">
					<v-list-item-action>
						<v-icon class="white--text">{{link.icon}}</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title class="white--text">{{link.text}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-menu offset-y open-on-click>
					<template v-slot:activator="{on, attrs}">
						<v-list-item v-bind="attrs" v-on="on">
							<v-list-item-action>
								<v-icon>mdi-music-circle</v-icon>
							</v-list-item-action>
							<v-list-item-title>
								Univers Instrurap
								<v-icon>mdi-menu-down</v-icon>
							</v-list-item-title>
						</v-list-item>
					</template>
					<v-list>
						<v-list-item v-for="(item, social) in social" v-bind:key="social.title" :href="social.route">
							<v-btn plain grow :href="item.route" target="_blank">
								<v-icon>{{item.icon}}</v-icon>
								{{item.title}}
							</v-btn>
						</v-list-item>
					</v-list>
				</v-menu>
			</v-list>
		</v-navigation-drawer>
	</v-app-bar>
</template>
<script>
import userDropdown from "./userDropdown.vue";
import notificationDropdown from "../notificationPage/notificationDropdown.vue";

export default {
	name: "Navbar",
	components: {
		userDropdown,
		notificationDropdown,
	},
	created() {
		console.log(this.$router.currentRoute.name, "current route");
	},
	computed: {
		homePage() {
			switch (this.$router.currentRoute.name) {
				case "HomePage":
					return false;

				default:
					return "";
			}
		},
		loopsPage() {
			switch (this.$router.currentRoute.name) {
				case "Loops":
					return false;

				default:
					return "";
			}
		},
		communautéPage() {
			switch (this.$router.currentRoute.name) {
				case "Communaute":
					return false;

				default:
					return "";
			}
		},
		userIsAuth() {
			return this.user != null;
		},
		user() {
			return this.$store.state.user;
		},
	},

	data() {
		return {
			// Controle le NavDrawer à droite de l'écran sur petit écran
			drawer: false,
			// Liens de l'espace beatmaker
			// Liens de la Navbar
			links: [
				{
					icon: "mdi-bell-ring",
					text: "Notifications",
					route: "/notifications",
				},
				{ icon: "mdi-music-box", text: "Publier", route: "/publier" },
				{
					icon: "mdi-account-music",
					text: "Sample & Drumkit",
					route: "/loops",
				},
				{
					icon: "mdi-account-music",
					text: "Instrumentales",
					route: "/",
				},
				{
					icon: "mdi-account-supervisor",
					text: "Communaute",
					route: "/communaute",
				},
			],
			// Info user

			// Menu déroulant en cliquant sur icone compte
			account: [
				{ text: "Mon profil", icon: "mdi-account", route: "/account" },
				{
					text: "Tableau de bord",
					icon: "mdi-clipboard-play-outline",
					route: "/tableau",
				},
				{
					text: "Paramètres",
					icon: "mdi-account-cog-outline",
					route: "/settings",
				},
			],
			// Menu déroulant Univers Instrurap
			social: [
				{
					title: "Youtube",
					icon: "mdi-youtube",
					route: "https://www.youtube.com/channel/UC0seBfmqfs6su72F5Ac90-w",
				},
				{
					title: "Instagram",
					icon: "mdi-instagram",
					route: "https://www.instagram.com/instrurap_/",
				},
				{
					title: "Snapchat",
					icon: "mdi-snapchat",
					route: "https://www.snapchat.com/add/InstruRap",
				},
				{
					title: "Nous contacter",
					icon: "mdi-email",
					route: "/contact",
				},
			],
		};
	},
};
</script>
<style>
</style>