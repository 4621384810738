<template lang="">
<v-container fluid>
    <v-row>
        <v-img class="img" :height="imgHeight" :src="beatmakerInfos.cover">
            <v-row class="justify-center">
                <v-col cols=11 xl=10 class="pt-10">
                    <v-row class="justify-center">
                        <v-avatar :size="avatarSize" class="mt-1">
                            <v-img :src="beatmakerInfos.avatar"></v-img>
                        </v-avatar>
                        <v-col cols="12" xl=7 class="white--text ml-2 py-0">
                            <p>
                                <span class="h1 username">{{beatmakerInfos.name}}</span> <br>
                                <span class="overline">{{beatmakerInfos.nbSubcribers}} abonnés</span>
                                <span class="ml-1 overline">{{beatmakerInfos.nbSubscriptions}} abonnements</span>
                                <span class="overline ml-1">{{beatmakerInfos.nbListens}} écoutes</span>
                                <span class="overline ml-1">{{beatmakerInfos.nbDownload}} téléchargements</span>  <br>
                                <v-sheet :max-height="bioHeight" class="white--text font-weight-bold transparent overflow-hidden">{{beatmakerInfos.bio}}</v-sheet>
                            </p>
                            <v-row v-if="$vuetify.breakpoint.xs" class="justify-center">
                                <v-btn v-if="showAll === false" @click="showAll = true" icon>
                                    <v-icon color="white" class="py-0">mdi-chevron-down</v-icon>
                                </v-btn>
                                <v-btn v-if="showAll === true" @click="showAll = false" icon>
                                    <v-icon color="white" class="py-0">mdi-chevron-up</v-icon>
                                </v-btn>
                            </v-row>
                        </v-col>
                        <v-col cols=12 xl=3>
                            <v-row class="mt-1 mb-1" :class="btnPosition">
                                <v-btn :disabled="userExist" @click="subscribe(beatmakerInfos.name)" small class="primary">S'abonner</v-btn>
                                <v-btn :disabled="userExist" @click="sendChat(beatmakerInfos.name)" small class="primary ml-2"> Envoyer un message</v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-img>
    </v-row>
</v-container>
</template>
<script>
export default {
    methods: {
        subscribe(name){
            this.$emit('subscribe', name)
        },
        sendChat(name){
            this.$emit('chat', name)
        },
    },
    computed:{
        userExist(){
            if (this.beatmaker === undefined) {
                return true
            }
            else return false
        },
        bioHeight(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    if (this.showAll === true) {
                        return "15em"
                    }
                    else return "4.5em"
                case 'sm':
                    return "6em"
                case 'lg':
                    return "6em"
                case 'xl':
                    return "6em"
                default:
                    return "4.5em"
            }
        },
        avatarSize(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return "100px"
                case 'sm':
                    return "124px"
                case 'lg':
                    return "124px"
                case 'xl':
                    return "124px"
                default:
                    return "100px"
            }
        },
        imgHeight(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return ""
                case 'sm':
                    return "40vh"
                case 'lg':
                    return "50vh"
                case 'xl':
                    return "25vh"
                default:
                    return "50vh"
            }
        },
        btnPosition(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return "justify-space-between"
                case 'sm':
                    return "justify-end"
                case 'xl':
                    return "justify-end"
                default:
                    return "justify-space-between"
            }
        }
    },
    props:{
        beatmaker:Object,
    },
    created() {
        if (this.beatmaker === undefined) {
            return ""
        }else
        this.beatmakerInfos = Object.assign({},this.beatmaker, )
    },
    data() {
        return {
            showAll:false,
            beatmakerInfos:{
                bio:"Ce profil n'existe pas",
                avatar:"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af",
                cover:"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2Fcaught-in-joy-ptVBlniJi50-unsplash-min%20(2).jpg?alt=media&token=1de2601f-4e2f-4135-919e-ef11b4dd9111",                
            }
        }
    },
}
</script>
<style>
.username{
    font-size: 180%;
}
.img{
    opacity: 0.9;
}
 
</style>