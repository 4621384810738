<template>
	<notifications />
</template>
<script>
import notifications from "../components/notificationPage/notifications.vue";
export default {
	components: { notifications },
	component: {
		notifications,
	},
};
</script>
<style>
</style>