

import Vue from 'vue'
import Vuex from 'vuex'
import "vue-router";
import "firebase/auth";
import firebase from "firebase";
let user = window.localStorage.getItem('user');
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: user ? JSON.parse(user) : null,
    },
    getters: {
        getUser(state) {
            return state.user;
        },
        isUserAuth(state) {
            return !!state.user;
        },
        getError(state) {
            return state.error;
        }
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload;
            this.commit('saveData');
        },
        setError(state, payload) {
            state.error = payload;
        },
        setAuthIsReady(state, payload) {
            state.authIsReady = payload
        },
        saveData(state) {
            window.localStorage.setItem('user', JSON.stringify(state.user));
        }
    },
    actions: {
        signUpGoogle({ commit }, payload) {
            const provider = new firebase.auth.GoogleAuthProvider();
            firebase
                .auth()
                .signInWithPopup(provider)

        },

        signUpAction({ commit }, payload) {
            firebase
                .auth()
                .signInWithEmailAndPassword(payload.email, payload.password)
                .then(response => {
                    alert(" connexion réussi !")
                })
                .catch(error => {
                    commit("setError", error.message);
                });

        },
        async signup(context, { email, password }) {

            const res = await createUserWithEmailAndPassword(email, password)
            if (!res) {
                throw new Error('could not complete signup')
            }
        },



    }
})

// wait until auth is ready



// export the store
