/**
 * This file links the application with the firebase backend, exporting the firestore realtime database
 */


import firebase from 'firebase/app';
import 'firebase/firestore';
import "firebase/auth";
import "firebase/storage";

const firebaseApp = firebase.initializeApp({
    apiKey: 'AIzaSyDVi0e4CDqAIIyeNeg723ntJaii21Uy0gc',
    authDomain: 'instrurap-a48b3.firebaseapp.com',
    projectId: 'instrurap-a48b3',
    storageBucket: 'instrurap-a48b3.appspot.com',
    messagingSenderId: '1052846237254',
    appId: '1:1052846237254:web:b4c702fd142e699ef68c47',
    measurementId: 'G-KPWNWNGE3W',
});
const storageRef = firebaseApp.storage().ref();
const db = firebaseApp.firestore();

//  export the firestore to make it accessible from the front
export { db, storageRef };
