<template lang="">
<v-container fluid>
    <v-row>
        <v-img class="img" :height="imgHeight" :src="playlistInfos.cover">
            <v-row class="justify-center">
                <v-col cols=12 class="pt-10">
                    <v-row class="justify-center">
                        <v-avatar tile :size="avatarSize" class="mt-1 rounded">
                            <v-img :src="playlistInfos.avatar"></v-img>
                        </v-avatar>
                        <v-col cols="12" xl=7 class="white--text ml-2 py-0">
                            <p>
                                <span class="h1 username">{{playlistInfos.name}}</span> <br>
                            </p>
                            <p class="text-wrap px-1">
                                <span class="pointer artist" @click="routeTo(item.name)" v-for="item in beatmakerList" :key="item.name">
                                    {{item.name}}
                                </span>
                            </p>
                            <v-row v-if="$vuetify.breakpoint.xs" class="justify-center">
                                <v-btn v-if="showAll === false" @click="showAll = true" icon>
                                    <v-icon color="white" class="py-0">mdi-chevron-down</v-icon>
                                </v-btn>
                                <v-btn v-if="showAll === true" @click="showAll = false" icon>
                                    <v-icon color="white" class="py-0">mdi-chevron-up</v-icon>
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-img>
    </v-row>
</v-container>
</template>
<script>
export default {
    methods: {
        routeTo(link){
            this.$router.push({ name: 'user', params: { id: link } })
        },
        subscribe(name){
            this.$emit('subscribe', name)
        },
        sendChat(name){
            this.$emit('chat', name)
        },
    },
    computed:{
        userExist(){
            if (this.beatmaker === undefined) {
                return true
            }
            else return false
        },
        bioHeight(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    if (this.showAll === true) {
                        return "15em"
                    }
                    else return "4.5em"
                case 'sm':
                    return "6em"
                case 'lg':
                    return "6em"
                case 'xl':
                    return "6em"
                default:
                    return "4.5em"
            }
        },
        avatarSize(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return "100px"
                case 'sm':
                    return "124px"
                case 'lg':
                    return "124px"
                case 'xl':
                    return "124px"
                default:
                    return "100px"
            }
        },
        imgHeight(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return ""
                case 'sm':
                    return "35vh"
                case 'md':
                    return "30vh"
                case 'lg':
                    return "35vh"
                case 'xl':
                    return "20vh"
                default:
                    return ""
            }
        },
        btnPosition(){
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return "justify-space-between"
                case 'sm':
                    return "justify-end"
                case 'xl':
                    return "justify-end"
                default:
                    return "justify-space-between"
            }
        }
    },
    props:{
        PlaylistName:String,
        playlist:Array,
    },
    created() {
        this.playlistInfos.name = this.PlaylistName
        if (this.playlist === undefined) {
            return ""
        }else
        this.beatmakerList = this.playlist
    },
    data() {
        return {
            showAll:false,
            beatmakerList:[
                {
                    name:"Cette playlist n'existe pas",
                }
            ],
            playlistInfos:{
                avatar:"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Flogo_s%C3%A9lection_instrurap.png?alt=media&token=68ddebbe-83b4-478c-b5a6-e7c3cc8767db",
                cover:"https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2Fcaught-in-joy-ptVBlniJi50-unsplash-min%20(2).jpg?alt=media&token=1de2601f-4e2f-4135-919e-ef11b4dd9111",                
            }
        }
    },
}
</script>
<style>
.artist:hover{
    text-decoration: underline;
}
.username{
    font-size: 180%;
}
.img{
    opacity: 0.9;
}
 
</style>