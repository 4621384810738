<template lang="">
<v-container fluid class="black fill-height white--text py-0">
    <v-row class='fill-height text-center'>
        <v-col class="py-10">
            <v-row class="justify-center" :class="formSpacing">
                <v-img @click="routeTo()" class="pointer" height="10rem" max-width="20rem" contain lazy src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Flogo_long_vector_final.svg?alt=media&token=dd634e8a-ca45-4dc4-a6d6-5c25cc912680"></v-img>
            </v-row>
            <v-row dense class="justify-center text-start">
                <v-col cols="12" md=7 class='px-0 py-0'>
                    <LoginForm  :beatmaker="beatmaker" v-if="logForm === true" @password="displayPassword" @register="displayRegister"/>
                    <Register :beatmaker="beatmaker" v-if="regForm === true" @login="displayLogin"/>
                    <PasswordForm :beatmaker="beatmaker" @sendPassword="sendPassword" v-if="passForm === true" @login="displayLogin"/>
                </v-col>
            </v-row>
        </v-col>
        <div v-if="$vuetify.breakpoint.lgAndUp">
            <v-img max-width="50vw" max-height="100vh" src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FLoginPage%2Fnyjah-gobert-PpDDvUsepCI-unsplash.jpg?alt=media&token=2f0385d3-0163-44de-8542-b95d7700fdf7"></v-img>
        </div>
    </v-row>
</v-container>
</template>
<script>
import LoginForm from "../components/LoginPage/LoginForm.vue";
import Register from "../components/LoginPage/Register.vue";
import PasswordForm from "../components/LoginPage/PasswordForm.vue";
export default {
  components: {
    LoginForm,
    Register,
    PasswordForm,
  },

  created() {
    if (this.$route.query.signup) {
      this.logForm = false;
      this.regForm = true;
    }
  },
  methods: {
    routeTo() {
      return this.$router.push("/");
    },
    sendPassword(mail) {
      // Envoie le mot de passe par mail.
    },
    displayPassword() {
      this.logForm = false;
      this.passForm = true;
    },
    displayLogin() {
      this.passForm = false;
      this.regForm = false;
      this.logForm = true;
    },
    displayRegister() {
      this.logForm = false;
      this.regForm = true;
    },
  },
  computed: {
    formSpacing() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "mb-2";
        case "sm":
          return "mb-2";
        case "md":
          return "mb-2";
        case "lg":
          return "mb-0";
        case "xl":
          return "mb-16";
        default:
          return "mb-0";
      }
    },
  },
  data() {
    return {
      logForm: true,
      regForm: false,
      passForm: false,
      beatmaker: [
        {
          email: "t@mail.com",
          name: "Wojack",
        },
        {
          email: "truc@mail.com",
          name: "Green",
        },
        {
          email: "ted@mail.com",
          name: "Red",
        },
      ],
    };
  },
};
</script>
<style lang="">
</style>