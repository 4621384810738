<template lang="">
<v-container fluid class="blue darken-4">
    <router-view />
    <v-row>
        <v-col class="py-0 px-0">
            <v-img height="10em" src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2Ftymur-kuchumov-i6HKsKPjDKk-unsplash-min.jpg?alt=media&token=a5980479-b234-483d-8081-c473d2c592a6">
                <v-row class='justify-center px-0 py-5'>
                    <v-col cols=12>
  <v-row class="justify-center mt-4">
                            <v-col cols=12 lg=6 xl=5>
                                <UserSearch v-if="userListing === true"/>
                                <v-row>
                                    <v-col cols=12>
                                        <v-text-field v-if="submissionList === true" class="mx-4 rounded-lg" solo v-model="searchInput" @click:append="search" @keyup="search" v-on:keyup.enter="search" append-icon="mdi-magnify" placeholder="Rechercher par beatmaker, titre"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-img>
        </v-col>
    </v-row>
    <v-row class="justify-center">
        <v-col cols=12 sm=10 class="ml-3">
            <p class="tabSizing white--text">
                <v-row>
                    <span class="pointer h1 tabOne" @click="submissionTab">Gestion des soumissions </span>
                    <v-divider inset color=white class="mx-5" vertical v-if="$vuetify.breakpoint.mdAndUp"></v-divider>
                    <span class="pointer h1 tabTwo" @click="userTab">Gestion des utilisateurs</span>
                </v-row>
            </p>
        </v-col>
    </v-row>
    <v-row class="justify-center">
        <v-col cols=12 sm=10>
            <Submissions v-if="submissionList === true" :validated="searchedValidatedSongs" :song="searchedPendingSongs"/>
            <UserList v-if="userListing === true"/>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
import Submissions from "../components/AdminPage/Submissions.vue";
import UserList from "../components/AdminPage/UserList.vue";
import UserSearch from "../components/CommunautePage/UserSearch.vue";
import { is } from "vee-validate/dist/rules";
import { db } from "../firebase";

const pendingSubmissions = db
	.collection("tempSongs")
	.where("submission.status", "==", "pending");

const validatedSubmissions = db
	.collection("tempSongs")
	.where("submission.status", "==", "approved");

const allSubmissions = db
	.collection("tempSongs")
	.where("isSubmission", "==", true);

export default {
	components: {
		Submissions,
		UserList,
		UserSearch,
	},
	methods: {
		search() {
			console.log("search");
			this.$bind(
				"beatmakerResults",
				allSubmissions
					.where("beatmakerLC", ">=", this.searchInput.toLowerCase())
					.where("beatmakerLC", "<=", this.searchInput.toLowerCase() + "\uf8ff")
			);
			this.$bind(
				"titleResults",
				allSubmissions
					.where("titleLC", ">=", this.searchInput.toLowerCase())
					.where("titleLC", "<=", this.searchInput.toLowerCase() + "\uf8ff")
			);
		},
		submissionTab() {
			this.userListing = false;
			this.submissionList = true;
		},
		userTab() {
			this.submissionList = false;
			this.userListing = true;
		},
		isEmpty(array) {
			return typeof array !== "undefined" && array.length > 0;
		},
	},
	firestore() {
		return {
			songList: pendingSubmissions.limit(5),
			validated: validatedSubmissions.limit(5),
		};
	},
	computed: {
		searchedSongs() {
			return this.beatmakerResults.concat(this.titleResults);
		},
		searchedPendingSongs() {
			if (this.searchInput != "") {
				return this.searchedSongs.filter((song) => {
					return song.submission.status == "pending";
				});
			} else {
				return this.songList;
			}
		},
		searchedValidatedSongs() {
			if (this.searchInput != "") {
				return this.searchedSongs.filter((song) => {
					return song.submission.status == "approved";
				});
			} else {
				return this.validated;
			}
		},
	},
	data() {
		return {
			beatmakerResults: [],
			titleResults: [],
			submissionList: true,
			userListing: false,
			searchInput: "",
			userList: [],
			songList: [],
			validated: [],
		};
	},
};
</script>
<style>
.pageTitle {
	font-size: 140%;
}
.tabOne:hover {
	text-decoration: underline;
}
.tabTwo:hover {
	text-decoration: underline;
}
@media (min-width: 768px) {
	.tabSizing {
		font-size: 140%;
	}
	.pageTitle {
		font-size: 200%;
	}
}
</style>