<template lang="">
<v-container fluid>
    <v-row dense>
        <v-col cols=12>
            <v-row class="justify-center">
                <v-col cols=12 class="text-center">
                    <span class="white--text h1 headText">Inscription gratuite</span> <br>
                    <span v-if="mailExist === false && nameExist === false" class="grey--text text--lighten-1 captionText">C'est ici que tout commence !</span>
                    <span v-if="mailExist === true" class="error--text">Un compte existe déjà avec cette adresse mail</span>
                    <span v-if="nameExist === true && mailExist === false" class="error--text">Ce nom d'utilisateur est déjà attribué</span>
                </v-col>
                <v-col cols=10 md=8>
                    <validation-observer ref="form">
                        <v-form @submit.prevent="onSubmit">
                            <validation-provider rules="required|email" v-slot="{errors}">
                                <span class="grey--text text--lighten-1">Adresse mail</span>
                                <v-text-field v-on:keyup.enter="onSubmit" :error-messages="errors" v-model="mailInput" background-color="grey" outlined dense></v-text-field>
                            </validation-provider>
                            <validation-provider rules="required|alpha_dash|minName:3|maxName:20" v-slot="{errors}">
                                <span class="grey--text text--lighten-1">Nom d'utilisateur</span>
                                <v-text-field v-on:keyup.enter="onSubmit" :error-messages="errors" v-model="nameInput" background-color="grey" outlined dense></v-text-field>
                            </validation-provider>
                            <validation-provider rules="required|minPass:6|maxPass:30" v-slot="{errors}">
                                <span class="grey--text text--lighten-1">Mot de passe</span>
                                <v-text-field v-on:keyup.enter="onSubmit" type=password :error-messages="errors" v-model="passwordInput" background-color="grey" outlined dense></v-text-field>
                            </validation-provider>
                        </v-form>
                            <v-btn v-on:click="onSubmit" width="100%">Continuer</v-btn>
                            <v-btn @click="googleAuth()" text color="white" class="mt-4 mb-1" width="100%">
                                <v-avatar>
                                    <v-img contain height="2.3em" src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficons8-google.svg?alt=media&token=5e152b47-6d94-4c05-b170-fd62c1767f33"></v-img>
                                </v-avatar>
                                S'inscrire avec Google</v-btn>
                            <p class="text-center grey--text text--lighten-1 mt-1">                                
                                <span>
                                    En m'inscrivant j'accepte
                                </span>
                                <span class="error--text register pointer"> les conditions générales d'utilisation</span>
                                <span> ainsi que les</span>
                                <span class="error--text register pointer"> conditions générales de services </span>
                            </p>
                            <p class="text-center grey--text text--lighten-1">
                                <span>Déjà membre ?</span>
                                <span @click="loginForm" class="error--text register pointer"> Se connecter</span>
                            </p>
                    </validation-observer>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
import firebase from "firebase";
import "firebase/auth";
import { required, email, alpha_dash, min, max } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { db } from "../../firebase";
extend("required", {
  ...required,
  message: "Cette case ne doit pas rester vide",
});
extend("alpha_dash", {
  ...alpha_dash,
  message: "Les caractères spéciaux et les espaces ne sont pas autorisés",
});
extend("minName", {
  ...min,
  message: "Le nom d'utilisateur doit faire au moins 3 caractères",
});
extend("minPass", {
  ...min,
  message: "Le mot de passe doit faire au moins 6 caractères",
});
extend("maxName", {
  ...max,
  message: "Le nom d'utilisateur ne doit pas dépasser 20 caractères",
});
extend("maxPass", {
  ...max,
  message: "Le mot de passe ne doit pas dépasser 30 caractères",
});
extend("email", {
  ...email,
  message: "Entrez un email valide",
});
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  methods: {
    googleAuth() {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((userCredential) => {
          var today = new Date();
          var date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();

          db.collection("testAbdel").add({
            authID: userCredential.user.uid,
            name: this.nameInput,
            createdAt: date,
            altName: "",
            bio: "",
            mail: "",
            about_instagram: "",
            about_twitter: "",
            about_youtube: "",
            about_snapchat: "",
            about_website: "",
            about_facebook: "",
            beatmakerType: "",
            role: "",
            avatar: "",
            isBanned: false,
            isChatBanned: false,
            isTempBanned: false,
            cover: "",
            isOnline: true,
            likedSongs: [],
            createdSongs: [],
            collabSongs: [],
            nbDownload: 0,
            nbListens: 0,
            subscriptions: [],
            nbSubscriptions: 0,
            nbSubscribers: 0,
            subscribersID: [],
            isVB: false,
          });
        })
        .then((result) => {
          this.$router.push("/");
        })
        .catch((err) => {
          alert("Oops. " + err.message);
        });
    },

    loginForm() {
      this.$emit("login");
    },
    onSubmit() {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.mailInput, this.passwordInput)
        .then((userCredential) => {
          var today = new Date();
          var date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();
          db.collection("testAbdel").add({
            authID: userCredential.user.uid,
            name: this.nameInput,
            createdAt: date,
            altName: "",
            bio: "",
            mail: this.mailInput,
            about_instagram: "",
            about_twitter: "",
            about_youtube: "",
            about_snapchat: "",
            about_website: "",
            about_facebook: "",
            beatmakerType: "",
            role: "",
            avatar: "",
            isBanned: false,
            isChatBanned: false,
            isTempBanned: false,
            cover: "",
            isOnline: true,
            likedSongs: [],
            createdSongs: [],
            collabSongs: [],
            nbDownload: 0,
            nbListens: 0,
            subscriptions: [],
            nbSubscriptions: 0,
            nbSubscribers: 0,
            subscribersID: [],
            isVB: false,
          });
        })
        /* .then((res) => {
          res.user.updateProfile({
            displayName: this.user.nameInput,
          })
        })  */
        .then(() => {
          this.$router.push("/");
        })
        .catch((error) => {
          alert(error.message);
        });
    },
  },
  props: {
    beatmaker: Array,
  },
  created() {
    this.accounts = this.beatmaker.map(({ email, name }) => ({
      email: email,
      name: name,
    }));
  },
  data() {
    return {
      mailInput: "",
      nameInput: "",
      passwordInput: "",
      mailExist: false,
      nameExist: false,
      accounts: [
        {
          email: String,
          name: String,
        },
      ],
    };
  },
};
</script>
<style>
.headText {
  font-size: 200%;
}
@media (min-width: 768px) {
  .headText {
    font-size: 230%;
  }
  .captionText {
    font-size: 120%;
  }
}
</style>