<template lang="">
    <v-container fluid>
        <v-row>
            <v-col cols=12 class="white rounded-lg pt-5 pb-6">
                <v-row class="ml-1">
                    <v-btn @click="explore = true" :color="exploreColor" :class="exploreText" small>Explorer</v-btn>
                    <v-btn @click="explore = false" :color="followColor" :class="followText" small>Fil abonné(e)</v-btn>
                </v-row>
            </v-col>
            <v-col v-show="explore" class="white rounded-lg" cols=12 v-for="post in publications" :key="post.id">
            <v-divider class="py-3"></v-divider>
                    <v-lazy
                        v-model="isActive"
                        :options="{
                        threshold: .5
                        }"
                        min-height="200"
                        transition="fade-transition"
                    >
                        <v-row>
                            <v-avatar class="ml-2 mt-3">
                                <v-img @click="linkTo(post.altName)" class="pointer" :src="post.avatar"></v-img>
                            </v-avatar>
                            <v-col cols=8 md=10>
                                <span @click="linkTo(post.altName)" class="pointer h1">{{post.beatmaker}}</span>
                                <v-avatar v-if="post.badge === 'Compositeur'" class="ml-2" size="15px" tile>
                                    <v-img src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Fbadge_compositeur.svg?alt=media&token=2dd6dbc0-58dd-41df-bc24-a46864d6534c"></v-img>
                                </v-avatar>
                                <v-avatar v-if="post.badge === 'Interprète'" class="ml-2" size="15px" tile>
                                    <v-img src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Fbadge_interpr%C3%A8te.svg?alt=media&token=57370c78-0fea-43c6-a3ce-487e08d00b33"></v-img>
                                </v-avatar>
                                <v-avatar v-if="post.badge === 'Structure'" class="ml-2" size="15px" tile>
                                    <v-img src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Fbadge_entreprise.svg?alt=media&token=933d60b6-b7b2-4e04-a3df-ec3cc3fadae8"></v-img>
                                </v-avatar>  <br>
                                <span>@{{post.altName}}</span>
                            </v-col>
                            <v-col cols="1">
                                <v-btn aria-label="Delete comment" v-if="user.name == post.beatmaker || user.isAdmin ===true" icon>
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols=11 class="ml-6">
                                <span>{{post.text}}</span>
                            </v-col>
                            <v-col class="pb-0" cols=12>
                                <v-btn v-if="user.likedPost.indexOf(post.id) === -1" @click="like(post.id), post.nbLikes ++" icon>
                                    <v-icon>mdi-cards-heart</v-icon>
                                    <span>{{post.nbLikes}}</span>
                                </v-btn>
                                <v-btn v-else @click="removeLike(post.id), post.nbLikes --" icon>
                                    <v-icon color=red>mdi-cards-heart</v-icon>
                                    <span>{{post.nbLikes}}</span>
                                </v-btn>
                                <v-btn icon class="ml-5">
                                    <v-icon>mdi-chat</v-icon>
                                    <span>{{post.comments.length}}</span>
                                </v-btn>
                                <v-divider class="mt-3"></v-divider>
                            </v-col>
                            <v-col cols=12 class="pb-0" v-for="item in post.comments" :key="item.id">
                                <v-row dense>
                                    <v-col class="pb-0" cols="11" offset-md="1">
                                        <v-row dense>
                                                <v-avatar class="mt-2">
                                                    <v-img @click="linkTo(item.altName)" class="pointer" :src="item.icon"></v-img>
                                                </v-avatar>
                                            <v-col cols=8 md=10>
                                                <span @click="linkTo(item.altName)" class="h1 ml-2 pointer">{{item.name}}</span>
                                                <v-avatar v-if="item.badge === 'Compositeur'" class="ml-2" size="15px" tile>
                                                    <v-img src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Fbadge_compositeur.svg?alt=media&token=2dd6dbc0-58dd-41df-bc24-a46864d6534c"></v-img>
                                                </v-avatar>
                                                <v-avatar v-if="item.badge === 'Interprète'" class="ml-2" size="15px" tile>
                                                    <v-img src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Fbadge_interpr%C3%A8te.svg?alt=media&token=57370c78-0fea-43c6-a3ce-487e08d00b33"></v-img>
                                                </v-avatar>
                                                <v-avatar v-if="item.badge === 'Structure'" class="ml-2" size="15px" tile>
                                                    <v-img src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Fbadge_entreprise.svg?alt=media&token=933d60b6-b7b2-4e04-a3df-ec3cc3fadae8"></v-img>
                                                </v-avatar> <br>
                                                <span class="ml-2">@</span>
                                                <span>{{item.altName}}</span><br>
                                                <p class="ml-8 mt-2">{{item.text}}</p>
                                            </v-col>
                                            <v-col cols=1 class="mt-2">
                                                <v-row class='justify-end'>
                                                    <v-btn aria-label="Delete comment" v-if="user.name == item.name || user.isAdmin === true" @click="deleteComment(item.id, post.comments)" icon :class="delPosition"><v-icon>mdi-delete</v-icon></v-btn>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col class="py-0" cols="12">
                                        <v-divider></v-divider>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols=11 offset-md="1" class="mt-1 pb-1">
                                <v-row dense>
                                    <v-avatar class="mt-1">
                                        <v-img :src="user.imgPath"></v-img>
                                    </v-avatar>
                                    <v-col cols=9 md=11 class="pr-3">
                                        <validation-observer ref="observer" v-slot="{invalid}">
                                            <validation-provider rules="max:1000" v-slot="{errors}">
                                                <v-form @submit.prevent="submit">
                                                    <Mentionable @open="loadIssues()" @close="enablePost()" @search="loadIssues($event), getName($event)" :keys="['@']" :items="beatmakerList">
                                                        <v-row class="ml-1">
                                                            <v-textarea :error-messages="errors" counter='1000' rows="2" v-on:keyup.enter="onEnter(post.comments, invalid, post.userComment, post.id)" v-model="post.userComment" :placeholder="repondre(post.altName)"></v-textarea>
                                                        </v-row>
                                                        <template #no-result>
                                                            {{ loading ? 'Loading...' : 'No result' }}
                                                        </template>
                                                    </Mentionable>
                                                </v-form>
                                            </validation-provider>
                                        </validation-observer>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-lazy>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { Mentionable } from 'vue-mention'
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import {max} from "vee-validate/dist/rules";

extend("max", {
	...max,
	message: "Le commentaire ne doit pas dépasser 1000 caractères",
});

// function fetchIssues (searchText = null) {
//   return new Promise(resolve => {
//     setTimeout(() => {
//       if (!searchText) {
//         resolve(this.beatmaker)
//       } else {
//         const reg = new RegExp(searchText, 'i')
//         resolve(this.beatmaker.filter(issue => reg.test(issue.label)))
//       }
//     }, 1000)
//   })
// }
export default {
    components:{
        Mentionable,
        ValidationProvider,
		ValidationObserver,
    },
    created() {
        this.beatmakerList = this.beatmaker.map(({ name, altName, id }) => ({ value: altName, label: altName, id: id }));
    },
    computed:{
        delPosition(){
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                return "";
                case "sm":
                return "";
                case "md":
                return "mr-4";
                case "lg":
                return "mr-6";
                case "xl":
                return "mr-7";
                default:
                return "";
            }
        },
    exploreColor(){
            if (this.explore === true) {
                return "#1f2d41"
            }
            else return ""
        },
    followColor(){
            if (this.explore === false) {
                return "#1f2d41"
            }
            else return ""
        },
    exploreText(){
        if (this.explore === true) {
                return "white--text"
            }
            else return ""
    },
    followText(){
        if (this.explore === false) {
                return "white--text"
            }
            else return ""
    },
    },
    methods:{
        getName(name){
            this.selected = name
        },
        linkTo(link){
            this.$router.push({ name: 'user', params: { id: link } })
        },
        enablePost(){
            setTimeout(() => {
                this.enterDisabled = false
            }, 150);
        },
        async loadIssues (searchText = null) {
            this.enterDisabled = true
            // this.loading = true
            // this.items = await fetchIssues(searchText)
            // this.loading = false
        },
        like(id){
            this.$emit("like",id)
        },
        removeLike(id){
            this.$emit("removeLike",id)
        },
        repondre(name){
            return 'Répondre à @' + name
        },
        deleteComment(id,comment){
           var index = comment.findIndex(function(postIndex) {
               return postIndex.id == id
           })
            return comment.splice(index,1)
        },
         onEnter(comments, invalid,userComment, postId){
             if (invalid === false) {
                 
                 if (this.enterDisabled === false) {
                     
                     var id = comments.length +1
                     var comment = userComment
                     if (/\S/.test(comment)) {
                         
                         comments.push({
                             id: id,
                             text: comment,
                             icon: this.user.imgPath,
                             name:this.user.name,
                             altName:this.user.altName,
                             badge:this.user.badge
                         })
                            this.messageSent(postId)
                        
                     }
                     else return ""
                 }
                 else var item = this.beatmakerList.find(item => item.label == this.selected);
                 this.subscribersID.push(item.id)
                 this.selected = ""
             }
             else return ""
        },
        messageSent(id){
            this.publications.forEach(element => {
                if (element.id === id) {
                    element.userComment = ""
                    element.subscribersID = this.subscribersID
                }
                else return ""           
            });
        },
    },
    props:{
        user:Object,
        publications:Array,
        beatmaker:Array,
    },
    data() {
        return {
            isActive:false,
            loading:false,
            enterDisabled:false,
            explore:true,
            selected:"",
            subscribersID:[],
            beatmakerList:[
                {
                    id:0,
                    value:"",
                    label:"",
                }
            ]
        }
    },
    
}
</script>
<style>
.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
  background: rgba(41, 37, 37, 0.959);
  color:white;
  transform: translate(8em,8em);
  font-size: 90%;
}
.mention-selected {
  background: rgb(255, 255, 255);
  color:black;
}
</style>