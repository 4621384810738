import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "../views/HomePage.vue";
import DashboardPage from "../views/DashboardPage.vue"
import PublishPage from "../views/PublishPage.vue";
import Loops from "../views/Loops.vue";
import Login from "../views/Login.vue";
import NotificationPage from "../views/NotificationPage.vue";
import Communaute from "../views/Communaute.vue";
import Settings from "../views/Settings.vue";
import PopupItem from "../components/Global_Components/PopupItem.vue";
import DashboardDetails from "../components/DashboardPage/DashboardDetails.vue";
import SubmissionDetails from "../components/Global_Components/SubmissionDetails.vue";
import SongPage from "../components/Global_Components/SongPage.vue";
import UserPage from "../views/UserPage.vue";
import Playlist from "../views/Playlist.vue";
import AdminPage from "../views/AdminPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    children: [
      {
        path: "/song/:id",
        name:"SongPopup",
        component: PopupItem,
        props: {
          component: SongPage,
        }
      }
    ]
  },
  {
    path: "/Settings",
    name: "Settings",
    component: Settings
  },
  {
    path: "/Login",
    name: "Login",
    component: Login
  },
  {
    path: "/Administrateur",
    name: "Administrateur",
    component: AdminPage,
    children: [
      {
        path: "/Administrateur/Song/:id",
        name:"Submissions",
        component: PopupItem,
        props: {
          component: SubmissionDetails,
        }
      }
    ]
  },
  {
    path: "/User/:id",
    name:'user',
    component: UserPage,
  },
  {
    path: "/Playlist/:id",
    name:'playlist',
    component: Playlist,
  },
  {
    path: '/Publier',
    name: 'Publier',
    component: PublishPage
  },
  {
    path: '/Communaute',
    name: 'Communaute',
    component: Communaute
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardPage,
    children: [
      {
        path: "/dashboard/song/:id",
        name:"DashboardDetails",
        component: PopupItem,
        props: {
          component: DashboardDetails,
        }
      }
    ]
  },
  {
    path: "/loops",
    name: "Loops",
    component: Loops,
    children: [
      {
        path: "/loops/sample/:id",
        name:"SamplePopup",
        component: PopupItem,
        props: {
          component: SongPage,
        }
      }
    ]
  },
  {
    path: "/Notifications",
    name: "NotificationPage",
    component: NotificationPage,
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
