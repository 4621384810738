<template lang="">
<v-container fluid class="grey darken-1">
	<router-view />
	<v-row>
		<v-col cols="12" xl=11 class="py-0 px-0">
  			<SearchBar page="loops" @research="updateLoopsItems"/>
		</v-col>
		<v-col cols="12" class="py-0 px-0">
			<LoopsCarousel :songCards="songs"/>
		</v-col>
		<v-col cols="12" class="py-0 px-0">
			<LoopsItems :songCards="songs" class="mt-7" ref="sampleDrumRef"/>
		</v-col>
	</v-row>
</v-container>
</template>
<script>
import SearchBar from "../components/Global_Components/SearchBar.vue";
import LoopsItems from "../components/LoopsPage/LoopsItems.vue";
import LoopsCarousel from "../components/LoopsPage/LoopsCarousel.vue";
import Navbar from "../components/Global_Components/Navbar.vue";
import { db } from "../firebase";
import "firebase/firestore";
export default {
	methods: {
		updateLoopsItems(songResults, isEmpty) {
			console.log(songResults, isEmpty);
			if (isEmpty) {
				this.$refs.sampleDrumRef.songCardss = this.songs;
			} else {
				this.$refs.sampleDrumRef.songCardss = songResults;
			}
			this.$refs.sampleDrumRef.page = 0;
		},
	},
	data() {
		return {
			songResults: [],
			songs: [],
		};
	},
	firestore() {
		return {
			songs: db.collection("loops"),
		};
	},
	components: {
		SearchBar,
		LoopsItems,
		LoopsCarousel,
		Navbar,
	},
};
</script>
<style lang="">
</style>