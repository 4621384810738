<template>
	<v-col cols="12" v-if="Songs===undefined">
		<span class="white--text">Aucun titre</span>
	</v-col>
	<v-col cols="12" v-else>
		<v-row class="justify-center my-1" v-for="song in songItems" :key="song.id">
			<v-card elevation="5" dark width="100%" height="4em" rounded="lg" @click.self="audioPlayer = true" class="d-flex flex-row align-center">
				<v-snackbar v-model="audioPlayer">
					Lecteur de musique avec plein de boutons super cool ! Wow !
					<!-- stop prevent permet d'éviter d'activer le lien du composant parent
					en cliquant sur le bouton-->
					<template v-slot:action="{ attrs }">
						<v-btn color="pink" icon v-bind="attrs" @click.stop.prevent="audioPlayer = false">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</template>
				</v-snackbar>
				<v-list width="3rem" class="ml-2">
					<v-img lazy @click="audioPlayer = true" width="3rem" :src="song.imgPath" large></v-img>
				</v-list>
				<v-list class="ml-2 text-center" height="4em" :width="titleWidth">
					<div>
						<v-btn :width="titleWidth" text :x-small="titleSize" :small="altSize" @click="routeTo(song.id,'SongPopup')">{{song.title}}</v-btn>
					</div>
					<div>
						<v-btn :width="titleWidth" text :x-small="titleSize" :small="altSize" @click="routeTo(song.beatmaker,'user')">{{song.beatmaker}}</v-btn>
					</div>
				</v-list>
				<v-list class="d-none d-lg-block" width="60%">
					<v-list-item class="justify-center">
						<v-chip small class="mx-2">{{song.genre}}</v-chip>
						<v-chip small class="mx-2">{{song.licence}}</v-chip>
						<v-chip small class="mx-2">{{song.bpm}} BPM</v-chip>
						<v-chip small class="mx-2">{{song.mood}}</v-chip>
						<v-chip small class="mx-2">{{song.instrument}}</v-chip>
						<v-chip small class="mx-2">{{ song.nbListens}} écoutes</v-chip>
					</v-list-item>
				</v-list>
				<v-spacer></v-spacer>
				<v-col v-if="user.liked.indexOf(song.id) === -1" class="text-end d-none d-sm-block">
					<v-btn icon small v-if="user" @click.stop.prevent="like(song.id), song.nbLikes++">
						<v-icon class="white--text">mdi-thumb-up-outline</v-icon>
						<span class="mr-3 ml-1 white--text">{{ song.nbLikes }}</span>
					</v-btn>
				</v-col>
				<v-col v-else class="text-end d-none d-sm-block">
					<v-btn icon small v-if="user" @click.stop.prevent="removeLike(song.id), song.Like--">
						<v-icon class="white--text" color="green">mdi-thumb-up</v-icon>
						<span class="mr-3 ml-1 white--text caption">
							{{
							song.nbLikes
							}}
						</span>
					</v-btn>
				</v-col>
				<v-btn @click="routeTo(song.id,'SongPopup')" icon class="mr-2">
					<v-icon>mdi-information-outline</v-icon>
				</v-btn>
			</v-card>
		</v-row>
		<v-col cols="12">
			<v-row class="justify-center">
				<v-col cols="6" sm="12">
					<v-row :class="justifyPage" class="mt-3">
						<v-btn v-if="this.page>0" small dark @click="prevPage" class="h1">
							<!-- eslint-disable-next-line vue/no-parsing-error -->
							<span class="grey--text text--lighten-2 next">< Page Précédente</span>
						</v-btn>
						<v-btn small text @click="nextPage" class="h1">
							<span class="grey--text text--lighten-2 next">Page Suivante ></span>
						</v-btn>
					</v-row>
				</v-col>
			</v-row>
		</v-col>
	</v-col>
</template>
<script>
// TODO : link data
export default {
	props: {
		Songs: Array,
	},
	created() {
		if (this.Songs === undefined) {
			return "";
		} else
			this.songItems = this.Songs.map(
				({
					id,
					title,
					beatmaker,
					imgPath,
					songPath,
					isPlaying,
					Like,
					BPM,
					genre,
					licence,
					mood,
					instrument,
				}) => ({
					id: id,
					title: title,
					beatmaker: beatmaker,
					imgPath: imgPath,
					songPath: songPath,
					isPlaying: isPlaying,
					Like: Like,
					BPM: BPM,
					genre: genre,
					licence: licence,
					mood: mood,
					instrument: instrument,
				})
			);
	},
	data: () => ({
		user: {
			id: ["id"],
			liked: ["657417"],
		},
		submissions: {
			id: "657417",
			title: "It was only a dream",
			beatmaker: "Pylot",
			imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
			songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
			beatmakerPath: "http://iampylot.com/",
			beatmakerMail: "example@gmail.com",
			samples: true,
			isPlaying: false,
			nbListens: 111,
			nbDownload: 3,
			likes: 1,
			BPM: 130,
			genre: "Rap",
			mood: "Dark",
			instrument: "Piano",
			licence: "Licence non-commerciale",
			downloadLinks: {
				mp3: "",
				wav: "",
				tagged: "",
			},
			comments: [],
			isSubmission: true,
			submission: {
				comments: [
					{
						author: "Toto77",
						text: "Trop bien ton son",
					},
					{
						author: "BeatCritic9000",
						text: "Mouais j'ai entendu mieux",
					},
					{
						author: "MecPaumé99",
						text: "Je sais pas ce que je fais ici :/ Du coup je vais laisser un commentaire super long pour qu'on puisse voir ce que ça donne. Apparement c'est toujours pas assez long. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi non quis exercitationem culpa nesciunt nihil aut nostrum explicabo reprehenderit optio amet ab temporibus asperiores quasi cupiditate. Voluptatum ducimus voluptates voluptas?",
					},
				],
				status: "pending",
				channel: "InstruLab",
				lastUpdate: "2020-06-08T22:33:44Z",
				submissionNumber: 6,
			},
		},
		page: 0,
		// toggle le lecteur
		audioPlayer: false,
		songItems: [],
	}),

	computed: {
		justifyPage() {
			switch (this.page) {
				case 0:
					return "justify-end";
				case 1:
					return "justify-space-between";
				default:
					return "justify-space-between";
			}
		},
		//   Change la longueur du songItem entier
		itemWidth() {
			switch (this.$vuetify.breakpoint.id) {
				case "xs":
					return "70%";
				case "sm":
					return "60%";
				case "md":
					return "58%";
				case "lg":
					return "55%";
				case "xl":
					return "45%";
				default:
					return "50%";
			}
		},
		// toggle la taille small pour le bouton
		altSize() {
			switch (this.$vuetify.breakpoint.id) {
				case "sm":
					return true;
				case "md":
					return true;
				case "lg":
					return true;
				case "xl":
					return true;
				default:
					return true;
			}
		},
		// toggle la taille x-small pour le bouton
		titleSize() {
			switch (this.$vuetify.breakpoint.id) {
				case "xs":
					return true;
				default:
					return false;
			}
		},
		// Fait varier la longueur du titre
		titleWidth() {
			switch (this.$vuetify.breakpoint.id) {
				case "xs":
					return "10rem";
				case "sm":
					return "10rem";
				case "md":
					return "10rem";
				case "lg":
					return "10rem";
				case "xl":
					return "10rem";
				default:
					return "10rem";
			}
		},
	},
	methods: {
		routeTo(link, type) {
			this.$router.push({ name: type, params: { id: link } });
		},
		nextPage() {
			this.page = this.page + 1;
		},
		prevPage() {
			this.page = this.page - 1;
		},
		//   Permet de liker le son
		like(song) {
			this.user.liked.push(song);
		},
		// Permet de retirer le like d'un son
		removeLike(song) {
			this.user.liked.splice(this.user.liked.indexOf(song), 1);
		},
	},
};
</script>
<style>
</style>