<template lang="">
<v-container fluid class="primary">
    <v-row>
        <v-col>
            <v-row class="justify-center">
                <v-col cols="10">
                    <p class="white--text">
                        <span class="userAdminHead">Opération administrateur</span>
                    </p>
                    <v-row>
                        <v-col cols=5 sm=3 md=2 xl=1>
                            <v-dialog
                                transition="dialog-bottom-transition"
                                max-width="600"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn :disabled="userExist" v-bind="attrs" v-on="on" small class="mr-4">Habilitations</v-btn>
                                </template>
                                <template v-slot:default="dialog">
                                    <v-card>
                                        <v-toolbar
                                        color="primary"
                                        class="pb-4"
                                        dark
                                        >Selectionnez le rôle</v-toolbar>
                                        <v-card-text>
                                        <v-select v-model="select" :label="beatmakerRole.role" :items="role"></v-select>
                                        </v-card-text>
                                        <v-card-actions class="justify-space-between">
                                            <v-btn
                                            text
                                            color="success"
                                            @click="roleChange(select),dialog.value = false"
                                        >Confirmer</v-btn>
                                        <v-btn
                                            text
                                            color="error"
                                            @click="dialog.value = false"
                                        >Fermer</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </template>
                            </v-dialog>
                        </v-col>
                        <v-col cols=5 sm=3 md=2 xl=1>
                            <v-btn :disabled="userExist" @click="connectUser" small class="error ml-4">Connexion</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
export default {
    computed:{
        userExist(){
            if (this.beatmakerRole === undefined) {
                return true
            }
            else return false
        }
    },
    methods:{
        connectUser(){
            console.log(this.beatmakerRole)
            this.$emit('connect')
        },
        roleChange(role){
            this.$emit('role',role)
        }
    },
    props:{
        beatmakerRole:Object,
    },
    data() {
        return {
            role:['Utilisateur','Modérateur','Administrateur'],
            select:"",
        }
    },    
}
</script>
<style>
.userAdminHead{
    font-size: 180%;
}
</style>