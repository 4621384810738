<template lang="">
<v-col cols="12" lg=8 xl=6>
    <p class="mb-5 h1 grey--text text--lighten-2 text-center">
        <span>Beatmaker </span>
        <span>à découvrir</span>
    </p>
    <v-row>
        <v-col cols="12" class='pb-0'>
            <template lang="">
                <div>
                    <v-list class="mt-2" color="transparent" v-for="item in beatmakerCard.slice(0,4)"
                        :key="item.name">
                        <v-list-item class="justify-center">
                            <v-btn target=_blank @click="linkToUser(item.name)" class= x-large fab>
                                <v-avatar :width="imageSize" :height="imageSize">
                                    <v-img lazy :src="item.avatar"></v-img>
                                </v-avatar>
                            </v-btn>
                        </v-list-item>
                        <v-list-item :class="spacing" class="justify-center">
                            <v-btn :width="buttonSize" @click="linkToUser(item.name)" dark x-small>
                                <p :style="nameSize" class="mt-4 font-weight-bold">
                                    {{item.name}}
                                </p>
                            </v-btn>
                        </v-list-item>
                    </v-list>
                </div>
            </template>
        </v-col>
        <v-row>
        <v-col cols="6">
          <v-btn :disabled='prevbtn' @click="prevPage" x-small text dark> Précédent </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn x-small @click="nextPage" text dark> Suivant </v-btn>
        </v-col>
        </v-row>
    </v-row>
</v-col>
</template>
<script>
export default {
  props:{
    beatmaker:Array,
  },
  created() {
        this.beatmakerCard = this.beatmaker.map(({ name, avatar }) => ({ name: name, avatar: avatar}));
    },
  methods: {
    linkToUser(link){
            this.$router.push({ name: 'user', params: { id: link } })
        },
    nextPage(){
			this.page = this.page +1;
		},
		prevPage(){
			this.page = this.page -1;
		},
  },
  computed: {
    prevbtn(){
      switch (this.page) {
        case 0:
          return true
        case 1:
          return false
        default:
          return false

      }
    },
    // Gère l'espace entre le bouton et l'image
    spacing() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "mt-0.5";
        case "sm":
          return "mt-2";
        case "md":
          return "mt-3";
        case "lg":
          return "mt-3";
        case "xl":
          return "mt-4";
        default:
          return "mt-4";
      }
    },
    // Gère la taille de l'image
    imageSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "5em";
        case "sm":
          return "6em";
        case "md":
          return "7em";
        case "lg":
          return "7em";
        case "xl":
          return "8em";
        default:
          return "6em";
      }
    },
    // Gère la taille du bouton
    buttonSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "6em";
        case "sm":
          return "8em";
        case "md":
          return "9em";
        case "lg":
          return "10em";
        case "xl":
          return "12em";
        default:
          return "10em";
      }
    },
    // Gère la taille du texte
    nameSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size : 55%";
        case "sm":
          return "font-size : 70%";
        case "md":
          return "font-size : 80%";
        case "lg":
          return "font-size : 85%";
        case "xl":
          return "font-size : 100%";
        default:
          return "font-size : 100%";
      }
    },
  },

  data() {
    return {
      page:0,
      beatmakerCard: [
        {
          name:String,
          avatar:String,
        },
      ],
    };
  },
};
</script>
<style >
.h1{
    font-size: 120%;
}
</style>