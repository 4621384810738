<template>
  <v-dialog persistent v-model="active" :max-width="popupWidth">
    <template v-slot:activator="{ on , attrs }">
      <slot name="button" v-bind="{ on, attrs }"></slot>
    </template>
    <v-card dark class="details-card">
      <v-icon fixed right class="ma-1 close-dialog" x-large dark @click="onClickOutside()">mdi-close</v-icon>
      <v-row :style="headerStyle" class="ma-0">
        <v-col cols="3" class="my-auto" v-if="$vuetify.breakpoint.smAndUp">
          <v-img class="rounded-circle" :src="song.imgPath"></v-img>
        </v-col>
        <v-col>
          <v-card-title>
            <h2>{{ song.title }}</h2>
          </v-card-title>
          <v-card-subtitle class="beatmaker">
            <!-- //TODO: Provide link to beatmaker profile page -->
            <router-link to="beatmaker">
              <v-avatar size="36px">
                <v-img :src="song.beatmakerAvatar" alt="avatar"></v-img>
              </v-avatar>
              {{ song.beatmaker }}
            </router-link>
          </v-card-subtitle>
          <v-chip outlined class="ma-1">{{ song.genre }}</v-chip>
          <v-chip outlined class="ma-1">
            <v-img contain width="20" class="mr-1 mb-1" src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficons8-drums-64.png?alt=media&token=ea993af6-d831-48fd-aa0a-62d8daad6f56"></v-img>
            {{song.gamme}}
          </v-chip>
          <br />
          <v-chip outlined class="ma-1">{{ song.licence }}</v-chip>
          <v-chip outlined class="ma-1">{{ `${song.BPM} BPM` }}</v-chip>
          <br />
          <v-chip outlined class="ma-1">{{ `${song.nbListens} écoutes` }}</v-chip>
          <v-chip outlined class="ma-1">{{ `${song.nbDownload} téléchargements` }}</v-chip>
        </v-col>
      </v-row>
      <v-tabs v-model="selectedTab">
        <v-tab v-for="tab in tabs" :key="tab">{{ tab }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedTab">
        <v-card dark>
          <v-container>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field label="Identité musicale" :value="song.beatmaker" outlined readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field label="Featuring" :value="song.beatmaker" outlined readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field label="Social URL" :value="song.beatmakerPath" outlined readonly></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field label="Adresse email" :value="song.beatmakerMail" outlined readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field label="Utilisation de samples" :value="song.samples ? 'Oui' : 'Non'" outlined readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field label="Chaîne de publication souhaitée" :value="song.submission.channel" outlined readonly></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <p class="mx-3">
            Cliquez pour
            <a :href="song.downloadLinks.wav">télécharger {{song.title}}</a> (WAV)
            <br />Cliquez pour
            <a :href="song.downloadLinks.mp3">télécharger {{song.title}}</a> (MP3)
            <br />
          </p>
          <v-divider />
          <v-container v-if="song.submission.status === 'pending'">
            <v-row>
              <v-col cols="12" sm="6">
                <v-select v-model="selectedChannel" :items="channels" outlined></v-select>
                <v-btn @click="acceptSubmission" width="100%" color="success">Valider</v-btn>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="submissionComment" label="Raison du refus" outlined></v-text-field>
                <v-btn @click="rejectSubmission" width="100%" color="error">Refuser</v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-else>
            <v-chip v-if="song.submission.status === 'approved'" outlined color="success" class="ma-1">Demande validée</v-chip>
            <v-chip v-else outlined color="error" class="ma-1">Demande rejetée</v-chip>
            <v-chip outlined class="ma-1">{{ song.submission.channel }}</v-chip>
          </v-container>
        </v-card>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    song: {
      type: Object,
      required: true,
    },
    isActive: Boolean,
  },
  mounted() {
    if (this.isActive === true) {
      this.active = true;
    }
  },
  data() {
    return {
      active: false,
      tabs: ['Gestion demande'],
      selectedTab: 0,
      newComment: '',
      submissionComment: '',

      channels: [
        {
          text: 'InstruRap',
          value: 'InstruRap',
        },
        {
          text: 'InstruLab',
          value: 'InstruLab',
        },
      ],
      selectedChannel: 'InstruRap',
      //TODO: Sync data with back
      userIsAdmin: true,
    };
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url("${this.song.imgPath}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      };
    },
    popupWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '90%';
        case 'sm':
          return '85%';
        case 'md':
          return '65%';
        case 'lg':
          return '55%';
        case 'xl':
          return '55%';
        default:
          return '55%';
      }
    },
  },
  methods: {
    onClickOutside() {
      this.$router.push('/Administrateur');
    },
    //TODO: Sync actions with back
    submitComment() {
      this.newComment = '';
    },
    acceptSubmission() {
      console.log(this.selectedChannel);
    },
    rejectSubmission() {
      console.log(this.submissionComment);
      this.submissionComment = '';
    },
  },
  //Adds appropriate tabs and preselects appropriate channel
  created() {
    if (this.song.submission.status === 'pending') {
      var index = this.channels.findIndex(
        (channel) => channel.value === this.song.submission.channel
      );
      this.channels[index].text += ' (souhaité)';
      this.selectedChannel = this.channels[index].value;
    }
  },
};
</script>

<style scoped>
.v-dialog__content {
  align-items: flex-start;
}

.close-dialog {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.beatmaker a {
  font-size: 150%;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

.commenterLink {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 600px) {
  .close-dialog {
    position: fixed;
    top: 30px;
    right: 30px;
  }
}
</style>
