<template lang="">
<v-container fluid>
  <v-row class="footerRow">
      <v-col cols=12>
          <v-row dense class="pt-2 pb-1 px-1">
              <v-col offset-sm='1' offset-lg="1" offset-xl="2" class="footer grey--text" cols="12" sm=5 lg=4 xl=3>
                  <span class="footer grey--text" >Copyright © InstruRap 2021</span>
                  <a class="footer virtual links grey--text mx-2" target="_blank" href="/VirtualBeats">Archive VirtualBeats</a>
              </v-col>
              <v-col offset-lg="3" offset-xl="3" class="footer" cols="12" sm=6 lg=4 xl=3>
                  <a class="footer links grey--text mx-2" target="_blank" href="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Administratif%2Fprivacy.pdf?alt=media&token=73a32578-dfd1-4379-a317-25fc4775d4ad">Politique de confidentialité</a>
                  <a class="footer links grey--text mx-2" target="_blank" href="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Administratif%2FCONDITIONS_GENERALES_DUTILISATION_VF_10_12_2020.pdf?alt=media&token=7af8396e-7e91-43c3-a89a-d544e8508c95">CGU</a>
                  <a class="footer links grey--text mx-2" target="_blank" href="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Administratif%2Fml.pdf?alt=media&token=8e69c994-46db-44eb-8223-312d200d4524">Mentions légales</a>
                  <a class="footer links grey--text mx-2" target="_blank" href="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Administratif%2Fcgs.pdf?alt=media&token=0954ab71-1570-484e-86c5-5f01e825452e">CGS</a>
              </v-col>
          </v-row>
      </v-col>
  </v-row>
</v-container>
</template>
<script>
export default {
    
}
</script>
<style>
@media (max-width: 600px) {
  .footer {
    font-size: 80%;
  }
}
@media (max-width: 960px) {
  .footer {
    font-size: 70%;
  }
}
@media (max-width: 1264px) {
  .footer {
    font-size: 80%;
  }
}
@media (max-width: 1904px) {
  .footer {
    font-size: 80%;
  }
}
@media (min-width: 1904px) {
  .footer {
    font-size: 80%;
  }
}
.footerRow{
    background-color: #212832;
}
.links{
text-decoration: none;
}
.links:hover{
    text-decoration: underline;
}
</style>