<template>
  <v-container style="min-height:85vh" fluid>
    <navbar />
    <router-view />
    <v-row class="justify-center">
      <v-col cols="9" sm="11" class="px-0">
        <h1 class="mt-4 h1">Tableau de bord</h1>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="11">
        <v-container>
          <v-row>
            <v-col cols="12" sm="4" class="pa-4">
              <v-card height="100%" width="98%" class="counter-card">
                <v-card height="101%" width="1%" class="info"></v-card>
                <v-row class="justify-space-between">
                  <v-card-title>
                    <h2 class="ml-2">
                      <span class="info--text cardHeader">Projets envoyés</span>
                      <br />
                      <span>{{nbSubmissions}}</span>
                    </h2>
                  </v-card-title>
                </v-row>
                <v-icon color="grey lighten-1" size="72px" class="mb-2 mr-2">mdi-export-variant</v-icon>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4" class="pa-4">
              <v-card height="100%" width="98%" class="counter-card">
                <v-card height="101%" width="1%" class="error"></v-card>
                <v-row class="justify-space-between">
                  <v-card-title>
                    <h2 class="ml-2">
                      <span class="error--text cardHeader">Projets en attente</span>
                      <br />
                      <span>{{nbPending}}</span>
                    </h2>
                  </v-card-title>
                </v-row>
                <v-icon size="72px" color="grey lighten-1" class="mb-2 mr-2">mdi-pause</v-icon>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4" class="pa-4">
              <v-card height="100%" width="98%" class="counter-card">
                <v-card height="101%" width="1%" class="success"></v-card>
                <v-row class="justify-space-between">
                  <v-card-title>
                    <h2 class="ml-2">
                      <span class="success--text cardHeader">Projets validés</span>
                      <br />
                      <span>{{nbApproved}}</span>
                    </h2>
                  </v-card-title>
                </v-row>
                <v-icon color="grey lighten-1" size="72px" class="mb-2 mr-2">mdi-youtube</v-icon>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-row class="justify-center mt-1" :class="inspirePublish">
          <v-col cols="12" sm="12" md="12" lg="3" :class="inspiredBox">
            <v-card width="100%" class="px-8 pb-10">
              <p class="text-center mt-4 py-5 px-5">
                <span class="publishHeader">Inspiré(e) ?</span>
                <br />
                <span>Vous êtes inspiré(e) et prêt(e) à nous envoyer un</span>
                <br />
                <span>projet musical, un bon Beat ? Oui ?</span>
                <br />
                <span>Dans ce cas, cliquez sur le bouton ci-dessous pour accéder au formulaire d'envoi.</span>
              </p>

              <router-link active-class="is-active" class="link" :to="{ name: 'Publier' }">
                <v-row class="justify-center">
                  <v-btn color="info">Envoyer un beat</v-btn>
                </v-row>
              </router-link>
            </v-card>
          </v-col>
          <v-col cols="12" lg="9" :class="publishBox">
            <v-card width="98%" class="ma-4 mt-16">
              <v-card-title>
                <h2>Mes demandes de publication</h2>
              </v-card-title>
              <v-simple-table>
                <template>
                  <thead>
                    <tr>
                      <th>Nom de l'instrumentale</th>
                      <th>File d'attente</th>
                      <th>État</th>
                      <th>Chaîne</th>
                      <th>Dernière mise à jour</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="song in orderedPendingSubmissions" class="pointer" @click="linkTo(song.id)" :key="song.id" :class="song.submission.status">
                      <td>{{ song.title }}</td>
                      <td>{{ queuePosition(song.submission.submissionNumber) }}</td>
                      <td>{{ statusFR(song.submission.status) }}</td>
                      <td>{{ song.submission.channel }}</td>
                      <td>{{ formatDate(song.submission.lastUpdate.toDate()) }}</td>
                    </tr>
                    <tr v-for="song in managedSubmissions" class="pointer" @click="linkTo(song.id)" :key="song.id" :class="song.submission.status">
                      <td>{{ song.title }}</td>
                      <td>N/A</td>

                      <td>{{ statusFR(song.submission.status) }}</td>
                      <td>{{ song.submission.channel }}</td>
                      <td>{{ formatDate(song.submission.lastUpdate.toDate()) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Navbar from "../components/Global_Components/Navbar.vue";
import { db } from "../firebase";
import { mapGetters } from "vuex";

export default {
  components: {
    Navbar,
  },

  firestore() {
    return {
      //TODO:BACKEND update to right collection
      submissions: db
        .collection("tempSongs")
        .where("userID", "==", this.getUser.authID)
        .where("isSubmission", "==", true),
      // checking purposes
      allSubmissions: db
        .collection("tempSongs")
        .where("userID", "==", this.getUser.authID),
    };
  },
  data() {
    return {
      submissions: [],
      // Checking purposes
      allSubmissions: [],
    };
  },
  computed: {
    ...mapGetters(["getUser", "isUserAuth"]),

    pendingSubmissions() {
      return this.submissions.filter((song) => {
        return song.submission.status == "pending";
      });
    },
    orderedPendingSubmissions() {
      return this.pendingSubmissions.sort((a, b) => {
        return a.submission.submissionNumber - b.submission.submissionNumber;
      });
    },
    managedSubmissions() {
      return this.submissions.filter((song) => {
        return song.submission.status != "pending";
      });
    },
    inspiredBox() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return " px-8";
        case "sm":
          return "px-7";
        case "md":
          return "px-12";
        case "lg":
          return "mt-12";
        case "xl":
          return "px-7";
        default:
          return "px-8";
      }
    },
    inspirePublish() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
          return "px-16 ml-5";
        case "xl":
          return "";
        default:
          return "";
      }
    },
    publishBox() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return " px-7 mr-6";
        case "sm":
          return "px-5 mr-2";
        case "md":
          return "px-10 mr-2";
        case "lg":
          return "px-16";
        case "xl":
          return "px-5";
        default:
          return "px-7 mr-6";
      }
    },
    nbSubmissions() {
      return this.submissions.length;
    },
    nbPending() {
      return this.submissions.reduce((accumulator, song) => {
        if (song.submission.status === "pending") {
          accumulator++;
        }
        return accumulator;
      }, 0);
    },
    nbApproved() {
      return this.submissions.reduce((accumulator, song) => {
        if (song.submission.status === "approved") {
          accumulator++;
        }
        return accumulator;
      }, 0);
    },
  },
  methods: {
    linkTo(link) {
      this.$router.push({ name: "DashboardDetails", params: { id: link } });
    },
    //TODO: Calculate queue position from submission number
    queuePosition(submissionNumber) {
      return submissionNumber;
    },
    formatDate(timestamp) {
      const date = new Date(timestamp).toLocaleDateString();
      return date;
    },
    statusFR(status) {
      switch (status) {
        case "pending":
          return "En attente";
        case "rejected":
          return "Rejeté";
        case "approved":
          return "Accepté";
        default:
          return "";
      }
    },
  },

};
</script>

<style scoped>
h1 {
	font-size: 2rem;
}
.publishHeader {
	font-size: 140%;
}
.publishText {
	font-size: 110%;
}
.counter-card {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	min-height: 7em;
}
.v-card__title h2 {
	word-break: normal;
	font-size: 90%;
}

.pending td {
	color: orange;
}

.rejected td {
	color: #ff5252;
}

.approved td {
	color: #4caf50;
}

.popup-activator {
	width: 100%;
	height: 48px;
	position: absolute;
	left: 0px;
	z-index: 100;
}

.popup-activator:hover {
	cursor: pointer;
}
</style>
