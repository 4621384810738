<template lang="">
<div class='grey darken-4'>
    <v-row class="justify-center">
        <v-col>
            <v-row class='justify-end'>
                <v-btn dark class="mt-4 mr-4" @click="abort()">
                    <v-icon>mdi-exit-to-app</v-icon>
                </v-btn>
            </v-row>
            <v-row class="justify-center">
                <v-col cols='8'>
                    <v-row class="justify-start">
                        <p>
                            <v-icon color="grey" large class="mb-4">
                                mdi-arrow-right-thin-circle-outline
                            </v-icon>
                            <span class="white--text titre">
                                Publier mon Instrumental
                            </span> <br>
                            <span class="grey--text">
                                Cet assistant de publication vous aidera à publier votre projet musical sur le site et/ou notre chaîne YouTube.
                            </span>
                        </p>
                    </v-row>
                </v-col>   
            </v-row>
        </v-col>
    </v-row>
    <v-list class="list justify-center grey lighten-1">
        <v-row class='justify-center'>
            <v-col class="form" cols="10" md='8'>
                <v-list rounded class="grey lighten-2">
                    <v-row class="justify-center">
                        <v-col cols="12">
                            <v-col>
                                <v-row class="grey lighten-2">
                                    <v-col cols="12" md='3' class="py-6" v-for="item in steps" :key="item.number">
                                        <v-row class="justify-center" v-if="page===item.number">
                                            <v-list rounded width="90%"
                                             class="grey darken-4 d-flex flex-row py-6 justify-center">
                                                <v-row class="mt-1 ml-5">
                                                    <v-sheet rounded height="45" width="45" class='text-center'>
                                                        <div class="mt-2">
                                                            <span color='black'>{{item.number}}</span>
                                                        </div>
                                                    </v-sheet>
                                                    <div class="mx-2">
                                                        <p>
                                                            <span class="font-weight-black white--text">{{item.title}}</span> <br>
                                                            <span class="caption font-weight-bold grey--text">{{item.subtitle}}</span>
                                                        </p>
                                                    </div>
                                                </v-row>
                                            </v-list>
                                        </v-row>
                                        <v-row class="justify-center" v-else>
                                            <v-list rounded width="90%"
                                             class="grey lighten-2 d-flex flex-row py-6 justify-center">
                                                <v-row class="mt-1 ml-5">
                                                    <v-sheet dark rounded height="45" width="45" class='text-center'>
                                                        <div class="mt-2">
                                                            <span>{{item.number}}</span>
                                                        </div>
                                                    </v-sheet>
                                                    <div class="mx-2">
                                                        <p>
                                                            <span class="font-weight-black">{{item.title}}</span> <br>
                                                            <span class="caption font-weight-bold grey--text">{{item.subtitle}}</span>
                                                        </p>
                                                    </div>
                                                </v-row>
                                            </v-list>
                                        </v-row>                                                
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-divider></v-divider>
                            <validation-observer ref="observer" v-slot="{ invalid }">
                                <v-col>
                                    <v-col v-if="page===1">
                                        <v-row class="justify-center">
                                            <v-col cols='12' md='6'>
                                                <p class="mt-6">
                                                    <span class="stepSize font-weight-black">Etape 1</span> <br>
                                                    <span>Informations relatives aux auteurs</span>
                                                </p>
                                                <v-row>
                                                    <v-col cols="12">
                                                    <v-form @submit.prevent="">
                                                    <v-col cols="12" class="spacing">
                                                        <validation-provider rules="required|max:16" v-slot="{errors}">                                                            
                                                                <span class="caption">Votre nom d'artiste</span>
                                                                <v-text-field
                                                                :error-messages="errors" solo dense outlined label="Entrez votre nom d'artiste" v-model="instruForm.name"></v-text-field>                                                            
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12" class="spacing" >
                                                        <span class="caption">Collaborateur(s)</span>
                                                        <v-combobox
                                                        v-model="instruForm.feat"
                                                        :items="accounts"
                                                        :search-input.sync="search"
                                                        hide-selected
                                                        label="Ajouter des collaborateurs"
                                                        background-color="white"
                                                        outlined
                                                        solo
                                                        dense
                                                        clearable
                                                        multiple
                                                        chips
                                                        >
                                                            <template v-slot:no-data>
                                                                <v-list-item>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>
                                                                        Aucun beatmaker trouvé pour "<strong>{{ search }}</strong>". Appuyer sur <kbd>entrée</kbd> pour le créer.
                                                                        </v-list-item-title>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </template>
                                                        </v-combobox>
                                                    </v-col>
                                                    <v-col cols="12" class="spacing2">                                                       
                                                            <span class="caption">URL de votre chaîne YouTube, Soundcloud, autre... (facultatif)</span>
                                                            <v-text-field  solo dense outlined label="Lien vers votre média" v-model="instruForm.path"></v-text-field>                                                        
                                                    </v-col>
                                                    <v-col cols="12" class="spacing">
                                                        <validation-provider rules="required|email" v-slot="{errors}">                                                            
                                                                <span class="caption">Adresse email de contact</span>
                                                                <v-text-field :error-messages="errors"  solo dense outlined label="Adresse email" v-model="instruForm.email"></v-text-field>                                                            
                                                        </validation-provider>
                                                    </v-col>
                                                    </v-form>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col v-if="page===2">
                                        <v-row class="justify-center">
                                            <v-col cols="12" md=6>
                                                <p class="mt-6">
                                                    <span class="stepSize font-weight-black">Etape 2</span> <br>
                                                    <span>Informations relatives au Beat</span>
                                                </p>                                            
                                                <v-row>
                                                    <v-col cols="12" class='spacing'>
                                                        <validation-provider rules="required" v-slot="{errors}">
                                                            <span class="caption">Genre musical</span>
                                                            <v-select :error-messages="errors" solo label="Sélectionnez un genre" :items="genre" v-model="instruForm.genre" outlined dense></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-row dense>
                                                            <v-col cols="6" class='pb-0'>
                                                                <validation-provider rules="titleMin:3|titleMax:25|required" v-slot="{errors}">
                                                                    <span class="caption">Titre de l'instrumentale</span>
                                                                    <v-text-field :error-messages="errors" solo label="Titre" v-model="instruForm.titre" outlined dense></v-text-field>
                                                                </validation-provider>
                                                            </v-col>
                                                            <v-col cols="6" class='pb-0'>
                                                                <validation-provider rules="required" v-slot="{errors}">
                                                                    <span class="caption">Utilisation de samples</span>
                                                                    <v-select :error-messages="errors" label="Samples" solo v-model="instruForm.samples" :items="samples" outlined dense></v-select>
                                                                </validation-provider>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <span class="caption">Inspiration (Optionnel)</span>
                                                                <v-select multiple solo v-model="instruForm.mood" label="Sélectionnez une inspiration" :items="mood" outlined dense></v-select>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <span class="caption">Instrument (Optionnel)</span>
                                                                <v-select solo v-model="instruForm.instrument" label="Sélectionnez un instrument" :items="instrument" outlined dense></v-select>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <validation-provider rules="required|min_value:60" v-slot="{errors}">
                                                                    <span class="caption">BPM</span>
                                                                    <v-text-field :error-messages="errors" solo v-model="instruForm.bpm" type="number" label="BPM" outlined dense></v-text-field>
                                                                </validation-provider>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <span class="caption">Gamme (Optionnel)</span>
                                                                <v-select solo v-model="instruForm.key" label="Sélectionnez une gamme" :items="gamme" outlined dense></v-select>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col v-if="page===3">
                                        <v-row class="justify-center">
                                            <v-col cols="12" md=6>
                                                <p class="mt-6">
                                                    <span class="stepSize font-weight-black">Etape 3</span> <br>
                                                    <span>Licences</span>
                                                </p>
                                                <v-row>
                                                    <v-col cols="12" class='spacing'>
                                                        <validation-provider rules="required" v-slot="{errors}">
                                                            <span class="caption">Licence à définir (Gratuite, Écoute, Vente)</span>
                                                            <v-select :error-messages="errors" solo v-model="instruForm.licence" label="Sélectionnez une licence" :items="licences" outlined dense></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col v-if="page===4">
                                        <v-row class="justify-center">
                                            <v-col cols='10' md=8>
                                                <v-row>
                                                    <p class="mt-6">
                                                        <span class="stepSize font-weight-black">Etape 4</span> <br>
                                                        <span>Téléchargement des fichiers</span>
                                                    </p>
                                                </v-row>
                                            </v-col>
                                            <v-list rounded width="44em">
                                                <v-col cols="12" class="white">
                                                    <v-col class="spacingFiles">
                                                        <validation-provider rules="requiredFile|mp3Size:20000|ext:mp3" v-slot="{errors}">
                                                            <span class="font-weight-bold">Fichier audio (MP3)</span> <br>
                                                            <span class="caption">Avec ou sans tags</span>
                                                            <v-file-input :error-messages="errors" accept="audio/*" v-model="mp3Data" outlined dense></v-file-input>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col class="spacingFiles">
                                                        <validation-provider rules="requiredFile|wavSize:100000|ext:wav" v-slot="{errors}">
                                                            <span class="font-weight-bold">Fichier audio (WAV)</span> <br>
                                                            <span class="caption">Sans tags</span>
                                                            <v-file-input :error-messages="errors" accept="audio/*" v-model="wavData" outlined dense></v-file-input>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col>
                                                        <validation-provider rules="requiredFile|size:2000|ext:png,jpg" v-slot="{errors}">
                                                            <span class="font-weight-bold">Pochette</span> <br>
                                                            <span class="caption">Sélectionnez le fichier image à associer à votre instrumentale (PNG, JPG, etc.)</span>
                                                            <v-file-input :error-messages="errors" required accept="image/*" v-model="imageData" outlined dense></v-file-input>
                                                        </validation-provider>                                                
                                                    </v-col>
                                                </v-col>
                                            </v-list>
                                            <v-col cols="12">
                                                <v-row class="justify-center">
                                                    <v-col cols="12" md="7">
                                                        <v-divider></v-divider>
                                                        <p class="my-10">
                                                            <span class="conditionsText">Conditions spécifiques d'envoi</span>
                                                        </p>
                                                    </v-col>
                                                </v-row>
                                                <v-col cols="12">
                                                    <v-row class="justify-center">
                                                        <v-sheet rounded class="d-flex" width="44em">
                                                            <v-sheet rounded class="orange conditionsSheet text-center">
                                                                <v-icon class="infosIcon" x-large color='white'>mdi-information-outline</v-icon>
                                                            </v-sheet>
                                                            <v-sheet class="infosConditions">
                                                                <v-col cols="12">
                                                                    <v-row class="mt-6 ml-4 mr-1">
                                                                        <span class="conditionsText mb-5">Conditions d'envoi</span>
                                                                        <p>
                                                                            <span>
                                                                                En échange de promouvoir votre contenu, 
                                                                            </span>
                                                                            <span class="font-weight-black brandSize">
                                                                                InstruRap
                                                                            </span>
                                                                            <span>                                                            
                                                                                se réserve le droit de monétiser votre instrumentale 
                                                                                (ou projet audio) sur les chaînes Instrurap et Instrulab via des outils tels qu'AdSence, 
                                                                                YouTube et tout autres services de publicité quels qu'ils soient. Par ailleurs, 
                                                                                si une fois publié, vous souhaitez faire supprimer votre contenu de l'une des chaînes Youtube, 
                                                                                la demande de suppression devra être motivée et une compensation financière pourra alors 
                                                                                vous être demandée.
                                                                            </span>
                                                                        </p>
                                                                        <p>
                                                                            <span>
                                                                                Suite à la mise à jour de Youtube concernant les droits d'auteurs,                                                             
                                                                            </span>
                                                                            <span class="font-weight-black brandSize">
                                                                                InstruRap
                                                                            </span>
                                                                            <span>
                                                                                se réservera désormais l'exclusivité de diffusion. La diffusion de votre projet ne pourra donc se faire uniquement sur les chaînes du groupe InstruRap.
                                                                                En cas de non-respect de cette clause, InstruRap se reserve le droit de supprimer l'oeuvre ou d'en réclammer 
                                                                                un droit d'exploitation auprès de Youtube.
                                                                            </span>
                                                                        </p>
                                                                    </v-row>
                                                                </v-col>
                                                            </v-sheet>
                                                        </v-sheet>
                                                    </v-row>
                                                </v-col>
                                            </v-col>
                                        </v-row>
                                        <v-col cols="12">
                                            <v-row class='justify-center'>
                                                <v-col cols="12" md=7>
                                                    <v-col cols="12" class="spacingSignature">
                                                        <v-divider class="my-4"></v-divider>
                                                        <validation-provider rules="required" v-slot="{errors}">
                                                            <v-form @submit.prevent="submit">
                                                            <p>
                                                                <span class="bold">Signature électronique</span>
                                                            </p>
                                                            <v-text-field :error-messages="errors" background-color="white" label="Signature électronique" v-model="instruForm.signature" outlined dense>                                                    
                                                            </v-text-field>
                                                            </v-form>
                                                        </validation-provider>
                                                    </v-col>
                                                    <span class="caption ml-3">En signant ce formulaire vous acceptez les : </span>
                                                    <ul>
                                                        <li class="caption">conditions spécifiques d'envoi ci-dessus</li>
                                                        <li class="caption"><a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Administratif%2FCONDITIONS_GENERALES_DUTILISATION_VF_10_12_2020.pdf?alt=media&token=7af8396e-7e91-43c3-a89a-d544e8508c95">conditions d'utilisations du service</a></li>
                                                        <li class="caption"><a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Administratif%2Fcgs.pdf?alt=media&token=0954ab71-1570-484e-86c5-5f01e825452e">conditions de service</a></li>
                                                    </ul> 
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-col>
                                    <v-col>
                                        <v-row class="justify-center">
                                            <v-col cols="8">
                                                <v-divider inset class="mr-16 mt-5"></v-divider>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col>
                                        <v-row class="justify-center">
                                            <v-col class="my-5" cols="12">
                                                <v-row class="justify-center">
                                                    <v-col cols=12 md=6>
                                                        <v-row>                                                        
                                                            <v-btn v-if="page===1" disabled>Précédent</v-btn>
                                                            <v-btn v-else @click="page=page-1">Précédent</v-btn>
                                                            <v-spacer></v-spacer>
                                                            <v-btn v-if="page===4 && invalid===false && loading===false" @click="finish()" dark>Soumettre</v-btn>
                                                            <v-btn v-if="page===4 && invalid===false && loading===true" :loading='loading' :disabled='loading' @click="finish()" >Soumettre</v-btn>
                                                            <v-btn v-if="page===4 && invalid===true" disabled @click="finish()">Soumettre</v-btn>
                                                            <v-btn v-if="page!=4 && invalid===true" disabled  @click="next()">Suivant</v-btn>
                                                            <v-btn v-if="page!=4 && invalid===false" dark  @click="next()">Suivant</v-btn>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-col>
                            </validation-observer>
                        </v-col>
                    </v-row>
                </v-list>
            </v-col>
        </v-row>
    </v-list>
</div>
</template>
<script>
import VueSimpleAlert from "vue-simple-alert";
import {
	required,
	email,
	max,
    min,
	min_value,
	size,
	ext,
} from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { db } from "../../firebase";
import { storageRef } from "../../firebase";

// TODO : Link avec firebase pour avoir le nom du beatmaker et l'email inscrits par défault

// Liste des différentes méthodes de validation des cases obligatoires.
extend("required", {
	...required,
	message: "Cette case ne doit pas rester vide",
});
extend("ext", {
	...ext,
	message: "Ce format de fichier n'est pas accepté",
});
extend("requiredFile", {
	...required,
	message: "Fichier manquant",
});
extend("size", {
	...size,
	message: "L'image ne doit pas dépasser 2 MB",
});
extend("mp3Size", {
	...size,
	message: "Le fichier ne doit pas dépasser 20 MB",
});
extend("wavSize", {
	...size,
	message: "Le fichier ne doit pas dépasser 100 MB",
});
extend("max", {
	...max,
	message: "Le nom ne doit pas dépasser 16 caractères",
});
extend("titleMin", {
	...min,
	message: "Le titre doit dépasser 3 caractères",
});
extend("titleMax", {
	...max,
	message: "Le titre ne doit pas dépasser 25 caractères",
});
extend("min_value", {
	...min_value,
	message: "Le BPM doit être supérieur à 60",
});
extend("email", {
	...email,
	message: "L'adresse email doit être valide",
});

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
	},
	created() {
		(this.instruForm.name = this.artistName),
			(this.instruForm.email = this.artistMail);
	},
	methods: {
		// Permet d'upload le WAV dans un dossier du cloud, puis d'ajouter le lien au cloud dans la base de données
		async wavUpload() {
			const wavFileRef = storageRef.child(
				"songs/" +
					this.instruForm.name +
					"/" +
					this.instruForm.titre +
					"/" +
					this.instruForm.titre +
					" " +
					"wav"
			);
			await wavFileRef.put(this.wavData);
			this.instruForm.beatWAV = await wavFileRef.getDownloadURL();
			console.log(this.instruForm.beatWAV);
		},
		// Permet d'upload le MP3 dans un dossier du cloud, puis d'ajouter le lien au cloud dans la base de données
		async mp3Upload() {
			const mp3FileRef = storageRef.child(
				"songs/" +
					this.instruForm.name +
					"/" +
					this.instruForm.titre +
					"/" +
					this.instruForm.titre +
					" " +
					"mp3"
			);
			await mp3FileRef.put(this.mp3Data);
			this.instruForm.beatMP3 = await mp3FileRef.getDownloadURL();
			console.log(this.instruForm.beatMP3);
		},
		// Permet d'upload l'image dans un dossier du cloud, puis d'ajouter le lien au cloud dans la base de données
		async imageUpload() {
			const imageFileRef = storageRef.child(
				"songs/" +
					this.instruForm.name +
					"/" +
					this.instruForm.titre +
					"/" +
					this.instruForm.titre +
					" " +
					"image"
			);
			await imageFileRef.put(this.imageData);
			this.instruForm.imgPath = await imageFileRef.getDownloadURL();
			console.log(this.instruForm.imgPath);
		},
		// Ferme le formulaire (sans envoyer les inputs)
		abort() {
			setTimeout(() => this.$emit("done"), 200);
		},

		finish() {
			if (this.checked === true) {
				(this.instruForm.isSubmission = true),
					console.log(this.instruForm.isSubmission);
			}
			if (this.checked === false) {
				(this.instruForm.isSubmission = false),
					console.log(this.instruForm.isSubmission);
			}
			this.instruForm.name = this.artistName;
			this.instruForm.mail = this.artistMail;
			// Transition du bouton
			this.loading = true;
			// Upload des fichiers et création dans la base de données
			this.imageUpload();
			this.mp3Upload();
			this.wavUpload().then(() => {
				const project = {
					artist: this.instruForm.name,
					title: this.instruForm.titre,
					collaborators: this.instruForm.feat,
                    key:this.instruForm.key,
					type: this.instruForm.type,
					genre: this.instruForm.genre,
					mood: this.instruForm.mood,
					instrument: this.instruForm.instrument,
					bpm: Number(this.instruForm.bpm),
					licence: this.instruForm.licence,
					sample: (this.instruForm.sample = "Oui") ? true : false,
					beatmakerMail: this.instruForm.email,
					beatmakerLinks: this.instruForm.path,
					pochette: this.instruForm.imgPath,
					mp3: this.instruForm.beatMP3,
					wav: this.instruForm.beatWAV,
					isSubmission: this.instruForm.isSubmission,
				};
				db.collection("songs")
					.add(project)
					.then(() => {
						this.$emit("done");
					});
				// 	// Arrête la transition du bouton et lance le dialog
				this.loading = false;
				VueSimpleAlert.alert(
					"Ton instru a bien été mise en ligne !",
					"Félicitations",
					"success"
				);
			});
		},
		// Change de page
		next() {
			this.page = this.page + 1;
		},
	},
	props: {
		checked: Boolean,
		artistName: String,
		artistMail: String,
	},
	data() {
		return {
			loading: false,
			wavData: null,
			mp3Data: null,
			imageData: null,
			page: 1,
			sample: "Non",
			accounts: [
				"Kaowbeat",
				"GreenWojack",
				"Pylot",
				"Machin",
				"Stuff",
				"Neobeats",
				"RedWojack",
				"Pilote",
				"Truc",
			],
			search: null,
            gamme: [
				"Am",
				"A#m",
				"A Maj",
				"A# Maj",
				"Bm",
				"B#m",
				"B Maj",
				"B# Maj",
				"Cm",
				"C#m",
				"C Maj",
				"C# Maj",
				"Dm",
				"D#m",
				"D Maj",
				"D# Maj",
				"Em",
				"E#m",
				"E Maj",
				"E# Maj",
				"Fm",
				"F#m",
				"F Maj",
				"F# Maj",
				"Gm",
				"G#m",
				"G Maj",
				"G# Maj",
			],
			genre: [
				"Tous les genres",
				"Rap",
				"Trap",
				"Drill",
				"Club",
				"Cloud",
				"Lofi",
				"Reggaeton",
				"R&B/Soul",
				"Dancehall",
				"Grime",
				"Funk",
				"2Step",
				"Pop",
				"Reggae",
				"Rai",
				"Electro",
				"Rock",
				"Autre",
			],
			mood: [
				"Mélancolique",
				"Agressif",
				"Dansant",
				"Puissant",
				"Calme",
				"Amour",
				"Conscient",
				"Sombre",
				"Digital",
				"Old School",
				"Street",
				"Joyeux",
				"Planant",
				"Latin",
				"Afro",
				"Asiatique",
				"Oriental",
				"French Type Beat",
				"US Type Beat",
				"UK Type Beat",
			],
			licences: [
				"Téléchargeable gratuitement et libre de droit",
				"Téléchargeable gratuitement pour utilisation non commerciale",
				"Non téléchargeable (Uniquement écoute)",
			],
			instrument: [
				"Piano",
				"Guitare",
				"Violon",
				"Flute",
				"Synthé",
				"Voix",
				"Saxo",
				"Tambour",
				"Trompette",
			],
			samples: ["Oui", "Non"],
			instruForm: {
				type: "Instru",
				name: "",
				feat: "",
				email: "",
                key:"",
				path: "",
				genre: "",
				mood: "",
				samples: "Non",
				titre: "",
				instrument: "",
				bpm: 0,
				licence: "",
				signature: "",
				collab: "",
				imgPath: [],
				beatMP3: [],
				beatWAV: [],
				isSubmission: false,
			},
			user: {
				username: "WojackHorseman",
				mail: "WojackHorseman@mail.com",
			},
			steps: [
				{
					number: 1,
					title: "Auteur(s)",
					subtitle: "Parlons de vous",
				},
				{
					number: 2,
					title: "Beat",
					subtitle: "Informations projet",
				},
				{
					number: 3,
					title: "Licence",
					subtitle: "Droits & ventes",
				},
				{
					number: 4,
					title: "Fichiers et contrats",
					subtitle: "Dernière étape",
				},
			],
		};
	},
};
</script>
<style >
.titre {
	font-size: 230%;
}

/* Positionnement des différentes fenetres d'input page 1 et 2 */
.spacing {
	height: 5em;
}
.spacing2 {
	height: 6em;
}
.spacingTitre {
	height: 5em;
}
@media (max-width: 430px) {
	.spacingSm {
		margin-top: 1.5em;
	}
}

/* Styling des cases étapes du formulaire */
.stepSize {
	font-size: 135%;
}
.list {
	margin-top: 9em;
}
.form {
	transform: translateY(-6.5em);
}

/* Styling de la page 4 */
.spacingFiles {
	height: 6.5em;
}
.spacingSignature {
	height: 9em;
}

/* Styling du paragraphe conditions d'envoi page 4 */
.conditionsSheet {
	height: 100%;
	width: 20%;
}
.conditionsText {
	font-size: 120%;
}
.infosConditions {
	width: 80%;
}
.infosIcon {
	transform: scale(150%) translateY(300%);
}
.brandSize {
	font-size: 110%;
}
@media (max-width: 640px) {
	.conditionsSheet {
		width: 40%;
	}
	.conditionsText {
		width: 60%;
	}
	.infosIcon {
		transform: scale(130%) translateY(13em);
	}
	.spacingFiles {
		height: 7.5em;
	}
}
</style>