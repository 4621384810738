<template>
  <v-container fluid>
    <v-row class="justify-center transparent">
      <v-col cols="12" class="pt-4 pb-1">
        <v-row dense>
          <v-avatar :size="logoSize" tile v-if="this.beatPlaylist == 'Sélection InstruRap'" class="ml-3 mb-1 rounded">
            <v-img src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Flogo_s%C3%A9lection_instrurap.png?alt=media&token=68ddebbe-83b4-478c-b5a6-e7c3cc8767db"></v-img>
          </v-avatar>
          <span class="h1 headSize grey--text text--lighten-2 ml-2">{{this.beatPlaylist}}</span>
          <v-spacer></v-spacer>
          <v-btn v-if="this.beatPlaylist !== 'Suggestions' " @click="routeTo(beatPlaylist,'playlist')" small dark class="btnSize mr-4">Voir la playlist</v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="songCards===undefined">
      <v-col cols="12">
        <span class="white--text">Aucun titre</span>
      </v-col>
    </v-row>
    <v-row v-else class="mt-1 transparent" justify="center">
      <vue-horizontal class="carousel">
        <template v-slot:btn-prev>
          <v-btn icon large color="white" class="justify-end mb-16 d-none d-md-block" :class="prevBtn">
            <v-icon>mdi-less-than</v-icon>
          </v-btn>
        </template>
        <template v-slot:btn-next>
          <v-btn icon large color="white" class="justify-end mb-16 d-none d-md-block" :class="nextBtn">
            <v-icon>mdi-greater-than</v-icon>
          </v-btn>
        </template>
        <section v-for="song in songCards" :key="song.id">
          <v-col class="px-0">
            <v-list class="card" :width="width" outlined color="transparent">
              <v-list-item>
                <v-hover v-slot="{ hover }">
                  <v-card :elevation="hover ? 24 : 2" :class="{ 'on-hover': hover }">
                    <v-img :src="song.imgPath" class="image align-center" height="100%" lazy @mouseover="song.playButton = true" @dblclick="song.playButton = !song.playButton" @mouseleave="song.playButton = false">
                      <v-row class="justify-center">
                        <v-col cols="5" class="mt-6 d-none d-lg-block" :class="btnLgPosition">
                          <v-btn @click="song.isPlaying=false" x-small v-if="song.isPlaying===true" icon>
                            <div class="icon audio">
                              <i></i>
                              <i></i>
                            </div>
                          </v-btn>
                          <v-btn v-if="song.isPlaying===false && song.playButton === true" @click="song.isPlaying=true" icon>
                            <v-icon x-large fab color="white">mdi-play</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="5" class="d-lg-none align-center">
                          <v-btn v-if="song.isPlaying === false && song.Play === false" x-small icon class="play" color="white" :class="btnXsPosition" @click.stop.prevent="song.isPlaying = !song.isPlaying">
                            <v-icon large>mdi-play</v-icon>
                          </v-btn>
                          <v-btn v-if="song.isPlaying === true" icon x-small color="white" class="play" :class="btnXsPosition" @click.stop.prevent="song.isPlaying = !song.isPlaying">
                            <v-icon large>mdi-pause</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-card-actions>
                        <v-col v-if="user.liked.indexOf(song.id) === -1">
                          <v-btn icon :x-small="likeSize" small @click.stop.prevent="like(song.id), song.nbLikes++" style="position: absolute; bottom: 0; left: 0" class="ml-2">
                            <v-icon class="white--text">mdi-thumb-up-outline</v-icon>
                            <span class="mr-2 white--text">{{ song.nbLikes }}</span>
                          </v-btn>
                        </v-col>
                        <v-col v-else>
                          <v-btn icon :x-small="likeSize" @click.stop.prevent="removeLike(song.id), song.nbLikes--" style="position: absolute; bottom: 0; left: 0" class="ml-2">
                            <v-icon class="white--text" color="green">mdi-thumb-up</v-icon>
                            <span class="mr-2 white--text caption">{{song.nbLikes}}</span>
                          </v-btn>
                        </v-col>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon :x-small="likeSize" dark v-bind="attrs" v-on="on" style="position: absolute; bottom: 0; right: 0">
                              <v-icon class="white--text">mdi-dots-horizontal</v-icon>
                            </v-btn>
                          </template>
                          <v-list dark>
                            <v-list-item class="pointer" @click="routeTo(song.id,'SongPopup')">
                              <v-icon>mdi-music-box</v-icon>Page de l'instru
                            </v-list-item>
                            <!-- TODO : Add beatmaker data in firestore -->
                            <v-list-item @click="routeTo(song.beatmaker,'user')">
                              <span>
                                <v-icon>mdi-account</v-icon>Profil du beatmaker
                              </span>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-card-actions>
                    </v-img>
                  </v-card>
                </v-hover>
              </v-list-item>
              <v-list-item-title class="font-weight-black text">
                <span class="text-uppercase grey--text textSize text--lighten-2 ml-5">{{ song.title }}</span>
                <br />
                <span @click="linkToUser(song.beatmaker)" class="ml-5 grey--text textSize text--lighten-2 pointer">{{ song.beatmaker }}</span>
              </v-list-item-title>
            </v-list>
          </v-col>
        </section>
      </vue-horizontal>
    </v-row>
  </v-container>
</template>

<script>
import VueHorizontal from 'vue-horizontal';
import { db } from '../../firebase';
import firebase from 'firebase/app';

export default {
  components: { VueHorizontal },
  props: {
    beatPlaylist: String,
    songCards: Array,
  },
  methods: {
    routeTo(link, type) {
      this.$router.push({ name: type, params: { id: link } });
    },
    linkToUser(link) {
      this.$router.push({ name: 'user', params: { id: link } });
    },
    //TODO : manage like when user data is present
    async downloadSong(uri, name) {
      var link = document.createElement('a');
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    like(id) {
      let ref = db.collection('songs').doc(id.toString());
      ref.update({
        nbLikes: firebase.firestore.FieldValue.increment(1),
      });
    },
    removeLike(song) {
      this.user.liked.splice(this.user.liked.indexOf(song), 1);
    },
  },
  computed: {
    logoSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '24';
        case 'sm':
          return '32';
        default:
          return '32';
      }
    },
    btnLgPosition() {
      switch (this.$vuetify.breakpoint.name) {
        case 'lg':
          return 'ml-2';
        case 'xl':
          return 'ml-4';
        default:
          return 'ml-4';
      }
    },
    btnXsPosition() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'mt-5';
        case 'sm':
          return 'mt-6';
        case 'md':
          return 'mt-6 ml-2';
        default:
          return 'mt-5';
      }
    },
    nextBtn() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '';
        case 'sm':
          return 'ml-2';
        case 'md':
          return 'ml-8';
        default:
          return 'ml-8';
      }
    },
    prevBtn() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'mr-8';
        case 'sm':
          return 'mr-8';
        case 'md':
          return 'mr-6';
        default:
          return 'mr-6';
      }
    },
    likeSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true;
        case 'sm':
          return true;
      }
      return false;
    },
    image() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 90;
        case 'sm':
          return 110;
        case 'md':
          return 150;
        case 'lg':
          return 150;
        case 'xl':
          return 150;
        default:
          return 150;
      }
    },
    // Cette fonction varie la taille de la card en fonction de la taille de l'écran
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 110;
        case 'sm':
          return 130;
        case 'md':
          return 150;
        case 'lg':
          return 150;
        case 'xl':
          return 190;
        default:
          return 150;
      }
    },
  },
  data() {
    return {
      user: {
        id: ['id'],
        liked: ['657417'],
      },
    };
  },
};
</script>

<style scoped>
.btnSize {
  font-size: 70%;
}
@media (max-width: 600px) {
  section {
    width: calc((100% - (10px)) / 3);
  }
  .textSize {
    font-size: 60%;
  }
  .headSize {
    font-size: 90%;
  }
  .btnSize {
    font-size: 50%;
  }
}
@media (min-width: 600px) {
  section {
    width: calc((100% - (1px)) / 5);
  }
  .textSize {
    font-size: 70%;
  }
  .play {
    transform: scale(0.8);
  }
}
@media (min-width: 960px) {
  section {
    width: calc((100% - (2 * 2px)) / 6);
  }
  .textSize {
    font-size: 75%;
  }
}
@media (min-width: 1264px) {
  section {
    width: calc((100% - (3 * 2px)) / 7);
  }
  .textSize {
    font-size: 80%;
  }
}
@media (min-width: 1904px) {
  section {
    width: calc(100% / 7);
  }
  .textSize {
    font-size: 90%;
  }
}
.carousel {
  width: 100%;
}
.v-card {
  transition: opacity 0.4s ease-in-out;
}
.card:hover {
  transition: all 500ms;
  transform: scale(1.05);
}
.text {
  font-size: 90%;
}
/* Audio */
.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16em;
  height: 16em;
  transform: translate(-50%, -50%);
}
.audio {
  color: #fffffff3;
}
.audio::after,
.audio i,
.audio i::before,
.audio i::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.25em;
  height: 0.5em;
  transform: translate(-50%, -50%);
  background: currentColor;
  border-radius: 0.125em;
}
.audio::after {
  animation: audio4 1.35s infinite;
}
.audio i::before {
  left: -0.8125em;
  animation: audio1 1.35s infinite;
}
.audio i::after {
  left: 1.0625em;
  animation: audio3 1.35s infinite;
}
.audio i:first-child {
  margin-left: -1.875em;
  animation: audio2 1.35s infinite;
}
.audio i:last-child {
  margin-left: 1.875em;
  animation: audio6 1.35s infinite;
}
.audio i:last-child::before {
  animation: audio5 1.35s infinite;
}
.audio i:last-child::after {
  animation: audio7 1.35s infinite;
}
@keyframes audio1 {
  9% {
    height: 1.75em;
  }
  36% {
    height: 0.5em;
  }
  42% {
    height: 2.375em;
  }
  69% {
    height: 0.5em;
  }
  76% {
    height: 3.625em;
  }
  100% {
    height: 1em;
  }
}
@keyframes audio2 {
  9% {
    height: 5.5em;
  }
  36% {
    height: 0.5em;
  }
  42% {
    height: 5.5em;
  }
  69% {
    height: 0.5em;
  }
  76% {
    height: 6.75em;
  }
  100% {
    height: 1em;
  }
}
@keyframes audio3 {
  9% {
    height: 6.5em;
  }
  36% {
    height: 0.5em;
  }
  42% {
    height: 3.5em;
  }
  69% {
    height: 0.5em;
  }
  76% {
    height: 3.5em;
  }
  100% {
    height: 1em;
  }
}
@keyframes audio4 {
  9% {
    height: 1.875em;
  }
  36% {
    height: 0.5em;
  }
  42% {
    height: 3.75em;
  }
  69% {
    height: 0.5em;
  }
  76% {
    height: 5.625em;
  }
  100% {
    height: 1em;
  }
}
@keyframes audio5 {
  9% {
    height: 5em;
  }
  36% {
    height: 0.5em;
  }
  42% {
    height: 1.25em;
  }
  69% {
    height: 0.5em;
  }
  76% {
    height: 3.75em;
  }
  100% {
    height: 1em;
  }
}
@keyframes audio6 {
  9% {
    height: 6.125em;
  }
  36% {
    height: 0.5em;
  }
  42% {
    height: 6.875em;
  }
  69% {
    height: 0.5em;
  }
  76% {
    height: 2.5em;
  }
  100% {
    height: 1em;
  }
}
@keyframes audio7 {
  9% {
    height: 3.125em;
  }
  36% {
    height: 0.5em;
  }
  42% {
    height: 1.875em;
  }
  69% {
    height: 0.5em;
  }
  76% {
    height: 3.75em;
  }
  100% {
    height: 1em;
  }
}
</style>