<template>
  <v-dialog persistent v-model="active" :max-width="popupWidth">
    <template v-slot:activator="{ on , attrs }">
      <slot name="button" v-bind="{ on, attrs }"></slot>
    </template>
    <v-card dark class="details-card">
      <v-icon fixed right class="ma-1 close-dialog" x-large dark @click="onClickOutside(song.type)">mdi-close</v-icon>
      <v-row :style="headerStyle" class="ma-0 align-center">
        <v-col cols="5" lg="3" xl="2" class="my-auto ml-3" v-if="$vuetify.breakpoint.smAndUp">
          <v-img class="rounded" :src="song.imgPath"></v-img>
        </v-col>
        <v-col class="pt-0">
          <v-card-title>
            <h2>{{ song.title }}</h2>
          </v-card-title>
          <v-card-subtitle class="beatmaker pb-0">
            <!-- //TODO: Provide link to beatmaker profile page -->
            <router-link to="beatmaker">{{ song.beatmaker }}</router-link>
          </v-card-subtitle>
          <v-chip color="black" class="ma-1 rounded-lg">
            <v-icon small class="mr-1">mdi-album</v-icon>
            {{ song.genre }}
          </v-chip>
          <v-chip color="black" class="ma-1 rounded-lg">
            <v-icon small class="mr-1">mdi-album</v-icon>
            <span class="mr-1" v-for="(item,index) in song.mood" :key="index">{{item}}</span>
          </v-chip>
          <v-chip color="black" class="ma-1 rounded-lg">
            <v-icon small class="mr-1">mdi-file-document-edit-outline</v-icon>
            {{ `Licence ${song.licence}` }}
          </v-chip>
          <br />
          <v-chip color="black" class="ma-1 rounded-lg">
            <v-img contain width="20" class="mr-1 mb-1" src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficons8-drums-64.png?alt=media&token=ea993af6-d831-48fd-aa0a-62d8daad6f56"></v-img>
            {{ `${song.BPM} BPM` }}
          </v-chip>
          <v-chip color="black" class="ma-1 rounded-lg">
            <v-img contain width="20" class="mr-1 mb-1" src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficons8-drums-64.png?alt=media&token=ea993af6-d831-48fd-aa0a-62d8daad6f56"></v-img>
            {{song.key}}
          </v-chip>
          <br />
          <v-chip color="black" class="ma-1 rounded-lg">
            <v-icon small class="mr-1">mdi-play-circle-outline</v-icon>
            {{song.nbListens}}
          </v-chip>
          <v-chip color="black" class="ma-1 rounded-lg">
            <v-icon small class="mr-1">mdi-download-circle-outline</v-icon>
            {{song.nbDownload}}
          </v-chip>
          <!-- <v-chip color="black" class="ma-1 rounded-lg"><v-icon small class="mr-1">mdi-thumb-up-outline</v-icon>{{song.nbListens}}</v-chip> -->
          <v-row v-if="$vuetify.breakpoint.lgAndUp" dense class="my-auto px-2 align-center">
            <v-chip color="black" class="ma-1 rounded-lg">
              <v-icon small class="mr-1" color="green">mdi-thumb-up</v-icon>
              {{song.nbListens}}
            </v-chip>
            <v-btn small color="white" class="ma-1 px-1 py-4 rounded-pill black--text font-weight-bold">
              <span class="pl-1" style="font-size:150%">Play</span>
              <v-icon large color="black">mdi-play</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="userIsLogged===true" small class="mx-1 my-1" color="info">S'abonner</v-btn>
            <v-btn v-if="userIsLogged===true" small class="mx-1 my-1" color="info">Envoyer un message</v-btn>
            <v-btn @click="copyURL" small class="ml-1 my-1" light>
              <v-icon small class="mr-1">mdi-link-variant</v-icon>Obtenir le lien
            </v-btn>
          </v-row>
          <v-chip v-if="$vuetify.breakpoint.mdAndDown" color="black" class="ma-1 rounded-lg">
            <v-icon small class="mr-1" color="green">mdi-thumb-up</v-icon>
            {{song.nbListens}}
          </v-chip>
          <v-btn v-if="$vuetify.breakpoint.mdAndDown" small color="white" class="ma-1 px-1 py-4 rounded-pill black--text font-weight-bold">
            <span class="pl-1" style="font-size:150%">Play</span>
            <v-icon large color="black">mdi-play</v-icon>
          </v-btn>
          <v-row v-if="$vuetify.breakpoint.mdAndDown" dense class="my-auto px-2 align-center">
            <v-btn v-if="userIsLogged===true" x-small class="mx-1 my-1" color="info">S'abonner</v-btn>
            <v-btn v-if="userIsLogged===true" x-small class="mx-1 my-1" color="info">Envoyer un message</v-btn>
            <v-btn @click="copyURL" x-small class="ml-1 my-1" light>
              <v-icon small class="mr-1">mdi-link-variant</v-icon>Obtenir le lien
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-card dark>
        <v-row v-if="userIsLogged===true && song.type === 'instru'" class="justify-center py-10 mx-0">
          <v-col cols="11" md="10">
            <v-row>
              <v-col cols="1" v-if="$vuetify.breakpoint.lgAndUp" class="warning text-center">
                <v-icon x-large class="mt-10">mdi-currency-eur-off</v-icon>
              </v-col>
              <!-- TODO : AJOUTER LES DIFFERENTS TEXTE EN FONCTION DES LICENCES -->
              <v-col class="warning darken-2">
                <p>
                  <span>Licence</span>
                  <span>{{song.licence}}</span>
                </p>
                <p v-if="song.licence == 'Non-commerciale'">
                  <span>
                    {{ `En téléchargeant '${song.title}' vous vous engagez personnellement à ne pas utiliser cette instrumentale à des fins commerciales.
                    Vous vous engagez également à créditer l'auteur de l'instrumentale lorsqu'elle apparaît sur un média social ou de streaming` }}
                  </span>
                </p>
              </v-col>
            </v-row>
            <v-row class="justify-space-between align-center mt-5">
              <v-col cols="12" sm="5" class="rounded-lg px-0 py-0 my-1">
                <!--TODO: ADD DOWNLOAD -->
                <v-btn large width="100%" class="grey">
                  <v-row v-if="$vuetify.breakpoint.lgAndUp" class="justify-center align-center">
                    <span class="black--text font-weight-bold text-capitalize" style="font-size:180%">Télécharger</span>
                    <v-icon class="mt-1 ml-1" large color="black">mdi-download</v-icon>
                    <v-chip class="white black--text font-weight-bold" style="font-size:110%">MP3</v-chip>
                  </v-row>
                  <v-row v-else class="justify-center align-center">
                    <span class="black--text font-weight-bold text-capitalize" style="font-size:120%">Télécharger</span>
                    <v-icon class="mt-1 ml-1" color="black">mdi-download</v-icon>
                    <v-chip small class="white black--text font-weight-bold" style="font-size:110%">MP3</v-chip>
                  </v-row>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="5" class="rounded-lg px-0 py-0 my-1">
                <!--TODO: ADD DOWNLOAD -->
                <v-btn large width="100%" class="grey">
                  <v-row v-if="$vuetify.breakpoint.lgAndUp" class="justify-center align-center">
                    <span class="black--text font-weight-bold text-capitalize" style="font-size:180%">Télécharger</span>
                    <v-icon class="mt-1 ml-1" large color="black">mdi-download</v-icon>
                    <v-chip class="red black--text font-weight-bold" style="font-size:110%">WAV</v-chip>
                  </v-row>
                  <v-row v-else class="justify-center align-center">
                    <span class="black--text font-weight-bold text-capitalize" style="font-size:120%">Télécharger</span>
                    <v-icon class="mt-1 ml-1" color="black">mdi-download</v-icon>
                    <v-chip small class="red black--text font-weight-bold" style="font-size:110%">WAV</v-chip>
                  </v-row>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <p class="mx-2 mt-2" style="font-size:150%">Commentaires</p>
        <v-text-field style="max-height:3.5em" v-if="userIsLogged===true" v-model="newComment" label="Écrire un commentaire..." solo filled outlined clearable @keydown.enter="submitComment" class="mx-4 mt-2"></v-text-field>
        <v-list three-line class="py-0">
          <v-list-item v-for="(comment, index) in song.submission.comments" :key="index">
            <v-list-item-avatar>
              <!-- //TODO: Provide link to beatmaker profile page -->
              <router-link to="beatmaker" class="commenterLink">
                <v-list-item-avatar>
                  <v-img :src="comment.avatar"></v-img>
                </v-list-item-avatar>
              </router-link>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <!-- //TODO: Provide link to beatmaker profile page -->
                <router-link to="beatmaker" class="commenterLink">
                  <h4>{{ comment.author }}</h4>
                </router-link>
              </v-list-item-title>
              {{ comment.text }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <p class="mx-4" v-if="!song.submission.comments.length">Il n'y a pas (encore) de commentaires pour ce son.</p>
        <v-row class="mx-0 justify-center">
          <v-col cols="12" class="pl-3 pr-7">
            <SongCarousel :songCards="popularCarousel" beatPlaylist="Suggestions" />
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import SongCarousel from './SongCarousel.vue';
export default {
  components: { SongCarousel },
  props: {
    song: {
      type: Object,
      required: true,
    },
    isActive: Boolean,
  },
  mounted() {
    if (this.isActive === true) {
      this.active = true;
    }
  },
  data() {
    return {
      active: false,
      tabs: ['Gestion demande'],
      selectedTab: 0,
      userIsLogged: true,
      newComment: '',
      submissionComment: '',

      channels: [
        {
          text: 'InstruRap',
          value: 'InstruRap',
        },
        {
          text: 'InstruLab',
          value: 'InstruLab',
        },
      ],
      popularCarousel: [
        {
          id: '657417',
          title: 'Blurred Vision',
          beatmaker: 'Pylot',
          imgPath: 'https://ukf.com/wp-content/uploads/2015/04/pylot.jpg',
          songPath: 'https://www.youtube.com/watch?v=5AKxVJlCA4M',
          beatmakerPath: 'http://iampylot.com/',
          bpm: 150,
          isPlaying: false,
          likes: 1,
          Play: false,
        },
        {
          id: '657418',
          title: 'Blurred Vision',
          beatmaker: 'Pylot',
          imgPath: 'https://ukf.com/wp-content/uploads/2015/04/pylot.jpg',
          songPath: 'https://www.youtube.com/watch?v=5AKxVJlCA4M',
          beatmakerPath: 'http://iampylot.com/',
          bpm: 150,
          isPlaying: false,
          likes: 0,
          Play: false,
        },
        {
          id: '657435',
          title: 'Blurred Vision',
          beatmaker: 'Pylot',
          imgPath: 'https://ukf.com/wp-content/uploads/2015/04/pylot.jpg',
          songPath: 'https://www.youtube.com/watch?v=5AKxVJlCA4M',
          beatmakerPath: 'http://iampylot.com/',
          bpm: 150,
          isPlaying: false,
          likes: 0,
          Play: false,
        },
        {
          id: '658418',
          title: 'Blurred Vision',
          beatmaker: 'Pylot',
          imgPath: 'https://ukf.com/wp-content/uploads/2015/04/pylot.jpg',
          songPath: 'https://www.youtube.com/watch?v=5AKxVJlCA4M',
          beatmakerPath: 'http://iampylot.com/',
          bpm: 150,
          isPlaying: false,
          likes: 0,
          Play: false,
        },
        {
          id: '677418',
          title: 'Blurred Vision',
          beatmaker: 'Pylot',
          imgPath: 'https://ukf.com/wp-content/uploads/2015/04/pylot.jpg',
          songPath: 'https://www.youtube.com/watch?v=5AKxVJlCA4M',
          beatmakerPath: 'http://iampylot.com/',
          bpm: 150,
          isPlaying: false,
          likes: 0,
          Play: false,
        },
        {
          id: '657419',
          title: 'Blurred Vision',
          beatmaker: 'Pylot',
          imgPath: 'https://ukf.com/wp-content/uploads/2015/04/pylot.jpg',
          songPath: 'https://www.youtube.com/watch?v=5AKxVJlCA4M',
          beatmakerPath: 'http://iampylot.com/',
          bpm: 150,
          isPlaying: false,
          likes: 0,
          Play: false,
        },
        {
          id: '657420',
          title: 'Blurred Vision',
          beatmaker: 'Pylot',
          imgPath: 'https://ukf.com/wp-content/uploads/2015/04/pylot.jpg',
          songPath: 'https://www.youtube.com/watch?v=5AKxVJlCA4M',
          beatmakerPath: 'http://iampylot.com/',
          bpm: 150,
          isPlaying: false,
          likes: 0,
          Play: false,
        },
        {
          id: '657421',
          title: 'Blurred Vision',
          beatmaker: 'Pylot',
          imgPath: 'https://ukf.com/wp-content/uploads/2015/04/pylot.jpg',
          songPath: 'https://www.youtube.com/watch?v=5AKxVJlCA4M',
          beatmakerPath: 'http://iampylot.com/',
          bpm: 150,
          isPlaying: false,
          likes: 0,
          Play: false,
        },
      ],
      selectedChannel: 'InstruRap',
      //TODO: Sync data with back
      userIsAdmin: true,
    };
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url("${this.song.imgPath}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      };
    },
    popupWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '90%';
        case 'sm':
          return '85%';
        case 'md':
          return '75%';
        case 'lg':
          return '70%';
        case 'xl':
          return '65%';
        default:
          return '65%';
      }
    },
  },
  methods: {
    copyURL() {
      var URL = 'instrurap' + this.$route.path;
      navigator.clipboard.writeText(URL);
    },
    onClickOutside(type) {
      if (type === 'sample') {
        this.$router.push('/loops');
      } else this.$router.push('/');
    },
    //TODO: Sync actions with back
    submitComment() {
      this.newComment = '';
    },
    acceptSubmission() {
      console.log(this.selectedChannel);
    },
    rejectSubmission() {
      console.log(this.submissionComment);
      this.submissionComment = '';
    },
  },
  //Adds appropriate tabs and preselects appropriate channel
  created() {
    if (this.song.submission.status === 'pending') {
      var index = this.channels.findIndex(
        (channel) => channel.value === this.song.submission.channel
      );
      this.channels[index].text += ' (souhaité)';
      this.selectedChannel = this.channels[index].value;
    }
  },
};
</script>
<style scoped>
.v-dialog__content {
  align-items: flex-start;
}
.close-dialog {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.beatmaker a {
  font-size: 150%;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

.commenterLink {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 600px) {
  .close-dialog {
    position: fixed;
    top: 30px;
    right: 30px;
  }
}
</style>