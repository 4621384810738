<template lang="">
<v-container fluid class="px-0 py-0">
    <v-row class="justify-center grey darken-2">
        <v-col class="pb-0">            
            <PlaylistHeader :PlaylistName="this.$route.params.id" :playlist="beatmakerList"/>
        </v-col>
    </v-row>
        <v-row style="min-height:76vh" class="grey darken-4 justify-center">
            <v-col cols="11" md=8>
                <span class="white--text h1 songItemHead">Tous les titres</span>
                <SongItem :Songs="songItem"/>
            </v-col>
        </v-row>
</v-container>
</template>
<script>
import SongItem from "../components/Global_Components/SongItem.vue";
import PlaylistHeader from "../components/PlaylistPage/PlaylistHeader.vue";
export default {
	components: {
		PlaylistHeader,
		SongItem,
	},
	data() {
		return {
			songItem: [
				{
					songID: "657417",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 300,
					genre: "Rap",
					licence: "Licence non-commerciale",
					mood: "Triste",
					instrument: "Piano",
				},
				{
					songID: "657418",
					title: "Search",
					beatmaker: "Filtered",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 100,
					genre: "Drill",
					licence: "Licence non-commerciale",
					mood: "Kek",
					instrument: "Flute",
				},
				{
					songID: "657419",
					title: "Data",
					beatmaker: "Green Wojack",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 10,
					BPM: 105,
					genre: "Rap",
					licence: "Ecoute",
					mood: "Dansant",
					instrument: "Tambour",
				},
				{
					songID: "657420",
					title: "Last One",
					beatmaker: "Maker",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 180,
					genre: "Trap",
					licence: "Licence non-commerciale",
					mood: "Joyeux",
					instrument: "Accordeon",
				},
				{
					songID: "657421",
					title: "On the Run",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 170,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Triste",
					instrument: "Guitare",
				},
				{
					songID: "657422",
					title: "It was only a Dream",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 10,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Dansant",
					instrument: "piano",
				},
				{
					songID: "657498",
					title: "On the Run",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 170,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Triste",
					instrument: "Guitare",
				},
				{
					songID: "657499",
					title: "It was only a Dream",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 10,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Dansant",
					instrument: "piano",
				},
				{
					songID: "657487",
					title: "On the Run",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 170,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Triste",
					instrument: "Guitare",
				},
				{
					songID: "657488",
					title: "It was only a Dream",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 10,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Dansant",
					instrument: "piano",
				},
			],
			beatmakerList: [
				{
					name: "Green",
				},
				{
					name: "Wojack",
				},
				{
					name: "Artiste",
				},
				{
					name: "Sélection",
				},
				{
					name: "MAJUSTCULEETLONG",
				},
				{
					name: "Conception",
				},
				{
					name: "Dynamic",
				},
				{
					name: "Red",
				},
				{
					name: "Wajack",
				},
				{
					name: "Artoste",
				},
				{
					name: "Séliction",
				},
				{
					name: "MAJUSTCULEELONG",
				},
				{
					name: "Concepion",
				},
				{
					name: "Dynamik",
				},
				{
					name: "LastOne",
				},
			],
		};
	},
};
</script>
<style lang="">
</style>