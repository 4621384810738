<template lang="">
    <v-app-bar bottom app dark :height=playerHeight class='black py-0 my-0'>
        <v-row class="align-center">
            <v-col cols="12" order-lg="2" lg=2>
                <v-row class="align-center">
                    <v-col cols=2 v-if="$vuetify.breakpoint.lgAndUp" class="py-0">
                <v-menu top rounded dark offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon large>mdi-menu</v-icon>
                        </v-btn>
                    </template>
                    <v-list width="20em" class="grey darken-4 py-1 mb-2">
                        <v-list-item v-for="(item, index) in songHistory" class="black my-1 py-0 px-0" :key="index">
                            <v-btn plain text height="5em" width="100%">
                                <v-row dense class="align-center">
                                    <v-col cols="1" class='py-0 px-0'>
                                        <p>
                                            <span>{{index + 1}}</span>
                                        </p>
                                    </v-col>
                                    <v-col cols="2" class='py-0 px-0'>
                                        <v-avatar size="64" tile class='rounded'>
                                                <v-img :src="item.imgPath"></v-img>
                                        </v-avatar>
                                    </v-col>
                                    <v-col cols="8" offset="1" class='py-0'>
                                        <v-row dense class="align-center">
                                            <v-col class='py-0 px-0'>
                                                <p class="px-0 mt-4 text-start">
                                                    <span class="text-capitalize h1">{{ item.title }}</span> <br>
                                                    <span class="text-lowercase">par</span><span class="text-capitalize">{{ item.beatmaker }}</span> <br>
                                                    <span>{{ item.bpm }}</span> <span>BPM </span>
                                                    <span> {{ item.nbListens }}</span> <span class="text-capitalize">écoutes</span>
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-btn>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
                    <v-col cols="12" lg=9 offset-lg=1 class="ml-4 py-0">
                        <v-row class='align-center pr-3'>
                            <v-avatar :size=avatarSize>
                                <v-img :src="song.imgPath"></v-img>
                            </v-avatar>
                            <p class="ml-2 mt-3 songInfos" >
                                <span class="pointer h1">{{song.title}}</span> <br v-if="$vuetify.breakpoint.lgAndUp">
                                <span class="pointer" @click="routeTo(song.beatmaker,'user')">{{song.beatmaker}}</span>
                            </p>
                            <v-spacer></v-spacer>
                            <span v-if="$vuetify.breakpoint.mdAndDown" class="songInfos">00:10/01:10</span>
                            <v-btn @click="routeTo(song.id,'SongPopup')" icon v-if="$vuetify.breakpoint.mdAndDown">
                                <v-icon>mdi-information-outline</v-icon>
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" order-lg="3" lg=7 class="pl-0">
                <v-col class="px-6" v-if="$vuetify.breakpoint.lgAndUp">
                    <v-row>
                        <span>00:01</span>
                        <v-spacer></v-spacer>
                        <span>01:13</span>
                    </v-row>
                </v-col>
                <v-col>
                    <v-progress-linear background-color="white" v-model="songTimer" color="pink"></v-progress-linear>
                </v-col>
            </v-col>
            <v-col cols=2 lg=1 order-lg="1" v-if="$vuetify.breakpoint.mdAndDown" class="py-0 px-0">
                <v-menu top rounded dark offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon large>mdi-menu</v-icon>
                        </v-btn>
                    </template>
                    <v-list width="20em" class="grey darken-4 py-1 mb-2">
                        <v-list-item v-for="(item, index) in songHistory" class="black my-1 py-0 px-0" :key="index">
                            <v-btn plain text height="5em" width="100%">
                                <v-row dense class="align-center">
                                    <v-col cols="1" class='py-0 px-0'>
                                        <p>
                                            <span>{{index + 1}}</span>
                                        </p>
                                    </v-col>
                                    <v-col cols="2" class='py-0 px-0'>
                                        <v-avatar size="64" tile class='rounded'>
                                                <v-img :src="item.imgPath"></v-img>
                                        </v-avatar>
                                    </v-col>
                                    <v-col cols="8" offset="1" class='py-0'>
                                        <v-row dense class="align-center">
                                            <v-col class='py-0 px-0'>
                                                <p class="px-0 mt-4 text-start">
                                                    <span class="text-capitalize h1">{{ item.title }}</span> <br>
                                                    <span class="text-lowercase">par</span><span class="text-capitalize">{{ item.beatmaker }}</span> <br>
                                                    <span>{{ item.bpm }}</span> <span>BPM </span>
                                                    <span> {{ item.nbListens }}</span> <span class="text-capitalize">écoutes</span>
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-btn>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
            <v-col cols="10" lg=3 order-lg="4" class="py-0 px-0">
                <v-row class="align-center">
                    <v-col cols="12" lg=6>
                        <v-row class="pr-5">
                            <v-col cols="2">
                                <v-btn v-if="songLiked === false" @click="like" icon>                                
                                    <v-icon size="30">mdi-thumb-up-outline</v-icon>
                                </v-btn>
                                <v-btn v-if="songLiked === true" @click="removeLike" icon>                                
                                    <v-icon color='green' size="30">mdi-thumb-up</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn @click="previousSong" icon>                                
                                    <v-icon size="30">mdi-rewind-outline</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn icon>                                
                                    <v-icon v-if="songPaused === true" @click="songPlay" size="30">mdi-play-circle-outline</v-icon>
                                    <v-icon v-if="songPaused === false" @click="songPause" size="30">mdi-pause-circle-outline</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn @click="nextSong" icon>                                
                                    <v-icon size="30">mdi-fast-forward-outline</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn v-if="songRepeat === false" @click="repeat" icon>                                
                                    <v-icon size="30">mdi-repeat-variant</v-icon>
                                </v-btn>
                                <v-btn v-if="songRepeat === true" @click="stopRepeat" icon>                                
                                    <v-icon color='red' size="30">mdi-repeat-variant</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="2" v-if="$vuetify.breakpoint.lgAndUp">                                
                                <v-btn @click="routeTo(song.id,'SongPopup')" icon>                                
                                    <v-icon size="30">mdi-information-outline</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="2" class="text-end" v-if="$vuetify.breakpoint.mdAndDown">
                                <v-menu top rounded dark offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on"  icon>                                
                                            <v-icon size="30">mdi-volume-high</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list width="3em">
                                        <v-slider vertical dense v-model="mobileVolume"></v-slider>
                                    </v-list>
                                </v-menu>                              
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6" v-if="$vuetify.breakpoint.lgAndUp" class="py-0">
                        <v-row class="justify-center">
                            <v-col cols="11">
                                <v-slider class='volumeSlider' dense @click:prepend="muteVolume" :prepend-icon="volumeIcon" v-model="songVolume"></v-slider>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>            
        </v-row>
    </v-app-bar>
</template>
<script>
export default {
	methods: {
		previousSong() {
			this.$emit("previous");
		},
		nextSong() {
			this.$emit("next");
		},
		like() {
			this.songLiked = true;
			this.$emit("liked");
		},
		removeLike() {
			this.songLiked = false;
			this.$emit("removedLike");
		},
		repeat() {
			this.songRepeat = true;
			this.$emit("repeat");
		},
		stopRepeat() {
			this.songRepeat = false;
			this.$emit("stopRepeat");
		},
		songPause() {
			this.songPaused = true;
			this.$emit("pause");
		},
		songPlay() {
			this.songPaused = false;
			this.$emit("play");
		},
		routeTo(link, type) {
			this.$router.push({ name: type, params: { id: link } });
		},
		muteVolume() {
			if (this.songMuted === false) {
				this.songMuted = true;
				this.beforeMuted = this.songVolume;
				this.songVolume = 0;
			} else if (this.songMuted === true) {
				if (this.beforeMuted == 0) {
					this.songMuted = false;
					this.songVolume = 50;
				} else if (this.beforeMuted > 0) {
					this.songMuted = false;
					this.songVolume = this.beforeMuted;
					this.beforeMuted = 0;
				}
			} else return "";
		},
	},
	computed: {
		playerHeight() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "145";
				case "sm":
					return "160";
				case "md":
					return "170";
				case "lg":
					return "80";
				case "xl":
					return "65";
				default:
					return "120";
			}
		},
		avatarSize() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "32";
				case "sm":
					return "48";
				case "md":
					return "54";
				case "lg":
					return "52";
				case "xl":
					return "48";
				default:
					return "32";
			}
		},
		volumeIcon() {
			var volume = this.songVolume;
			if (volume == 0) {
				return "mdi-volume-mute";
			} else if (volume > 0 && volume < 32) {
				return "mdi-volume-low";
			} else if (volume >= 32 && volume < 66) {
				return "mdi-volume-medium";
			} else if (volume >= 66) {
				return "mdi-volume-high";
			} else return "";
		},
	},
	data() {
		return {
			songLiked: false,
			songMuted: false,
			songRepeat: false,
			songPaused: true,
			mobileVolume: 0,
			beforeMuted: 0,
			songVolume: 50,
			songTimer: 0,
			songHistory: [
				{
					songID: "657417",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 1,
					nbListens: 10,
					Play: false,
				},
				{
					songID: "657418",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 1,
					nbListens: 10,
					Play: false,
				},
				{
					songID: "657419",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 1,
					nbListens: 10,
					Play: false,
				},
			],
			song: {
				id: "657417",
				title: "Blurred Vision",
				type: "instru",
				beatmaker: "Pylot",
				imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
				songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
				beatmakerPath: "http://iampylot.com/",
				bpm: 150,
				isPlaying: false,
				likes: 1,
				nbListens: 10,
				Play: false,
			},
		};
	},
};
</script>
<style>
.volumeSlider {
	height: 2em;
}
.songInfos {
	font-size: 70%;
}
</style>