<template lang="">
<v-container fluid class="grey darken-4">
  <router-view />
  <v-row class="justify-center">
    <v-scale-transition>
      <v-img width='100%' class="padSearch py-0" :height="imgHeight" lazy :lazy-src="lazySrc" :src="imgSrc">
        <v-col>
          <v-row  class="justify-center">
            <v-col cols=10 offset-md="1" class='px-0'>
              <v-row    class='pt-12 pb-9'>
                <v-col  cols="12" sm=6 md=4 xl=3 class="px-5">
                  <Flow  :class="flowMargin"/>
                </v-col>
                <v-col cols=12 md=7 :class="searchPadding">
                  <SearchBar page="songs" @research="updateSongItems" @advanced="advancedSearch"/>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="imgEffect">
              <v-row class="justify-center">
                <v-col cols="12" md=10 xl=9>
                  <SongCarousel :songCards="popularCarousel" beatPlaylist="Populaires"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-img>
    </v-scale-transition>
  </v-row>
  <v-row>
    <v-col>
      <v-row class="justify-center" :class="songPadding">
        <v-col cols="12" sm=8 offset-md="1">
          <SongItem ref="songItemRef" :Songs="songItem"/>
        </v-col>
        <v-col cols="3" md=2 v-if="$vuetify.breakpoint.smAndUp">
          <BeatmakerList :beatmaker="beatmakerCard"/>
        </v-col>
      </v-row>
      <v-row class="justify-center grey darken-3">
        <v-col cols="12" md=10 xl=9>
          <SongCarousel :songCards="instrurapCarousel" beatPlaylist="Sélection InstruRap"/>
        </v-col>
      </v-row>
      <v-row class="white justify-center">
        <v-col cols="12" md=10 xl=9>
          <BeatmakerCarousel :beatmaker="beatmakerCard" class="py-5 "/>
        </v-col>
      </v-row>
      <v-row class="justify-center grey darken-3">
        <v-col cols="12" md=10 xl=9>
          <SongCarousel :songCards="weeklyCarousel" beatPlaylist="Sélection de la semaine"/>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col cols=11 xl=10>
          <RubricCard :rubricCard="rubric"/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</v-container>

</template>
<script>
import Flow from "../components/HomePage/Flow.vue";
import BeatmakerList from "../components/HomePage/BeatmakerList.vue";
import SongItem from "../components/Global_Components/SongItem.vue";
import SearchBar from "../components/Global_Components/SearchBar.vue";
import SongCarousel from "../components/Global_Components/SongCarousel.vue";
import BeatmakerCarousel from "../components/HomePage/BeatmakerCarousel.vue";
import RubricCard from "../components/HomePage/RubricCard.vue";
import { db } from "../firebase";
export default {
	components: {
		SearchBar,
		Flow,
		SongItem,
		BeatmakerList,
		SongCarousel,
		BeatmakerCarousel,
		RubricCard,
	},
	methods: {
		addFrontDataSongs(array) {
			array.forEach((el, idx) => {
				array[idx].isPlaying = false;
				array[idx].playButton = false;
			});
		},
		async executeQuerry(q) {
			const res = [];
			await q.get().then((snapshot) => {
				snapshot.forEach((element) => {
					res.push(element.data());
				});
			});
			return res;
		},
		async test() {
			this.addFrontDataSongs(this.popularCarousel);
		},

		updateSongItems(searchResult, isEmpty) {
			if (isEmpty) {
				// If empty we show the base items of songItem
				this.$refs.songItemRef.songItems = this.songItem;
			} else {
				this.$refs.songItemRef.songItems = searchResult;
			}
		},
		advancedSearch() {
			this.imgMaxHeight = !this.imgMaxHeight;
		},
	},
	computed: {
		flowMargin() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "";
				case "xl":
					return "ml-8";
				default:
					return "";
			}
		},
		songPadding() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "";
				case "xl":
					return "pl-10";
				default:
					return "";
			}
		},
		searchPadding() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "";
				case "xl":
					return "pr-10";
				default:
					return "";
			}
		},
		imgHeight() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					switch (this.imgMaxHeight) {
						case false:
							return "475";
						case true:
							return "1250";

						default:
							return "475";
					}
				case "sm":
					switch (this.imgMaxHeight) {
						case false:
							return "495";
						case true:
							return "1290";

						default:
							return "495";
					}
				case "md":
					switch (this.imgMaxHeight) {
						case false:
							return "435";
						case true:
							return "900";

						default:
							return "435";
					}
				case "lg":
					switch (this.imgMaxHeight) {
						case false:
							return "435";
						case true:
							return "900";

						default:
							return "435";
					}
				case "xl":
					switch (this.imgMaxHeight) {
						case false:
							return "470";
						case true:
							return "935";

						default:
							return "470";
					}
				default:
					return "500";
			}
		},
		lazySrc() {
			const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8];
			const random = Math.floor(Math.random() * numbers.length);
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			this.random = random;
			const lazy = this.image[this.random].lazy;
			return lazy;
		},
		imgSrc() {
			const img = this.image[this.random].img;
			return img;
		},
	},

	// firestore() {
	//   return {
	//     popularCarousel: db.collection("songs").orderBy("nbLikes").limit(20),
	//   };
	// },
	async mounted() {
		this.popularCarousel = await this.executeQuerry(
			db.collection("songs").orderBy("nbLikes").limit(20)
		);
		this.addFrontDataSongs(this.popularCarousel);
	},
	data() {
		return {
			songs: [],
			popularCarousel: [],
			imgMaxHeight: false,
			random: 0,
			songItem: [
				{
					id: "657417",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					isPlaying: false,
					Like: 1000,
					BPM: 300,
					genre: "Rap",
					licence: "Licence non-commerciale",
					mood: "Triste",
					instrument: "Piano",
				},
				{
					id: "657418",
					title: "Search",
					beatmaker: "Filtered",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 100,
					genre: "Drill",
					licence: "Licence non-commerciale",
					mood: "Kek",
					instrument: "Flute",
				},
				{
					id: "657419",
					title: "Data",
					beatmaker: "Green Wojack",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 10,
					BPM: 105,
					genre: "Rap",
					licence: "Ecoute",
					mood: "Dansant",
					instrument: "Tambour",
				},
				{
					id: "657420",
					title: "Last One",
					beatmaker: "Maker",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 180,
					genre: "Trap",
					licence: "Licence non-commerciale",
					mood: "Joyeux",
					instrument: "Accordeon",
				},
				{
					id: "657421",
					title: "On the Run",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 170,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Triste",
					instrument: "Guitare",
				},
				{
					id: "657422",
					title: "It was only a Dream",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 10,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Dansant",
					instrument: "piano",
				},
				{
					id: "657498",
					title: "On the Run",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 170,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Triste",
					instrument: "Guitare",
				},
				{
					id: "657499",
					title: "It was only a Dream",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 10,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Dansant",
					instrument: "piano",
				},
				{
					id: "657487",
					title: "On the Run",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 170,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Triste",
					instrument: "Guitare",
				},
				{
					id: "657488",
					title: "It was only a Dream",
					beatmaker: "Timecop1983",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					isPlaying: false,
					Like: 1,
					BPM: 10,
					genre: "Drill",
					licence: "Ecoute",
					mood: "Dansant",
					instrument: "piano",
				},
			],
			rubric: [
				{
					genre: "Pop",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					nbOfSong: 15,
				},
				{
					genre: "Rap",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					nbOfSong: 15,
				},
				{
					genre: "Club",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					nbOfSong: 15,
				},
				{
					genre: "Lofi",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					nbOfSong: 15,
				},
				{
					genre: "Dancehall",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					nbOfSong: 15,
				},
				{
					genre: "Grime",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					nbOfSong: 15,
				},
				{
					genre: "Funk",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					nbOfSong: 15,
				},
				{
					genre: "Trap",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					nbOfSong: 15000,
				},
				{
					genre: "Chill",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					nbOfSong: 15,
				},
				{
					genre: "Drill",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					nbOfSong: 15,
				},
				{
					genre: "Retro",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					nbOfSong: 15,
				},
			],
			beatmakerCard: [
				{
					name: "Green Wojack",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
				},
				{
					name: "Green Wojack9",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
				},
				{
					name: "Green Wojack8",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
				},
				{
					name: "Green Wojack7",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
				},
				{
					name: "Green Wojack6",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
				},
				{
					name: "Green Wojack5",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
				},
				{
					name: "Green Wojack4",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
				},
				{
					name: "Green Wojack3",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
				},
				{
					name: "Green Wojack2",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
				},
				{
					name: "Green Wojack1",
					avatar: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
				},
			],

			popcaroussel: [
				{
					id: "657417",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 1,
					Play: false,
				},
				{
					id: "657418",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					id: "657435",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					id: "658418",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					id: "677418",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					id: "657419",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					id: "657420",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					id: "657421",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
			],
			instrurapCarousel: [
				{
					id: "657417",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 1,
					Play: false,
				},
				{
					id: "657418",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					id: "657435",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					id: "658418",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					id: "677418",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					id: "657419",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					id: "657420",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					id: "657421",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
			],
			weeklyCarousel: [
				{
					id: "657417",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 1,
					Play: false,
				},
				{
					id: "657418",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					id: "657435",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					id: "658418",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					id: "677418",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					id: "657419",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					id: "657420",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
				{
					id: "657421",
					title: "Blurred Vision",
					beatmaker: "Pylot",
					imgPath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					bpm: 150,
					isPlaying: false,
					likes: 0,
					Play: false,
				},
			],
			image: [
				{
					img: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2Falex-meier-c4Stt0rvQ8o-unsplash-min%20(2).jpg?alt=media&token=d84d81b4-3e42-403f-920a-82d5710cc6c2",
					lazy: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2FLAZYalex-meier-c4Stt0rvQ8o-unsplash-min%20(2).jpg?alt=media&token=777b2f7e-5c19-4efd-acb2-aebde1e1e89a",
				},
				{
					img: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2Fantoine-julien-bl1_IhZL6Pc-unsplash-min%20(1).jpg?alt=media&token=f501c326-0eb0-42cd-9cbe-903dde925bfd",
					lazy: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2FLAZYantoine-julien-bl1_IhZL6Pc-unsplash-min%20(1).jpg?alt=media&token=4d3a39cb-a869-4074-a689-e52ef06b97d0",
				},
				{
					img: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2Fcaught-in-joy-ptVBlniJi50-unsplash-min%20(2).jpg?alt=media&token=1de2601f-4e2f-4135-919e-ef11b4dd9111",
					lazy: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2FLAZYcaught-in-joy-ptVBlniJi50-unsplash-min%20(2).jpg?alt=media&token=a95227a6-060a-49b1-85ca-f6997d7bde46",
				},
				{
					img: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2Fgordon-cowie-qQzw8jPvip8-unsplash-min.jpg?alt=media&token=e0ab445b-780b-4d06-8e08-bbe80e568919",
					lazy: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2FLAZYgordon-cowie-qQzw8jPvip8-unsplash-min.jpg?alt=media&token=aa5357e3-d0d6-4f5c-a783-c4c4d057d79f",
				},
				{
					img: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2Fjon-tyson-HxwdZ7vLwaY-unsplash-min.jpg?alt=media&token=9cfd299c-1011-4435-a5e4-64610910c4f8",
					lazy: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2FLAZYjon-tyson-HxwdZ7vLwaY-unsplash-min.jpg?alt=media&token=5a3adf23-8b12-4639-8b96-5d5ee30c3e05",
				},
				{
					img: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2Fkushal-medhi-5v5VKmvNqF8-unsplash-min%20(1).jpg?alt=media&token=9d12f555-5d26-42bc-a5b7-2eaba49aec69",
					lazy: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2FLAZYkushal-medhi-5v5VKmvNqF8-unsplash-min%20(1).jpg?alt=media&token=3fb597a2-888c-4185-b3e9-8522144727c5",
				},
				{
					img: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2Flouis-pellissier-wJ2SaSiL5FA-unsplash-min.jpg?alt=media&token=32678290-9089-41dc-abce-c3bf02e261d8",
					lazy: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2FLAZYlouis-pellissier-wJ2SaSiL5FA-unsplash-min.jpg?alt=media&token=a9e55ca2-7ea8-4438-845d-ea048483d56d",
				},
				{
					img: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2Fnicolas-gras-40f1gfxbwdA-unsplash-min.jpg?alt=media&token=7b833843-226b-4978-926b-1050cef85fb8",
					lazy: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2FLAZYnicolas-gras-40f1gfxbwdA-unsplash-min.jpg?alt=media&token=6bc87ddc-691e-419e-9aec-0dfe674aa663",
				},
				{
					img: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2Ftymur-kuchumov-i6HKsKPjDKk-unsplash-min.jpg?alt=media&token=a5980479-b234-483d-8081-c473d2c592a6",
					lazy: "https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2FPage%20d'accueil%2FLAZYtymur-kuchumov-i6HKsKPjDKk-unsplash-min.jpg?alt=media&token=521cae37-ce11-463e-a5f9-b16c8abcc52e",
				},
			],
		};
	},
};
</script>
<style>
html,
body {
	margin: 0px;
	height: 100%;
}
.imgEffect {
	background-color: rgba(12, 12, 12, 0.5);
}
.padSearch {
	transition: all 0.3s ease-in;
}
</style>