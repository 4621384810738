<template>
	<v-container fluid :class="backgroundColor">
		<v-row v-if="page == 'loops' " class="justify-center">
			<v-col cols="12" md="10" offset-lg="2">
				<v-row class="justify-end">
					<v-col cols="12" md="8">
						<v-row justify="center">
							<v-col offset-md="2" offset-lg="0" offset-xl="2" cols="11" sm="8" md="10" lg="8" xl="9">
								<v-text-field label="Artiste, Titre" v-on:keyup.enter="research(search)" @click:append="research(search)" append-icon="mdi-magnify" placeholder="Artiste, Titre" dense solo v-model="search"></v-text-field>
								<v-row class="justify-end">
									<v-btn class="white--text mr-1" @click="advanced = !advanced" x-small text>Recherche avancée</v-btn>
								</v-row>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row v-else class="justify-center">
			<v-col cols="12">
				<v-row justify="center">
					<v-col cols="12">
						<v-text-field label="Artiste, Titre" append-icon="mdi-magnify" placeholder="Artiste, Titre" dense solo v-model="search" v-on:keyup.enter="research" @click:append="research"></v-text-field>
						<v-row class="justify-end mr-1">
							<v-btn class="white--text" @click="advancedSearch()" x-small text>Recherche avancée</v-btn>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-expand-transition v-if="page == 'loops' ">
			<v-form v-show="advanced">
				<v-container>
					<v-row class="mt-1" :class="lgMargin">
						<v-col offset-lg="3" offset-xl="4" cols="11" sm="8" md="11" lg="8" xl="7" class="white rounded-lg">
							<v-row class="justify-center">
								<v-col cols="12" md="11">
									<span class="h1 titre">Recherche avancée</span>
								</v-col>
							</v-row>
							<v-row class="justify-space-around">
								<v-col cols="12">
									<v-row class="justify-space-around">
										<v-col cols="12" md="5">
											<v-text-field dense label="Artiste, Titre" background-color="grey lighten-2" :height="formHeight" v-model="search" placeholder="Artiste, Titre" solo class="justify-center"></v-text-field>
										</v-col>
										<v-col cols="12" md="5">
											<v-select color="white" solo dense v-model="advGenre" background-color="grey lighten-2" :height="formHeight" :items="genres" label="Tous les genres" placeholder="Tous les genres"></v-select>
										</v-col>
									</v-row>
									<v-row class="justify-space-around">
										<v-col cols="12" md="5">
											<v-select color="white" solo dense v-model="advInstru" background-color="grey lighten-2" :height="formHeight" :items="instruments" label="Tous les instruments" placeholder="Tous les instruments"></v-select>
										</v-col>
										<v-col cols="12" md="5" class="d-flex flex-row justify-space-around">
											<v-row class="justify-space-around">
												<v-col cols="5" class="bpmMinLoops">
													<v-text-field solo type="number" background-color="grey lighten-2" dense label="BPM/Tempo minimum" v-model="advBPMmin" placeholder="BPM/Tempo minimum"></v-text-field>
												</v-col>
												<v-col cols="5" class="bpmMaxLoops">
													<v-text-field solo type="number" background-color="grey lighten-2" dense label="BPM/Tempo maximum" v-model="advBPMmax" placeholder="BPM/Tempo maximum"></v-text-field>
												</v-col>
											</v-row>
										</v-col>
									</v-row>
									<v-row class="justify-space-around">
										<v-col cols="12" md="5">
											<v-select color="white" solo dense background-color="grey lighten-2" :height="formHeight" :items="gammes" label="Toutes les gammes" placeholder="Toutes les gammes" v-model="advGamme"></v-select>
										</v-col>
										<v-col cols="5" class="text-center">
											<v-btn @click="research" dark>Rechercher</v-btn>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-container>
			</v-form>
		</v-expand-transition>
		<v-expand-transition v-else>
			<v-form v-show="advanced">
				<v-container>
					<v-row class="justify-center mt-10">
						<v-col cols="12" class="white rounded-lg">
							<v-row class="justify-center">
								<v-col cols="12" md="11">
									<span class="font-weight-black titre">Recherche avancée</span>
								</v-col>
							</v-row>
							<v-row class="justify-space-around">
								<v-col cols="12">
									<v-row class="justify-space-around">
										<v-col cols="12" md="5">
											<v-text-field dense label="Artiste, Titre" background-color="grey lighten-2" :height="formHeight" v-model="search" placeholder="Artiste, Titre" solo class="justify-center"></v-text-field>
										</v-col>
										<v-col cols="12" md="5">
											<v-select color="white" solo dense v-model="advGenre" background-color="grey lighten-2" :height="formHeight" :items="genres" label="Tous les genres" placeholder="Tous les genres"></v-select>
										</v-col>
									</v-row>
									<v-row class="justify-space-around">
										<v-col cols="12" md="5">
											<v-select
												color="white"
												solo
												dense
												v-model="advSort"
												:prepend-inner-icon="orderBy"
												@click:prepend-inner="ascending = !ascending"
												background-color="grey lighten-2"
												:height="formHeight"
												:items="order"
												label="Classer la recherche par.."
												placeholder="Classer la recherche par.."
											></v-select>
										</v-col>
										<v-col cols="12" md="5">
											<v-select color="white" solo dense background-color="grey lighten-2" :height="formHeight" :items="inspirations" label="Toutes les inspirations" placeholder="Toutes les inspiration" v-model="advMood"></v-select>
										</v-col>
									</v-row>
									<v-row class="justify-space-around">
										<v-col cols="12" md="5">
											<v-select color="white" solo dense background-color="grey lighten-2" :height="formHeight" :items="licences" label="Toutes les licences" placeholder="Toutes les licences" v-model="advLicence"></v-select>
										</v-col>
										<v-col cols="12" md="5" class="d-flex flex-row justify-space-around">
											<v-row class="justify-space-around">
												<v-col cols="5" class="bpmMin">
													<v-text-field solo type="number" background-color="grey lighten-2" dense label="BPM/Tempo minimum" v-model="advBPMmin" placeholder="BPM/Tempo minimum"></v-text-field>
												</v-col>
												<v-col cols="5" class="bpmMax">
													<v-text-field solo type="number" background-color="grey lighten-2" dense label="BPM/Tempo maximum" v-model="advBPMmax" placeholder="BPM/Tempo maximum"></v-text-field>
												</v-col>
											</v-row>
										</v-col>
									</v-row>
									<v-row class="justify-space-around">
										<v-col cols="12" md="5">
											<v-select color="white" solo dense background-color="grey lighten-2" :height="formHeight" :items="instruments" label="Tous les instruments" placeholder="Tous les instruments" v-model="advInstru"></v-select>
										</v-col>
										<v-col cols="12" md="5">
											<v-select color="white" solo dense background-color="grey lighten-2" :height="formHeight" :items="gammes" label="Toutes les gammes" placeholder="Toutes les gammes" v-model="advGamme"></v-select>
										</v-col>
										<v-col cols="5" class="text-center">
											<v-btn @click="research" dark>Rechercher</v-btn>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-container>
			</v-form>
		</v-expand-transition>
	</v-container>
</template>
<script>
import { db } from "../../firebase";
export default {
	props: {
		page: String,
	},
	computed: {
		backgroundColor() {
			if (this.page == "Loops") {
				return "grey darken-1";
			} else return "transparent";
		},
		lgMargin() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "justify-center";
				case "sm":
					return "justify-center";
				case "md":
					return "justify-center";
				case "lg":
					return "justify-end mr-2";
				case "xl":
					return "justify-end mr-7";
				default:
					return "";
			}
		},
		// Cette fonction filtre la liste des sons en fonction de l'input de l'utilisateur.
		// Il est aussi possible de filtrer par genre et licence
		// filteredSongs() {
		// 	return this.songItem
		// 		.filter(this.filterBy())
		// 		.sort(this.sortBy(this.advSort, this.ascending));
		// },
		orderedSongs() {
			switch (this.advSort) {
				case "":
					return this.songResults;
					break;

				case "Like":
					return this.songResults.sort((a, b) => {
						if (this.ascending) {
							return a.nbLikes - b.nbLikes;
						} else {
							return b.nbLikes - a.nbLikes;
						}
					});
					break;

				case "Écoutes":
					return this.songResults.sort((a, b) => {
						if (this.ascending) {
							return a.nbListens - b.nbListens;
						} else {
							return b.nbListens - a.nbListens;
						}
					});
					break;

				case "BPM":
					return this.songResults.sort((a, b) => {
						if (this.ascending) {
							return a.bpm - b.bpm;
						} else {
							return b.bpm - a.bpm;
						}
					});
					break;

				case "Date":
					return this.songResults.sort((a, b) => {
						if (this.ascending) {
							return a.createdAt.seconds - b.createdAt.seconds;
						} else {
							return b.createdAt.seconds - a.createdAt.seconds;
						}
					});
					break;
			}
		},
		orderBy() {
			if (this.ascending === true) {
				return "mdi-sort-ascending";
			} else return "mdi-sort-descending";
		},
		formHeight() {
			return "1em";
		},
	},
	/**
	 * Executes a firestore querry
	 *
	 * @param {Object} q - firestore querry
	 * @return {Array} list of object
	 */
	methods: {
		emitSongs() {
			console.log("emitted shit");
			const isEmpty =
				this.search == "" &&
				!this.hasQuerry &&
				this.advBPMmin == "" &&
				this.advBPMmax == "" &&
				this.advGenre == "" &&
				this.advGamme == "" &&
				this.advInstru == "" &&
				this.advMood == "" &&
				this.advLicence == "";

			console.log(this.songResults);
			console.log(this.orderedSongs);
			// emit la liste filtrée
			this.$emit("research", this.orderedSongs, isEmpty);
		},
		advancedSearch() {
			this.$emit("advanced");
			this.advanced = !this.advanced;
		},
		async checkQuerry() {
			await db
				.collection(this.page)
				.where("BPM", "<=", 150)
				.get()
				.then((snapshot) => {
					snapshot.forEach((loop) => {
						console.log(loop.data());
					});
				});
			console.log("hello");
		},
		/**
		 * Executes a firestore querry
		 *
		 * @param {Object} q - firestore querry
		 * @return {Array} list of object
		 */
		async executeQuerry(q) {
			const res = [];
			console.log("executing querry");
			await q.get().then(async (snapshot) => {
				snapshot.forEach((element) => {
					res.push(element.data());
				});
			});
			return Promise.all(res);
		},
		/**
		 * Concatenates the results of two querries in an array
		 *
		 * @param {Object} q1,q2 - firestore querries
		 * @return {Array} list of object
		 */
		async chainQuerryResults(q1, q2) {
			const resq1 = await this.executeQuerry(q1);
			const resq2 = await this.executeQuerry(q2);
			return this.intersection(resq1, resq2);
		},
		intersection(arr1, arr2) {
			let res = [];
			if (
				!(typeof arr1 !== "undefined" && arr1.length > 0) ||
				!(typeof arr2 !== "undefined" && arr2.length > 0)
			) {
				console.log("empty array in intersection");
				return res;
			} else {
				arr1.forEach((data) => {
					if (arr2.some((storedData) => storedData.songID === data.songID)) {
						res.push(data);
					}
				});
				return res;
			}
		},
		async research() {
			if (this.advGamme != "") {
				this.currentQuerry = this.currentQuerry.where(
					"key",
					"==",
					this.advGamme
				);
				this.hasQuerry = true;
			}
			if (this.advInstru != "") {
				this.currentQuerry = this.currentQuerry.where(
					"instrument",
					"==",
					this.advInstru
				);
				this.hasQuerry = true;
			}
			if (this.advGenre != "") {
				this.currentQuerry = this.currentQuerry.where(
					"genre",
					"==",
					this.advGenre
				);
				this.hasQuerry = true;
			}
			if (this.hasQuerry) {
				this.songResults = await this.executeQuerry(this.currentQuerry);
			}
			if (this.advBPMmax != "") {
				const res = await this.executeQuerry(
					db.collection(this.page).where("BPM", "<", parseInt(this.advBPMmax))
				);
				if (this.hasQuerry) {
					this.songResults = this.intersection(this.songResults, res);
				} else {
					this.songResults = res;
					this.hasQuerry = true;
				}
			}
			console.log("after max querry", this.songResults);
			if (this.advBPMmin != "") {
				const res = await this.executeQuerry(
					db.collection(this.page).where("BPM", ">", parseInt(this.advBPMmin))
				);
				if (this.hasQuerry) {
					this.songResults = this.intersection(this.songResults, res);
				} else {
					this.songResults = res;
					this.hasQuerry = true;
				}
			}
			if (this.search != "") {
				// Search for text in songs
				const titleResults = await this.executeQuerry(
					db
						.collection(this.page)
						.where("titleLC", ">=", this.search.toLowerCase())
						.where("titleLC", "<=", this.search.toLowerCase() + "\uf8ff")
				);
				//search for text in beatmakers
				const beatmakerResults = await this.executeQuerry(
					db
						.collection(this.page)
						.where("beatmakerLC", ">=", this.search.toLowerCase())
						.where("beatmakerLC", "<=", this.search.toLowerCase() + "\uf8ff")
				);
				const res = titleResults.concat(beatmakerResults);
				if (this.hasQuerry) {
					this.songResults = this.intersection(this.songResults, res);
				} else {
					this.songResults = res;
				}
			}
			const isEmpty =
				this.search == "" &&
				!this.hasQuerry &&
				this.advBPMmin == "" &&
				this.advBPMmax == "";
			// emit la liste filtrée
			this.$emit("research", this.songResults, isEmpty);
			//reset research parameters
			this.songResults = [];
			this.hasQuerry = false;
			this.currentQuerry = db.collection(this.page);
		},
		// Cette fonction filtre la liste des sons en fonction des inputs de la recherche avancée
		filterBy(prop) {
			if (this.advBPMmax != "") {
				return (item) =>
					item.title.toLowerCase().match(this.search.toLowerCase()) &&
					item.genre.toLowerCase().match(this.advGenre.toLowerCase()) &&
					item.licence.toLowerCase().match(this.advLicence.toLowerCase()) &&
					item.BPM >= this.advBPMmin &&
					item.BPM <= this.advBPMmax;
			} else {
				return (item) =>
					item.title.toLowerCase().match(this.search.toLowerCase()) &&
					item.genre.toLowerCase().match(this.advGenre.toLowerCase()) &&
					item.licence.toLowerCase().match(this.advLicence.toLowerCase()) &&
					item.BPM >= this.advBPMmin;
			}
		},
		// Cette fonction ordonne la liste des sons par ordre alphabétique ou par nombre (vues, like, bpm ..)
		sortBy(prop, order) {
			if (order === true) {
				if (prop === "Like" || prop === "BPM") {
					return (a, b) =>
						parseInt(a[this.advSort]) < parseInt(b[this.advSort]);
				} else {
					return (a, b) =>
						a[this.advSort.toLowerCase()] < b[this.advSort.toLowerCase()]
							? -1
							: 1;
				}
			} else {
				if (prop === "Like" || prop === "BPM") {
					return (a, b) =>
						parseInt(a[this.advSort]) > parseInt(b[this.advSort]);
				} else {
					return (a, b) =>
						a[this.advSort.toLowerCase()] > b[this.advSort.toLowerCase()]
							? -1
							: 1;
				}
			}
		},
	},
	watch: {
		// Permet de clear les v-model lors d'une selection par défaut
		advGenre(v) {
			if (v === "Tous les genres") {
				this.$nextTick(() => (this.advGenre = ""));
			}
		},
		advGamme(v) {
			if (v === "Toutes les gammes") {
				this.$nextTick(() => (this.advGamme = ""));
			}
		},
		advLicence(v) {
			if (v === "Toutes les licences") {
				this.$nextTick(() => (this.advLicence = ""));
			}
		},
		advMood(v) {
			if (v === "Toutes les inspirations") {
				this.$nextTick(() => (this.advMood = ""));
			}
		},
		advInstru(v) {
			if (v === "Tous les instruments") {
				this.$nextTick(() => (this.advInstru = ""));
			}
		},
		ascending() {
			this.emitSongs();
		},
		advSort(v) {
			if (v === "Classer la recherche par") {
				this.$nextTick(() => (this.advSort = ""));
			}
			this.$nextTick(() => this.emitSongs());
		},
	},
	data() {
		return {
			songResults: [],
			hasQuerry: false,
			currentQuerry: db.collection(this.page),
			advanced: false,
			ascending: true,
			advBPMmin: "",
			advBPMmax: "",
			search: "",
			advSort: "",
			advGenre: "",
			advLicence: "",
			advMood: "",
			advInstru: "",
			advGamme: "",
			inspirations: [
				"Toutes les inspirations",
				"Mélancolique",
				"Agressif",
				"Dansant",
				"Puissant",
				"Calme",
				"Amour",
				"Conscient",
				"Sombre",
				"Digital",
				"Old School",
				"Street",
				"Joyeux",
				"Planant",
				"Latin",
				"Afro",
				"Asiatique",
				"Oriental",
				"French Type Beat",
				"US Type Beat",
				"UK Type Beat",
			],
			genres: [
				"Tous les genres",
				"Rap",
				"Trap",
				"Drill",
				"Club",
				"Cloud",
				"Lofi",
				"Reggaeton",
				"R&B/Soul",
				"Dancehall",
				"Grime",
				"Funk",
				"2Step",
				"Pop",
				"Reggae",
				"Rai",
				"Electro",
				"Rock",
				"Autre",
			],
			gammes: [
				"Toutes les gammes",
				"A",
				"Am",
				"A#m",
				"AMaj",
				"A#Maj",
				"B",
				"Bm",
				"B#m",
				"BMaj",
				"B#Maj",
				"C",
				"Cm",
				"C#m",
				"CMaj",
				"C#Maj",
				"D",
				"Dm",
				"D#m",
				"DMaj",
				"D#Maj",
				"E",
				"Em",
				"E#m",
				"EMaj",
				"E#Maj",
				"F",
				"Fm",
				"F#m",
				"FMaj",
				"F#Maj",
				"G",
				"Gm",
				"G#m",
				"GMaj",
				"G#Maj",
			],
			instruments: [
				"Tous les instruments",
				"Drum",
				"Piano",
				"Guitare",
				"Violon",
				"Flute",
				"Synthé",
				"Vocal",
				"Saxo",
				"Tambour",
				"Trompette",
			],
			licences: ["Toutes les licences", "free", "semifree", "forbidden"],
			order: ["Classer la recherche par", "Like", "Écoutes", "BPM", "Date"],
		};
	},
};
</script>
<style>
.bpmMinLoops {
	transform: translate(-12px, 0);
}
.bpmMaxLoops {
	transform: translate(12px, 0);
}
.titre {
	font-size: 150%;
}
.bpmMin {
	transform: translate(-16px, 0);
}
.bpmMax {
	transform: translate(16px, 0);
}
.padSearch {
	padding-top: 5em;
	transition: all 0.2s ease-in;
}
</style>