<template>
  <div class="PopupItem-wrapper">
    <div class="overlay" @click="$router.push('/')"></div>
    <div class="PopupItem">
      <!-- you can use v-bind to pass down all props -->
      <component :isActive="activate" @changes="newSonginfo()" :song="song" :is="component" v-bind="$attrs" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupItem',
  props: ['component'],
  created() {
    this.active = true;
  },
  computed: {
    activate() {
      if (this.active === false) {
        return undefined;
      } else return true;
    },
  },
  methods: {
    // Actualise les données du sons avec les nouvelles données
    newSongInfo(item) {
      this.song = item;
    },
  },
  data() {
    return {
      active: false,
      song: {
        id: 123445,
        imgPath: 'https://ukf.com/wp-content/uploads/2015/04/pylot.jpg',
        title: 'Emotions',
        type: 'instru',
        beatmaker: 'Green',
        beatmakerAvatar:
          'https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af',
        BPM: 120,
        nbListens: 15,
        nbDownload: 3,
        likes: 1,
        samples: true,
        genre: 'Drill',
        key: 'A Maj',
        collab: ['Kaowbeat', 'Wojack', 'Pylot'],
        mood: ['Triste', 'Calme'],
        Instrument: 'Piano',
        licence: 'Non-commerciale',
        isPlaying: false,
        beatmakerPath: 'http://iampylot.com/',
        beatmakerMail: 'example@gmail.com',
        downloadLinks: {
          mp3: '',
          wav: '',
          tagged: '',
        },
        comments: [],
        isSubmission: true,
        submission: {
          comments: [
            {
              author: 'Toto77',
              avatar:
                'https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af',
              text: 'Trop bien ton son',
            },
            {
              author: 'BeatCritic9000',
              avatar:
                'https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af',
              text: "Mouais j'ai entendu mieux",
            },
            {
              author: 'MecPaumé99',
              avatar:
                'https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af',
              text: "Je sais pas ce que je fais ici :/ Du coup je vais laisser un commentaire super long pour qu'on puisse voir ce que ça donne. Apparement c'est toujours pas assez long. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi non quis exercitationem culpa nesciunt nihil aut nostrum explicabo reprehenderit optio amet ab temporibus asperiores quasi cupiditate. Voluptatum ducimus voluptates voluptas?",
            },
          ],
          status: 'pending',
          channel: 'InstruRap',
          lastUpdate: '2021-08-21T13:56:00Z',
          submissionNumber: 10,
        },
      },
    };
  },
};
</script>

<style scoped>
.PopupItem-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.PopupItem {
  position: absolute;
  z-index: 1;
  margin: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 5em;
  box-shadow: 0 0 1em #00000033;
}

.overlay {
  z-index: 1;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #00000055;
}
</style>
