<template>
	<v-container fluid class="grey darken-1">
		<v-row class="justify-center">
			<p v-if="this.songCards.length == 0" class="mb-0 mt-4 ml-8">
				<span class="grey--text text--lighten-2 h1 font-weight-bold head">Aucun Résultat</span>
			</p>
			<v-col class="grey darken-4 py-2 px-4 mx-4 my-5 rounded mt-1" cols="11" sm="5" md="5" lg="3" v-for="song in songCards.slice(page*12,(page+1)*12)" :key="song.id">
				<v-row class="px-1 py-0">
					<v-col cols="4" sm="3" class="px-0">
						<v-row dense class="py-1 px-1">
							<v-img v-if="song.imagepath != null" :src="song.imagePath" height="100%" class="align-end rounded-lg" />
							<v-img v-else :src="'https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ffavicon.png?alt=media&token=e2e96a37-5474-4fc0-abc0-04ffd333d18c'" height="100%" class="align-end rounded-lg" />
						</v-row>
					</v-col>
					<v-col cols="8" sm="9">
						<v-row dense class="fill-height mt-1 align-content-space-between">
							<v-col cols="11" sm="10">
								<v-row class="mb-0">
									<p class="my-0 ml-2 mt-2 songtitle">
										<span class="mr-1 white--text text-uppercase">{{song.title}}</span>
										<span class="grey--text">{{song.type}}</span>
										<br />
										<span @click="routeTo(song.beatmaker,'user')" class="mr-1 white--text text-uppercase pointer">{{song.beatmaker}}</span>
									</p>
								</v-row>
							</v-col>
							<v-spacer></v-spacer>
							<v-col cols="1" sm="2">
								<v-row class="justify-end">
									<v-btn @click="routeTo(song.id,'SamplePopup')" small icon class="mt-1">
										<v-icon color="white">mdi-information-outline</v-icon>
									</v-btn>
								</v-row>
							</v-col>

							<v-col cols="4" class="py-0">
								<v-btn x-small icon @click.stop.prevent="like(song.id), song.likes++" v-if="user.liked.indexOf(song.id) === -1">
									<v-icon class="white--text">mdi-thumb-up</v-icon>
									<span class="mr-3 mt-1 white--text">{{ song.likes }}</span>
								</v-btn>
								<v-btn x-small icon @click.stop.prevent=" removeLike(song.id), song.likes--" v-else>
									<v-icon class="white--text" color="green">mdi-thumb-up</v-icon>
									<span class="mr-3 mt-1 white--text caption">{{song.likes}}</span>
								</v-btn>
							</v-col>
							<v-spacer></v-spacer>
							<v-col cols="8">
								<v-row class="justify-end">
									<p class="mb-1 mx-2 px-3 py-1 rounded green">
										<span class="free font-weight-bold white--text">{{song.price}}</span>
									</p>
									<v-btn @click="downloadSong(song.wav,song.title)" class="mt-2" small icon>
										<v-icon color="white">mdi-download</v-icon>
									</v-btn>
								</v-row>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12">
				<v-row class="justify-center pb-7">
					<v-col cols="12" sm="11" lg="10">
						<v-row dense v-if="this.songCards.length > 12 " :class="justifyPage">
							<v-btn v-if="this.page>0" small dark @click="prevPage" class="mt-3 h1" :class="leftBtnMargin">
								<!-- eslint-disable-next-line vue/no-parsing-error -->
								<span class="grey--text text--lighten-2 next">< Page Précédente</span>
							</v-btn>
							<v-btn v-if="page < numPageMax" small text @click="nextPage" class="mt-3 h1" :class="rightBtnMargin">
								<span class="grey--text text--lighten-2 next">Page Suivante ></span>
							</v-btn>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
// TODO : link data

export default {
	props: {
		songCards: Array,
	},
	data: () => ({
		user: {
			id: ["id"],
			liked: ["657417"],
		},
		page: 0,
		// toggle le lecteur
		audioPlayer: false,
	}),
	computed: {
		leftBtnMargin() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "ml-2";
				case "sm":
					return "ml-2";
				case "md":
					return "ml-5";
				case "lg":
					return "ml-5";
				case "xl":
					return "ml-10";
				default:
					return "";
			}
		},
		rightBtnMargin() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "mr-1";
				case "sm":
					return "";
				case "md":
					return "mr-1";
				case "lg":
					return "mr-1";
				case "xl":
					return "mr-6";
				default:
					return "";
			}
		},
		numPageMax() {
			if (this.songCards.length % 12 == 0) {
				return Math.trunc(this.songCards.length / 12) - 1;
			} else {
				return Math.trunc(this.songCards.length / 12);
			}
		},
		justifyPage() {
			switch (this.page) {
				case 0:
					return "justify-end";
				case 1:
					return "justify-space-between";
				default:
					return "justify-space-between";
			}
		},
	},
	methods: {
		async downloadSong(uri, name) {
			var link = document.createElement("a");
			link.download = name;
			link.href = uri;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
		routeTo(link, type) {
			this.$router.push({ name: type, params: { id: link } });
		},
		nextPage() {
			this.page = this.page + 1;
		},
		prevPage() {
			this.page = this.page - 1;
		},
		//   Permet de liker le son
		like(song) {
			this.user.liked.push(song);
		},
		// Permet de retirer le like d'un son
		removeLike(song) {
			this.user.liked.splice(this.user.liked.indexOf(song), 1);
		},
		// Redirige sur la page du son
		info() {
			return console.log("marche");
		},
	},
};
</script>
<style>
.songtitle {
	font-size: 0.6vw;
}
.free {
	user-select: none;
}
@media (max-width: 600px) {
	.songtitle {
		font-size: 2.5vw;
	}
}
@media (min-width: 600px) {
	.songtitle {
		font-size: 1.1vw;
	}
	.next {
		font-size: 150%;
	}
}
@media (min-width: 960px) {
	.songtitle {
		font-size: 0.85vw;
	}
}
@media (min-width: 1264px) {
	.songtitle {
		font-size: 0.6vw;
	}
}
</style>