<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12" class="white">
				<v-row class="text-center">
					<v-col cols="12">
						<span class="h1">Utilisateurs en ligne</span>
					</v-col>
					<v-col cols="4" v-for="item in beatmaker" :key="item.id">
						<v-btn icon :href="item.name">
							<v-badge bordered bottom color="green" dot offset-x="10" offset-y="10">
								<v-responsive>
									<v-avatar @click="routeTo(item.name,'user')" :href="item.route">
										<v-img :src="item.avatar"></v-img>
									</v-avatar>
								</v-responsive>
							</v-badge>
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
export default {
	methods: {
		routeTo(link, type) {
			this.$router.push({ name: type, params: { id: link } });
		},
	},
	data() {
		return {
			beatmaker: [
				{
					id: 0,
					name: 'Green Wojack',
					avatar:
						'https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af',
					isOnline: true,
				},
				{
					id: 1,
					name: 'Red Wojack',
					avatar:
						'https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af',
					isOnline: true,
				},
				{
					id: 2,
					name: 'Black Wojack',
					avatar:
						'https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af',
					isOnline: true,
				},
				{
					id: 3,
					name: 'Other Wojack',
					avatar:
						'https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af',
					isOnline: true,
				},
				{
					id: 4,
					name: 'Green Dude',
					avatar:
						'https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_profil_d%C3%A9faut.svg?alt=media&token=db58367a-95d0-4cd3-b6eb-43d8ecd4b6af',
					isOnline: true,
				},
			],
		};
	},
};
</script>
<style>
</style>