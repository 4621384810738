<template lang="">
    <v-row class="px-3 pt-2">
        <v-col cols=1 class="px-2">
            <v-avatar :size="imgSize">
                <v-img :src="user.imgPath"></v-img>
            </v-avatar>
        </v-col>
        <v-col cols=11>
            <v-row>
                <v-col cols=10 offset="1" offset-sm="0" class="pb-0 ">
                    <span>{{user.name}}</span> <br>
                    <span>@</span><span>{{user.altName}}</span>
                </v-col>
                <v-col cols="11" offset=1 offset-sm=0 sm=12 class="pb-0">
                    <v-textarea :label="textLabel" counter="1000" rows="3" outlined dense></v-textarea>
                </v-col>
                <v-col offset="9" offset-sm="11" cols="1" class="pt-0">
                    <v-btn @click="publishPost()" icon color="#1f2d41" class="ml-4">
                        <v-icon x-large >mdi-check-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
export default {
    computed:{
        textLabel(){
            return "Exprimez-vous, " + this.user.name
        }
    },
    computed:{
        imgSize(){
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                return "3em";
                case "sm":
                return "3em";
                case "md":
                return "4em";
                case "lg":
                return "4em";
                case "xl":
                return "4em";
                default:
                return "3em";
            }
        }
    },
    methods: {
        publishPost(){
            // Todo : poster un message 
        },
    },
    props:{
        user:Object,
    }
}
</script>
<style lang="">
    
</style>