<template>
<v-row>
  <v-col cols="12">
    <v-autocomplete
      v-model="select"
      :loading="loading"
      :items="beatmaker"
      item-text="name"
      :search-input.sync="search"
      cache-items
      class="mx-4 white rounded-lg"
      flat
      append-icon="mdi-magnify"
      @click:append="rechercher"
      v-on:keyup.enter="rechercher"
      hide-no-data
      hide-details
      label="Rechercher un beatmaker"
      solo
    >
    </v-autocomplete>
  </v-col>
</v-row>
</template>
<script>
  export default {
    props:{
      beatmaker:Array,
    },
    data () {
      return {
        loading: false,
        items: {},
        search: null,
        select: null,
      }
    },
    methods: {
      rechercher(){
       this.$router.push({ name: 'user', params: { id: this.select } })
      }
    },
  }
</script>
