<template>
<v-container fluid>
  <v-row class="justify-center">
    <v-col cols="12" class="py-3 mb-4">
    <span class="h1 style ml-1">Nouveaux Beatmakers</span>
    </v-col>
  </v-row>
  <v-row class="mt-1" justify="center">
    <vue-horizontal class="carousel">
      <template v-slot:btn-prev>
                <v-btn icon large class="justify-end mb-16 d-none d-md-block" :class="prevBtn">
                    <v-icon>mdi-less-than</v-icon>
                </v-btn>
        	</template>
			<template v-slot:btn-next>
                <v-btn icon large class="justify-end mb-16 d-none d-md-block" :class="nextBtn">
                    <v-icon>mdi-greater-than</v-icon>
                </v-btn>
      </template>
      <section
        v-for="item in beatmakerCard"
        :key="item.name"
        class="horizontal"
      >
        <div>
          <v-list class="mt-5">
            <v-list-item class="justify-center">
              <v-btn target="_blank" @click="linkToUser(item.name)" class="x-large" fab>
                <v-avatar :width="imageSize" :height="imageSize">
                  <v-img lazy :src="item.avatar"></v-img>
                </v-avatar>
              </v-btn>
            </v-list-item>
            <v-list-item :class="spacing" class="justify-center">
              <v-btn :width="buttonSize" @click="linkToUser(item.name)" dark x-small>
                <p :style="nameSize" class="mt-4 font-weight-bold">
                  {{ item.name }}
                </p>
              </v-btn>
            </v-list-item>
          </v-list>
        </div>
      </section>
    </vue-horizontal>
  </v-row>
</v-container>
</template>

<script>
// TODO : link avec db firebase
import VueHorizontal from "vue-horizontal";

export default {
  components: { VueHorizontal },
  methods: {
    linkToUser(link){
            this.$router.push({ name: 'user', params: { id: link } })
        },
  },
  computed: {
      nextBtn(){
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return ''
				case 'sm':
					return 'ml-2'
				case 'md':
					return 'ml-8'
				default:
					return 'ml-8'
			}
		},
		prevBtn(){
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 'mr-8'
				case 'sm':
					return 'mr-8'
				case 'md':
					return 'mr-6'
				default:
					return 'mr-6'
			}
		},
    spacing() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "mt-0.5";
        case "sm":
          return "mt-2";
        case "md":
          return "mt-3";
        case "lg":
          return "mt-3";
        case "xl":
          return "mt-4";
        default:
          return "mt-4";
      }
    },
    // Gère la taille de l'image
    imageSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "5em";
        case "sm":
          return "6em";
        case "md":
          return "7em";
        case "lg":
          return "7em";
        case "xl":
          return "8em";
        default:
          return "6em";
      }
    },
    // Gère la taille du bouton
    buttonSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "6em";
        case "sm":
          return "8em";
        case "md":
          return "9em";
        case "lg":
          return "10em";
        case "xl":
          return "12em";
        default:
          return "10em";
      }
    },
    // Gère la taille du texte
    nameSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size : 55%";
        case "sm":
          return "font-size : 70%";
        case "md":
          return "font-size : 80%";
        case "lg":
          return "font-size : 85%";
        case "xl":
          return "font-size : 100%";
        default:
          return "font-size : 100%";
      }
    },
    // Cette fonction varie la taille de l'image en fonction de la taille de l'écran
    image() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 90;
        case "sm":
          return 110;
        case "md":
          return 150;
        case "lg":
          return 150;
        case "xl":
          return 150;
        default:
          return 150;
      }
    },

    // Cette fonction varie la taille de la card en fonction de la taille de l'écran
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 110;
        case "sm":
          return 130;
        case "md":
          return 150;
        case "lg":
          return 150;
        case "xl":
          return 150;
        default:
          return 150;
      }
    },
  },
  props:{
    beatmaker:Array,
  },
  created() {
        this.beatmakerCard = this.beatmaker.map(({ name, avatar }) => ({ name: name, avatar: avatar}));
    },
  data() {
    return {
      beatmakerCard: [
        {
          name:String,
          avatar: String,
        },
      ],
    };
  },
};
</script>

<style scoped>
  .carousel {
    width: 100%;
  }
@media (max-width: 600px) {
  section {
    width: calc((100% - (10px)) / 3);
  }
  .textSize {
    font-size: 60%;
  }
}
@media (min-width: 600px) {
  section {
    width: calc((100% - (1px)) / 5);
  }
  .textSize {
    font-size: 70%;
  }
}
@media (min-width: 960px) {
  section {
    width: calc((100% - (2 * 2px)) / 6);
  }
  .textSize {
    font-size: 75%;
  }
}
@media (min-width: 1264px) {
  section {
    width: calc((100% - (3 * 2px)) / 7);
  }
  .textSize {
    font-size: 80%;
  }
}
@media (min-width: 1904px) {
  section {
    width: calc(100% / 8);
  }
  .textSize {
    font-size: 90%;
  }
}
.v-card {
  transition: opacity 0.4s ease-in-out;
}
.card:hover {
  transition: all 500ms;
  transform: scale(1.05);
}
.text {
  font-size: 90%;
}
</style>

