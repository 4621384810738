<template>
  <v-dialog persistent v-model="active" :max-width="popupWidth">
    <template v-slot:activator="{ on , attrs }">
      <slot name="button" v-bind="{ on, attrs }"></slot>
    </template>
    <v-card dark class="details-card">
      <v-icon fixed right class="ma-1 close-dialog" x-large dark @click="closeDialog()">mdi-close</v-icon>
      <v-row :style="headerStyle" class="ma-0 align-center">
        <v-col cols="5" lg="3" xl="2" class="my-auto ml-3" v-if="$vuetify.breakpoint.smAndUp">
          <v-img class="rounded" :src="songItem.imgPath"></v-img>
        </v-col>
        <v-col class="pt-0">
          <v-card-title>
            <h2>{{ songItem.title }}</h2>
          </v-card-title>
          <v-card-subtitle class="beatmaker pb-0">
            <!-- //TODO: Provide link to beatmaker profile page -->
            <router-link :to="{ name: 'user', params: { id: songItem.beatmaker } }">{{ songItem.beatmaker }}</router-link>
            <span v-if="song.collab.length>0" style="font-size:105%">ft</span>
            <router-link style="font-size:105%" v-for="(item,index) in songItem.collab" :key="index" :to="{ name: 'user', params: { id: item } }">{{ item }}</router-link>
          </v-card-subtitle>
          <v-chip color="black" class="ma-1 rounded-lg">
            <v-icon small class="mr-1">mdi-album</v-icon>
            {{ songItem.genre }}
          </v-chip>
          <v-chip v-if="song.mood.length>0" color="black" class="ma-1 rounded-lg">
            <v-icon small class="mr-1">mdi-album</v-icon>
            <span class="mr-1" v-for="(item,index) in songItem.mood" :key="index">{{item}}</span>
          </v-chip>
          <v-chip color="black" class="ma-1 rounded-lg">
            <v-icon small class="mr-1">mdi-file-document-edit-outline</v-icon>
            {{ `Licence ${songItem.licence}` }}
          </v-chip>
          <br />
          <v-chip color="black" class="ma-1 rounded-lg">
            <v-img contain width="20" class="mr-1 mb-1" src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficons8-drums-64.png?alt=media&token=ea993af6-d831-48fd-aa0a-62d8daad6f56"></v-img>
            {{ `${songItem.BPM} BPM` }}
          </v-chip>
          <v-chip color="black" class="ma-1 rounded-lg">
            <v-img contain width="20" class="mr-1 mb-1" src="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficons8-drums-64.png?alt=media&token=ea993af6-d831-48fd-aa0a-62d8daad6f56"></v-img>
            {{songItem.key}}
          </v-chip>
          <br />
          <v-chip color="black" class="ma-1 rounded-lg">
            <v-icon small class="mr-1">mdi-play-circle-outline</v-icon>
            {{songItem.nbListens}}
          </v-chip>
          <v-chip color="black" class="ma-1 rounded-lg">
            <v-icon small class="mr-1">mdi-download-circle-outline</v-icon>
            {{songItem.nbDownload}}
          </v-chip>
          <!-- <v-chip color="black" class="ma-1 rounded-lg"><v-icon small class="mr-1">mdi-thumb-up-outline</v-icon>{{song.nbListens}}</v-chip> -->
          <v-row v-if="$vuetify.breakpoint.lgAndUp" dense class="my-auto px-2 align-center">
            <v-chip color="black" class="ma-1 rounded-lg">
              <v-icon small class="mr-1" color="green">mdi-thumb-up</v-icon>
              {{songItem.nbListens}}
            </v-chip>
            <v-btn small color="white" class="ma-1 px-1 py-4 rounded-pill black--text font-weight-bold">
              <span class="pl-1" style="font-size:150%">Play</span>
              <v-icon large color="black">mdi-play</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn small class="ml-1 my-1" light>
              <v-icon small class="mr-1">mdi-link-variant</v-icon>Obtenir le lien
            </v-btn>
          </v-row>
          <v-chip v-if="$vuetify.breakpoint.mdAndDown" color="black" class="ma-1 rounded-lg">
            <v-icon small class="mr-1" color="green">mdi-thumb-up</v-icon>
            {{songItem.nbListens}}
          </v-chip>
          <v-btn v-if="$vuetify.breakpoint.mdAndDown" small color="white" class="ma-1 px-1 py-4 rounded-pill black--text font-weight-bold">
            <span class="pl-1" style="font-size:150%">Play</span>
            <v-icon large color="black">mdi-play</v-icon>
          </v-btn>
          <v-row v-if="$vuetify.breakpoint.mdAndDown" dense class="my-auto px-2 align-center">
            <v-btn x-small class="ml-1 my-1" light>
              <v-icon small class="mr-1">mdi-link-variant</v-icon>Obtenir le lien
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-tabs light background-color="grey lighten-3" v-model="selectedTab">
        <v-tab v-for="tab in tabs" :key="tab">{{ tab }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedTab">
        <v-card class="grey lighten-3" v-if="selectedTab === 0">
          <v-row class="justify-center py-6 mx-0">
            <v-col cols="11">
              <v-row>
                <v-col v-if="songItem.isSubmission===true" cols="12" sm="7" xl="8">
                  <v-row dense>
                    <v-icon small>mdi-checkbox-blank-circle</v-icon>
                    <v-divider class="px-0 mx-0 mt-2 black"></v-divider>
                    <v-icon small>mdi-checkbox-blank-circle</v-icon>
                    <v-divider class="px-0 mx-0 mt-2 black"></v-divider>
                    <v-icon small>mdi-checkbox-blank-circle-outline</v-icon>
                  </v-row>
                  <v-row dense>
                    <v-col class="pt-0">
                      <p class="chronoText">Demande envoyée</p>
                    </v-col>
                    <v-col class="pt-0">
                      <p class="text-center chronoText">Traitement en cours</p>
                    </v-col>
                    <v-col class="pt-0">
                      <p class="text-end chronoText">Demande traitée</p>
                    </v-col>
                  </v-row>
                  <v-row class="rounded white">
                    <v-col cols="5" sm="3" lg="2" class="pr-0">
                      <v-row class="justify-center">
                        <v-col cols="10" sm="8" lg="7" class="px-0">
                          <v-img class="mb-1 ml-2" width="75%" :src="validatedLogo"></v-img>
                          <span class="white--text green rounded px-2 py-1" style="font-size:80%">Validée</span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="px-0 my-auto">
                      <span>Votre Beat a été validé par un opérateur de validation. Il apparaîtra prochainement sur notre chaîne YouTube.</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="5" xl="4">
                  <v-row dense class="justify-end">
                    <v-col cols="12" md="10" class="rounded-lg px-0 my-1">
                      <!--TODO: ADD DOWNLOAD -->
                      <v-btn large width="100%" class="grey">
                        <v-row v-if="$vuetify.breakpoint.lgAndUp" class="justify-center align-center">
                          <span class="black--text font-weight-bold text-capitalize" style="font-size:180%">Télécharger</span>
                          <v-icon class="mt-1 ml-1" large color="black">mdi-download</v-icon>
                          <v-chip class="white black--text font-weight-bold" style="font-size:110%">MP3</v-chip>
                        </v-row>
                        <v-row v-else class="justify-center align-center">
                          <span class="black--text font-weight-bold text-capitalize" style="font-size:120%">Télécharger</span>
                          <v-icon class="mt-1 ml-1" color="black">mdi-download</v-icon>
                          <v-chip small class="white black--text font-weight-bold" style="font-size:110%">MP3</v-chip>
                        </v-row>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row dense class="justify-end my-0">
                    <v-col cols="12" md="10" class="rounded-lg px-0 my-1">
                      <!--TODO: ADD DOWNLOAD -->
                      <v-btn large width="100%" class="grey">
                        <v-row v-if="$vuetify.breakpoint.lgAndUp" class="justify-center align-center">
                          <span class="black--text font-weight-bold text-capitalize" style="font-size:180%">Télécharger</span>
                          <v-icon class="mt-1 ml-1" large color="black">mdi-download</v-icon>
                          <v-chip class="red black--text font-weight-bold" style="font-size:110%">WAV</v-chip>
                        </v-row>
                        <v-row v-else class="justify-center align-center">
                          <span class="black--text font-weight-bold text-capitalize" style="font-size:120%">Télécharger</span>
                          <v-icon class="mt-1 ml-1" color="black">mdi-download</v-icon>
                          <v-chip small class="red black--text font-weight-bold" style="font-size:110%">WAV</v-chip>
                        </v-row>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row dense class="justify-end my-0">
                    <v-col cols="12" md="10" class="rounded-lg px-0 my-1">
                      <!--TODO: ADD DOWNLOAD -->
                      <v-btn large width="100%" class="red">
                        <v-row v-if="$vuetify.breakpoint.lgAndUp" class="justify-center align-center">
                          <span class="font-weight-bold text-capitalize white--text" style="font-size:180%">Supprimer</span>
                          <v-icon class="mt-1 ml-1" color="white" large>mdi-delete</v-icon>
                        </v-row>
                        <v-row v-else class="justify-center align-center">
                          <span class="font-weight-bold text-capitalize white--text" style="font-size:120%">Supprimer</span>
                          <v-icon class="mt-1 ml-1" color="white">mdi-delete</v-icon>
                        </v-row>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="justify-end">
                <p class="mb-0 px-2 pt-2 white rounded-t-xl">
                  <span style="font-size:130%">Modifications</span>
                  <v-icon>mdi-square-edit-outline</v-icon>
                </p>
              </v-row>
              <validation-observer v-slot="{invalid}" class="mx-0">
                <v-row class="justify-center white rounded-l-xl rounded-br-xl">
                  <v-col cols="12" md="8">
                    <v-row dense class="align-center">
                      <v-col cols="10" md="5" lg="4">
                        <validation-provider class="mx-0" rules="titleMin:3|titleMax:25" v-slot="{errors}">
                          <span>Titre</span>
                          <v-text-field :error-messages="errors" outlined dense class="rounded-0 inputDetail" append-icon="mdi-sync" @click:append="replaceTitle" v-model="newTitle" @keydown.enter="replaceTitle" label="Modifier le titre"></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="auto">
                        <v-chip dark class="tags">{{songItem.title}}</v-chip>
                      </v-col>
                    </v-row>
                    <v-row dense class="align-center">
                      <v-col cols="10" md="5">
                        <span>Collaborateur(s)</span>
                        <v-combobox
                          v-model="newCollab"
                          :items="accounts"
                          :search-input.sync="search"
                          hide-selected
                          class="rounded-0 inputDetail"
                          label="Ajouter un collaborateur"
                          outlined
                          dense
                          chips
                          append-icon="mdi-plus"
                          @click:append="addTo('collab',newCollab)"
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Aucun beatmaker trouvé pour "
                                  <strong>{{ search }}</strong>". Appuyer sur
                                  <kbd>entrée</kbd> pour le créer.
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-combobox>
                      </v-col>
                      <v-col>
                        <v-chip dark @click:close="removeFrom('collab',item)" v-for="(item,index) in songItem.collab" :key="index" close class="tags">{{item}}</v-chip>
                      </v-col>
                    </v-row>
                    <v-row dense class="align-center">
                      <v-col cols="10" md="5">
                        <span>Genre</span>
                        <v-select :items="genre" v-model="newGenre" append-icon="mdi-sync" @click:append="addTo('genre',newGenre)" @keydown.enter="addTo('genre',newGenre)" outlined dense class="rounded-0 inputDetail" label="Modifier le genre"></v-select>
                      </v-col>
                      <v-col cols="3" md="auto">
                        <v-chip dark class="tags">{{songItem.genre}}</v-chip>
                      </v-col>
                    </v-row>
                    <v-row dense class="align-center">
                      <v-col cols="10" md="6" lg="5">
                        <span class="text-uppercase">é</span>
                        <span class="ml-0">motions et Inspirations</span>
                        <v-select :items="mood" v-on:input="limiter" append-icon="mdi-plus" v-model="newMood" @click:append="addTo('mood',newMood)" outlined dense class="rounded-0 inputDetail" label="Ajouter un mood"></v-select>
                      </v-col>
                      <v-col>
                        <v-chip dark @click:close="removeFrom('mood',item)" v-for="(item,index) in songItem.mood" :key="index" close class="tags">{{item}}</v-chip>
                      </v-col>
                    </v-row>
                    <v-row dense class="align-center">
                      <v-col cols="12" class="pb-0">
                        <span>BPM & Gamme</span>
                      </v-col>
                      <v-col cols="8" md="4" lg="3" class="pt-0 mb-3">
                        <validation-provider class="mx-0" rules="min_value:60" v-slot="{errors}">
                          <v-text-field :error-messages="errors" append-icon="mdi-sync" @click:append="replaceBPM" v-model="newBPM" @keydown.enter="replaceBPM" outlined dense class="rounded-0 inputDetail" label="BPM"></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="4" md="2" xl="1">
                        <v-chip class="mb-1" dark>{{songItem.BPM}}</v-chip>
                      </v-col>
                      <v-col cols="8" md="4" lg="3" class="pt-0 mb-3">
                        <v-select :items="gamme" append-icon="mdi-sync" v-model="newGamme" @click:append="addTo('gamme',newGamme)" outlined dense class="rounded-0 inputDetail" label="Gamme"></v-select>
                      </v-col>
                      <v-col>
                        <v-chip class="mb-1" dark>{{songItem.key}}</v-chip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="11" md="3">
                    <v-row>
                      <v-col cols="12" class="px-0">
                        <span>Pochette de couverture</span>
                      </v-col>
                    </v-row>
                    <v-row class="justify-center rounded-lg elevation-5 imageRow">
                      <v-col cols="7" class="pb-0">
                        <v-img :src="songItem.imgPath"></v-img>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-10">
                        <validation-provider rules="ext:png,jpg|size:2000" v-slot="{errors}">
                          <v-file-input label="Modifier l'image (png,jpg)" :error-messages="errors" accept="image/*" v-model="imgData" class="inputDetail" outlined dense></v-file-input>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row class="mt-10">
                      <v-col class="text-end">
                        <v-btn @click="validate" v-if="invalid===false" color="success">Valider</v-btn>
                        <v-btn v-else disabled color="success">Valider</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </validation-observer>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-else>
          <v-text-field v-model="newComment" label="Écrire un commentaire..." solo filled outlined clearable @keydown.enter="submitComment" class="mx-4 mt-2"></v-text-field>
          <v-list three-line>
            <v-list-item v-for="(comment, index) in songItem.submission.comments" :key="index">
              <v-list-item-avatar>
                <!-- //TODO: Provide link to beatmaker profile page -->
                <router-link to="beatmaker" class="commenterLink">
                  <v-list-item-avatar>
                    <v-img :src="comment.avatar"></v-img>
                  </v-list-item-avatar>
                </router-link>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <!-- //TODO: Provide link to beatmaker profile page -->
                  <router-link to="beatmaker" class="commenterLink black--text">
                    <h4>{{ comment.author }}</h4>
                  </router-link>
                </v-list-item-title>
                {{ comment.text }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <p class="mx-4" v-if="!songItem.submission.comments.length">Il n'y a pas (encore) de commentaires pour ce son.</p>
        </v-card>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { max, min_value, min, size, ext } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
extend('titleMax', {
  ...max,
  message: 'Le titre ne doit pas dépasser 25 caractères',
});
extend('size', {
  ...size,
  message: "L'image ne doit pas dépasser 2 MB",
});
extend('ext', {
  ...ext,
  message: 'Format non accepté',
});
extend('titleMin', {
  ...min,
  message: 'Le titre doit dépasser 3 caractères',
});
extend('min_value', {
  ...min_value,
  message: 'Le BPM doit être supérieur à 60',
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    song: {
      type: Object,
      required: true,
    },
    isActive: Boolean,
  },
  mounted() {
    this.songItem = this.song;
    if (this.isActive === true) {
      this.active = true;
    }
  },
  data() {
    return {
      active: false,
      validatedLogo:
        'https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Images%2Ficone_valid%C3%A9_instrurap.svg?alt=media&token=d2bc960c-80a9-4188-9855-7b9236596537',
      tabs: ['Gestion demande', 'commentaires'],
      newTitle: '',
      imgData: null,

      newBPM: '',
      newCollab: '',
      newGenre: '',
      newMood: '',
      newGamme: '',
      songItem: {},
      selectedTab: 0,
      newComment: '',
      submissionComment: '',
      accounts: [
        'Kaowbeat',
        'GreenWojack',
        'Pylot',
        'Machin',
        'Stuff',
        'Neobeats',
        'RedWojack',
        'Pilote',
        'Truc',
      ],
      search: null,
      mood: [
        'Mélancolique',
        'Agressif',
        'Dansant',
        'Puissant',
        'Calme',
        'Amour',
        'Conscient',
        'Sombre',
        'Digital',
        'Old School',
        'Street',
        'Joyeux',
        'Planant',
        'Latin',
        'Afro',
        'Asiatique',
        'Oriental',
        'French Type Beat',
        'US Type Beat',
        'UK Type Beat',
      ],
      genre: [
        'Tous les genres',
        'Rap',
        'Trap',
        'Drill',
        'Club',
        'Cloud',
        'Lofi',
        'Reggaeton',
        'R&B/Soul',
        'Dancehall',
        'Grime',
        'Funk',
        '2Step',
        'Pop',
        'Reggae',
        'Rai',
        'Electro',
        'Rock',
        'Autre',
      ],
      gamme: [
        'Am',
        'A#m',
        'A Maj',
        'A# Maj',
        'Bm',
        'B#m',
        'B Maj',
        'B# Maj',
        'Cm',
        'C#m',
        'C Maj',
        'C# Maj',
        'Dm',
        'D#m',
        'D Maj',
        'D# Maj',
        'Em',
        'E#m',
        'E Maj',
        'E# Maj',
        'Fm',
        'F#m',
        'F Maj',
        'F# Maj',
        'Gm',
        'G#m',
        'G Maj',
        'G# Maj',
      ],
    };
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url("${this.song.imgPath}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      };
    },
    popupWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '90%';
        case 'sm':
          return '85%';
        case 'md':
          return '75%';
        case 'lg':
          return '70%';
        case 'xl':
          return '65%';
        default:
          return '65%';
      }
    },
  },
  methods: {
    // Envoie les modifications au composant parent pour modifier data (no mutating props) (popupitem)
    validate() {
      $emit('changes', this.songItem);
    },
    // Limite le nombre de mood à 2
    limiter(e) {
      if (e.length > 2) {
        e.pop();
      }
    },
    // Self explanatory
    replaceBPM() {
      this.songItem.BPM = this.newBPM;
      this.newBPM = '';
    },
    // Self explanatory
    replaceTitle() {
      this.songItem.title = this.newTitle;
      this.newTitle = '';
    },
    // Permet de remove les différents tags
    removeFrom(List, item) {
      console.log(this.songItem.collab);
      if (List === 'collab') {
        this.songItem.collab.splice(this.songItem.collab.indexOf(item), 1);
      } else if (List === 'mood') {
        this.songItem.mood.splice(this.songItem.mood.indexOf(item), 1);
      } else this.songItem.gamme.splice(this.songItem.gamme.indexOf(item), 1);
    },
    // Permet d'ajouter des tags
    addTo(List, item) {
      if (List === 'collab') {
        this.songItem.collab.push(item);
        this.newCollab = '';
      } else if (List === 'mood') {
        this.songItem.mood.push(item);
        this.newMood = '';
      } else if (List === 'genre') {
        this.songItem.genre = item;
        this.newGenre = '';
      } else this.songItem.gamme = item;
      this.newGamme = '';
    },
    closeDialog() {
      this.$router.push('/Dashboard');
    },
  },
};
</script>
<style scoped>
.v-dialog__content {
  align-items: flex-start;
}
.close-dialog {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.beatmaker a {
  font-size: 150%;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

.commenterLink {
  text-decoration: none;
}
.inputDetail {
  height: 2em;
}
.imageRow {
  border: 4px solid rgb(206, 204, 204);
}
.tags {
  margin-top: 0.5em;
}

@media (max-width: 600px) {
  .close-dialog {
    position: fixed;
    top: 30px;
    right: 30px;
  }
  .chronoText {
    font-size: 80%;
  }
}
@media (min-width: 1204px) {
  .tags {
    margin-top: 2.3em;
  }
}
</style>