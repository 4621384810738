<template lang="">
<v-container fluid >
    <v-row class='justify-center'>
        <v-col cols=12 class="px-0">
            <span class="headTitle white--text font-weight-bold">Demandes à traiter</span> <br>
            <span class="grey--text">Des Beats qui n'attendent qu'à être validés !</span>
            <v-list class="blue darken-4">
                <v-list-item v-for="item in song" :key="item.id">
                    <v-row class="align-center">
                        <v-btn @click="linkTo(item.id)" plain text height="100%" width="100%" class="px-0 my-5 justify-start grey darken-4">                          
                            <v-col cols="2" md=1 v-if="$vuetify.breakpoint.smAndUp" class="text-start">
                                <span class="grey--text songDetail">{{item.id}}</span>
                            </v-col>
                            <v-col cols="5" sm=3 md=2 lg=1>
                                <v-row class="justify-space-around">
                                    <v-avatar tile>
                                        <v-img :src="item.imgPath"></v-img>
                                    </v-avatar>
                                    <v-btn icon class="mt-1" @click.stop.prevent="playSubmission, item.isPlaying = true" v-if="item.isPlaying === false">
                                        <v-icon color="white">mdi-play</v-icon>
                                    </v-btn>
                                    <v-btn icon class="mt-1" @click.stop.prevent="pauseSubmission, item.isPlaying = false" v-if="item.isPlaying === true">
                                        <v-icon color="white">mdi-pause</v-icon>
                                    </v-btn>
                                </v-row>
                            </v-col>
                            <v-col cols="7" sm=6  md=4 class="pb-0 regText text-start">
                                <p class="songDetail white--text">
                                    <span class="font-weight-bold">{{item.title}}</span> <br>
                                    <span class="grey--text">par </span> <span>{{item.beatmaker}}</span> <br>
                                    <span>{{item.BPM}}</span><span> BPM </span> <span>{{item.nbListens}}</span> <span> écoutes</span>
                                </p>
                            </v-col>
                            <v-col cols="5" v-if="$vuetify.breakpoint.mdAndUp" class="regText text-start">
                                <v-chip dark small class="mx-2">{{item.mood}}</v-chip>
                                <v-chip dark small class="mx-2">{{item.submission.status}}</v-chip>

                                <v-chip dark small class="mx-2">{{item.Instrument}}</v-chip>
                                <v-chip dark small class="mx-2">{{item.licence}}</v-chip>
                            </v-col>
                        </v-btn>
                    </v-row>
                </v-list-item>
            </v-list>
            <v-row class='text-end'>
                <v-col class="pt-6">
                    <v-btn width="10em" small color='black' class='white--text'>Précédent</v-btn>
                    <v-btn width="10em" small>Suivant</v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row class='justify-center'>
        <v-col cols=12 class="px-0">
            <span class="headTitle white--text font-weight-bold">Toutes les demandes traitées</span> <br>
            <span class="grey--text">Du plus récent au plus ancestral</span>
            <v-list class="blue darken-4 ">
                <v-list-item v-for="item in validated" :key="item.id">
                    <v-row class="align-center">
                        <v-btn @click="linkTo(item.id)" plain text height="100%" width="100%" class="px-0 my-5 justify-start grey darken-4 popup-activator">                          
                            <v-col cols="2" md=1 v-if="$vuetify.breakpoint.smAndUp" class="text-start">
                                <span class="grey--text songDetail">{{item.id}}</span>
                            </v-col>
                            <v-col cols="5" sm=3 md=2 lg=1>
                                <v-row class="justify-space-around">
                                    <v-avatar tile>
                                        <v-img :src="item.imgPath"></v-img>
                                    </v-avatar>
                                    <v-btn icon class="mt-1" @click.stop.prevent="playSubmission, item.isPlaying = true" v-if="item.isPlaying === false">
                                        <v-icon color="white">mdi-play</v-icon>
                                    </v-btn>
                                    <v-btn icon class="mt-1" @click.stop.prevent="pauseSubmission, item.isPlaying = false" v-if="item.isPlaying === true">
                                        <v-icon color="white">mdi-pause</v-icon>
                                    </v-btn>
                                </v-row>
                            </v-col>
                            <v-col cols="7" sm=6  md=4 class="pb-0 regText text-start">
                                <p class="songDetail white--text">
                                    <span class="font-weight-bold">{{item.title}}</span> <br>
                                    <span class="grey--text">par </span> <span>{{item.beatmaker}}</span> <br>
                                    <span>{{item.BPM}}</span><span> BPM </span> <span>{{item.nbListens}}</span> <span> écoutes</span>
                                </p>
                            </v-col>
                            <v-col cols="5" v-if="$vuetify.breakpoint.mdAndUp" class="regText text-start">
                                <v-chip dark small class="mx-2">{{item.submission.status}}</v-chip>
                                
                                <v-chip dark small class="mx-2">{{item.mood}}</v-chip>
                                <v-chip dark small class="mx-2">{{item.Instrument}}</v-chip>
                                <v-chip dark small class="mx-2">{{item.licence}}</v-chip>
                                <v-chip dark small class="mx-2">
                                    <v-icon color=green>mdi-check-circle-outline</v-icon>
                                </v-chip>
                            </v-col>
                        </v-btn>
                    </v-row>
                </v-list-item>
            </v-list>
            <v-row class='text-end'>
                <v-col class="pt-6">
                    <v-btn width="10em" small color='black' class='white--text'>Précédent</v-btn>
                    <v-btn width="10em" small>Suivant</v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
export default {
  props: {
    song: Array,
    validated: Array,
  },
  methods: {
    linkTo(link) {
      this.$router.push({ name: "Submissions", params: { id: link } });
    },
    playSubmission() {
      // TODO : LINK TO SONG
    },
    pauseSubmission(playing) {
      // TODO : LINK TO SONG
    },
  },
  data() {
    return {};
  },
};
</script>
<style>
.songDetail {
  font-size: 80%;
}
.headTitle {
  font-size: 150%;
}
</style>